import React, { useMemo } from 'react';

import setup from './setup';
import { Flex, Text } from '@builtbypixel/plasma';

const EditForm = () => {
  return (
    <Flex>
      <Text>Welcome to the Running Imp Dashboard</Text>
    </Flex>
  );
};

export default EditForm;
