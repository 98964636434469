import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';

import setup from './setup';

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: 'Banner Details',
        fields: [
          {
            name: 'name',
            component: 'text',
            placeholder: 'Banner Text',
            label: 'Banner Text',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'colour',
            component: 'colour',
            placeholder: 'Banner Colour',
            label: 'Banner Colour',
            isRequired: true,
            rules: { required: true },
          },

          {
            name: 'start_date',
            component: 'dateTime',
            placeholder: 'Set Start Time',
            label: 'Start Time',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'end_date',
            component: 'dateTime',
            placeholder: 'Set End Date',
            label: 'End Date',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'active',
            component: 'switch',
            label: 'Active?',
          },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
