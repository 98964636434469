import React, { useMemo } from 'react';
import { EditView, useGet, useHttp } from '@builtbypixel/nucleus';
import setup from './setup';
import { Flex, Text } from '@builtbypixel/plasma';
import Tabs from './components/Tabs';
import { FormProvider, useForm } from 'react-hook-form';

const EditForm = () => {
  const methods = useForm();

  return (
    <Flex>
      <FormProvider {...methods}>
        <form>
          <Tabs />
        </form>
      </FormProvider>
    </Flex>
  );
};

export default EditForm;
