import React from 'react';
import { Flex, Text, Divider, useColorMode } from '@builtbypixel/plasma';
import { useFormContext } from 'react-hook-form';

const RequestedProduct = () => {
  const { getValues } = useFormContext();
  const { colorMode } = useColorMode();
  const product = getValues('product');
  const associatedProducts = getValues('associated_product');

  return (
    <Flex
      direction="column"
      mt="1em"
      bg={colorMode === 'dark' ? '#1A1A1E' : 'white'}
      w="90%"
      p="1em"
    >
      <Text
        fontSize="1.5rem"
        mb="2em"
        fontWeight="bold"
        textAlign="center"
        color={colorMode === 'dark' && 'white'}
      >
        Requested Product Details
      </Text>
      {product ? (
        <>
          <Flex justify="space-between" align="center">
            <Flex>
              {/* <Text fontWeight='bold'>Name</Text> */}
              {product.images.length !== 0 ? (
                <img
                  src={product.images[0].secure_url}
                  width={40}
                  alt="product picture"
                />
              ) : (
                <img
                  src='https://via.placeholder.com/300.png/e9e9e9/000?text="No Image"'
                  width={40}
                  alt="No pic"
                />
              )}
              <Text fontWeight="bold">{product.name}</Text>
            </Flex>
          </Flex>
          <Divider />
          <Flex justify="space-between">
            <Text>Price</Text>
            <Text>£{product.price}</Text>
          </Flex>
          <Divider />
          <Flex justify="space-between">
            <Text>Stock</Text>
            <Text>{product.stock_count}</Text>
          </Flex>
          <Divider />
          <Flex justify="space-between">
            <Text>SKU</Text>
            <Text>{product.sku}</Text>
          </Flex>
          <Divider />
          <Flex justify="space-between">
            <Text>Weight (g)</Text>
            <Text>{product.inventory.weight}</Text>
          </Flex>
          <Divider />
          <Flex justify="space-between">
            {associatedProducts ? (
              <>
                <Text>Associated Product</Text>

                {associatedProducts.images.length !== 0 ? (
                  <img
                    src={associatedProducts.images[0].secure_url}
                    width={40}
                    alt="product picture"
                  />
                ) : (
                  <img
                    src='https://via.placeholder.com/300.png/e9e9e9/000?text="No Image"'
                    width={40}
                    alt="No pic"
                  />
                )}
                <Text>{associatedProducts.name}</Text>
              </>
            ) : (
              <Text>No Associated Product provided</Text>
            )}
          </Flex>
        </>
      ) : (
        <p>...Loading</p>
      )}
    </Flex>
  );
};

export default RequestedProduct;
