import React from 'react';
import { Flex, Box, Text, Card, PseudoBox } from '@builtbypixel/plasma';

import { useHistory } from 'react-router-dom';
import useDimensions from 'react-use-dimensions';

const NavBoxWrapper = ({
  children,
  text,
  icon,
  path,
  setCurrentPage,
  image,
}) => {
  let history = useHistory();

  const [ref, { x, y, width }] = useDimensions();
  return (
    <Flex
      ref={ref}
      w='calc(100% / 3.5)'
      h={{
        xs: '200px',
        sm: '200px',
        md: '200px',
        lg: '250px',
        xl: width > 350 ? (width / 100) * 60 : '300px',
      }}
      justify='center'
      // height='350px'
      // minHeight='250px'
    >
      <Flex
        onClick={() => history.push(`/reports?${path}`)}
        cursor='pointer'
        backgroundImage={`url(${image})`}
        backgroundRepeat={'no-repeat'}
        backgroundPosition={'center'}
        // backgroundSize='100% 100%'
        backgroundSize='cover'
        width={'100%'}
        // height='80%'
        m='1em'
        my={['1em', '1em', '1em', '1em']}
        position='relative'
      >
        <PseudoBox
          display='flex'
          backgroundColor='#cbd900'
          _hover={{
            color: 'white',
            boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px',
            backgroundColor: '#cbd900',
            marginBottom: '10px',
            transform: 'scale(1.1)',
            transition: 'ease 0.2s',
          }}
          alignSelf='flex-end'
          justifyContent='center'
          width='100%'
          mb='10px'
        >
          <Text fontSize='1.5em' textAlign='center' fontFamily='CircularStd'>
            {text}
          </Text>
        </PseudoBox>

        {/* <Text fontSize='1.5em' __hover={{ color: 'white' }}>
        {text}
      </Text> */}
      </Flex>
    </Flex>
  );
};

export default NavBoxWrapper;
