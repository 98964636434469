import React from 'react'
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus'
import { IconButton, Switch } from '@builtbypixel/plasma'
import { FiEdit } from 'react-icons/fi'
import setup from './setup'
import { Button } from '@builtbypixel/plasma';
import { Link } from 'react-router-dom';

// Extra Menu appears next to the create button
const extraMenu = () => {
   return null
}

const List = () => {
   const columns = [
       {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ row }) => (
                <Link
                    to={`${setup.model}/edit/${row.original[setup.accessor]}`}
                >
                    <Button variantColor='secondary' variant='link'>
                        {row.original.name}
                    </Button>
                </Link>
            ),
        }
   ]

   return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />
}

export default List
