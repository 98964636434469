import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { IconButton, Text } from '@builtbypixel/plasma';
import moment from 'moment';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';

// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const List = () => {
  const columns = [
    {
      Header: () => null,
      id: 'edit',
      Cell: ({ row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <IconButton variant='ghost' variantColor='primary' rounded='md'>
            <FiEdit fontSize={18} />
          </IconButton>
        </LinkButton>
      ),
    },

    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Company / Event',
      accessor: 'event',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phone',
      accessor: 'phone',
    },
    {
      Header: 'SKU',
      accessor: 'product.sku',
    },
    {
      Header: 'Product Name',
      accessor: 'product.name',
    },
    {
      Header: 'Enquiry Date',
      accessor: 'created_at',
      Cell: ({ row }) => (
        <Text>
          {moment(row.original.created_at).format('DD-MM-YYYY | hh:mm a')}
        </Text>
      ),
    },
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
