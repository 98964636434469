import React, { useMemo } from 'react';
// import LinkButton from '../../components/LinkButton'
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { IconButton, Switch } from '@builtbypixel/plasma';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';

// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const columns = [
  {
    Header: () => null,
    id: 'edit',
    Cell: ({ row }) => (
      <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
        <IconButton variant='ghost' variantColor='primary' rounded='md'>
          <FiEdit fontSize={18} />
        </IconButton>
      </LinkButton>
    ),
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Full Name',
    accessor: 'full_name',
  },
];

const List = () => {
  const filters = useMemo(
    () => [
      // {
      //    name: 'from',
      //    component: 'date',
      //    placeholder: 'From'
      // },
      // {
      //    name: 'isAdmin',
      //    component: 'bool',
      //    label: 'Is Admin'
      // },
      // {
      //    name: 'tester',
      //    component: 'select',
      //    placeholder: 'Tester',
      //    options: [{ name: 'yyuss', id: 1 }],
      //    labelKey: 'name',
      //    valueKey: 'id'
      // }
    ],
    []
  );

  return (
    <ListWrapper
      filters={filters}
      setup={setup}
      columns={columns}
      extraMenu={extraMenu}
      handleRowSelection={(rows) => console.log(rows)}
    />
  );
};

export default List;
