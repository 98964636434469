function createPageRoutes({ list, pages, setup }) {
  const url = setup.title.replace(/\s+/g, '-').toLowerCase();
  let array = [
    {
      path: `/${url}`,
      title: `${setup.title} - View All`,
      exact: true,
      auth: true,
      component: list,
      permissions: ['Super Admin', 'Admin'],
    },
  ];

  pages.forEach((page) => {
    const component = page.component;
    array.push({
      path: `/:model(${url})/:type(edit)/:id(${page.id})`,
      title: `${page.title} - Edit Page`,
      exact: true,
      auth: true,
      component,
      permissions: ['Super Admin', 'Admin'],
    });
  });

  return array;
}

export default createPageRoutes;
