import React, { useState, useEffect } from 'react';

import {
  Text,
  Flex,
  Button,
  Grid,
  Alert,
  Divider,
  useColorMode,
  Badge,
} from '@builtbypixel/plasma';
import { useTable, useExpanded } from 'react-table';
import { Styles } from './styles';
import { BsFillDashSquareFill, BsFillPlusSquareFill } from 'react-icons/bs';
import CEProductsView from './CEProductsView';
import { Image, Transformation } from 'cloudinary-react';

const OrderLinesTable = ({ data }) => {
  const { colorMode } = useColorMode();
  const [loading, setLoading] = useState(true);
  const renderRowSubComponent = ({ row }) => {
    let isCE = row.original.template_options.map((option) => {
      return option.blocks.filter(
        (b) => b.input_type.id === 11 && b.value !== null
      );
    })?.[0];

    return (
      <div
        style={
          row.isExpanded && {
            background: colorMode === 'dark' ? 'black' : 'white',
            transform: 'scale(1)',
          }
        }
      >
        {isCE?.[0]?.products?.length > 0 ? (
          <Flex direction='column' p='10px' py='1em'>
            <Text fontWeight='bold'>{isCE[0].title}</Text>
            <Divider />
            {isCE[0]?.information?.free_characters && (
              <Flex justify='space-between' pb='0.1em'>
                <Text
                  textTransform='uppercase'
                  letterSpacing='-1px'
                  fontSize='0.9em'
                >
                  Free Characters:
                </Text>
                <Flex>
                  <Text
                    textTransform='uppercase'
                    letterSpacing='-1px'
                    fontSize='0.9em'
                  >{`${isCE[0]?.information?.free_characters}`}</Text>
                  <Badge ml='0.5em'>Per Product</Badge>
                </Flex>
              </Flex>
            )}
            {isCE[0]?.information?.fixed_price && (
              <Flex justify='space-between' pb='0.1em'>
                <Text
                  textTransform='uppercase'
                  letterSpacing='-1px'
                  fontSize='0.9em'
                >
                  Service Charge
                </Text>
                <Flex>
                  <Text
                    textTransform='uppercase'
                    letterSpacing='-1px'
                    fontSize='0.9em'
                  >{`£${Number(isCE[0]?.information?.fixed_price).toFixed(
                    2
                  )}`}</Text>
                  <Badge ml='0.5em'>Per Product</Badge>
                </Flex>
              </Flex>
            )}

            {isCE[0]?.information?.price_per_character && (
              <Flex justify='space-between' pb='0.1em'>
                <Text
                  textTransform='uppercase'
                  letterSpacing='-1px'
                  fontSize='0.9em'
                >
                  Price per Character
                </Text>
                <Flex mb='1em'>
                  <Text
                    textTransform='uppercase'
                    letterSpacing='-1px'
                    fontSize='0.9em'
                  >{`£${Number(
                    isCE[0]?.information?.price_per_character
                  ).toFixed(2)}`}</Text>
                  <Badge ml='0.5em'>Per Product</Badge>
                </Flex>
              </Flex>
            )}
            <Divider />
            <CEProductsView data={isCE?.[0] || []}>
              View Engravings
            </CEProductsView>
          </Flex>
        ) : (
          row.original.template_options.map((option, i) => {
            return (
              <Flex
                direction='column'
                key={`temp-op-${i}`}
                p='10px'
                border={`1px ${colorMode === 'dark' ? 'white' : 'black'} solid`}
              >
                <Text fontWeight='semibold'>{option.name}</Text>
                {option.blocks.map((block, ix) => (
                  <Flex key={`block-${ix}`} direction='column'>
                    <Text mb='10px'>{block.title}</Text>
                    {typeof block.value !== 'boolean' &&
                      (block.value ? (
                        <>
                          <Text
                            bg={
                              colorMode === 'dark' ? 'grey' : 'rgba(0,0,0,0.05)'
                            }
                            rounded='10px'
                            p='5px'
                          >
                            <pre>{block.value}</pre>
                          </Text>
                          <Divider />
                          <Grid
                            templateColumns='1fr 2fr'
                            w='100%'
                            // gap='10px'
                            // p='5px'
                          >
                            <Text
                              textTransform='uppercase'
                              letterSpacing='-1px'
                              fontSize='0.8em'
                            >
                              {/* <pre> */}
                              Free Characters: {block?.free_letters}
                              {/* </pre> */}
                            </Text>
                            <Flex>
                              <Text
                                textTransform='uppercase'
                                letterSpacing='-1px'
                                fontSize='0.8em'
                              >
                                {/* <pre> */}
                                Paid Characters: {block?.paid_letters}
                                {/* </pre> */}
                              </Text>
                              <Text
                                ml='1em'
                                textTransform='uppercase'
                                letterSpacing='-1px'
                                fontSize='0.8em'
                              >
                                (charged at £{block.information?.price} per
                                character)
                              </Text>
                            </Flex>
                          </Grid>
                        </>
                      ) : (
                        <Alert status='error'>No text given by customer</Alert>
                      ))}
                  </Flex>
                ))}
              </Flex>
            );
          })
        )}
      </div>
    );
  };

  const renderExtraCost = (row) => {
    //Display Extracost based on whether standard engraving or complex engraving
    const data = row.original.template_options?.[0]?.blocks?.find((item) => {
      return (
        (item.input_type.id === 11 && item.value) ||
        (item.input_type.id === 1 && item.value)
      );
    });

    return (
      data?.information && (
        <Flex>
          <Text fontSize='0.8em' opacity={0.7}>
            Extra Cost: £{Number(data?.information.total_price).toFixed(2)}
          </Text>
        </Flex>
      )
    );
  };
  return (
    <Styles>
      <CustomTable
        colorMode={colorMode}
        columns={[
          {
            Header: () => null,
            width: '50px',
            id: 'expander',
            Cell: ({ row }) => (
              <div {...row.getToggleRowExpandedProps()}>
                {row.original.template_options &&
                  row.original.template_options.length !== 0 && (
                    <>
                      {row.isExpanded ? (
                        <BsFillDashSquareFill
                          style={{
                            height: '1.2em',
                            width: '1.2em',
                          }}
                        />
                      ) : (
                        <BsFillPlusSquareFill
                          style={{
                            height: '1.2em',
                            width: '1.2em',
                          }}
                        />
                      )}
                    </>
                  )}
              </div>
            ),
          },
          {
            Header: 'Name',
            accessor: 'product.name',
            width: '300px',
            Cell: ({ row }) => (
              <>
                <Flex align='center'>
                  {row.original.images.length !== 0 && (
                    <Image
                      publicId={row.original.images[0].public_id}
                      secure='true'
                    >
                      <Transformation
                        width='40'
                        height='40'
                        crop='thumb'
                        fetchFormat='auto'
                      />
                    </Image>
                  )}
                  <Flex direction='column' pl='10px'>
                    <Button
                      variant='link'
                      variantColor='secondary'
                      as='a'
                      href={`${process.env.REACT_APP_FRONTEND_URL}/products/${row.original.slug}`}
                    >
                      {row.original.name}
                    </Button>
                    <Text fontSize='0.8em' opacity={0.7} as='pre'>
                      <pre>{row.original.sku}</pre>
                    </Text>
                  </Flex>
                </Flex>
                {row.original.surcharge_price !== 0 && (
                  <Flex>
                    <Text fontSize='0.8em' opacity={0.7}>
                      This item has a surcharge of £
                      {Number(row.original.surcharge_price).toFixed(2)}
                    </Text>
                  </Flex>
                )}
                {row.original.setup_cost !== 0 && (
                  <Flex>
                    <Text fontSize='0.8em' opacity={0.7}>
                      This item has a setup cost of £
                      {Number(row.original.setup_cost).toFixed(2)}
                    </Text>
                  </Flex>
                )}
                {renderExtraCost(row)}
                {/* {row.original.template_options?.[0]?.blocks?.[0]
                  ?.information && (
                  <Flex>
                    <Text fontSize='0.8em' opacity={0.7}>
                      Extra Cost: £
                      {Number(
                        row.original.template_options?.[0]?.blocks?.[0]
                          ?.information.total_price
                      ).toFixed(2)}
                    </Text>
                  </Flex>
                )} */}
              </>
            ),
          },

          {
            Header: 'Qty',
            accessor: 'quantity',
            width: '50px',
          },
          {
            Header: 'Unit Price',
            accessor: 'total',
            width: '80px',
            Cell: ({ row }) =>
              console.log(row, 'row') || (
                <Text>
                  £
                  {Number(
                    row.original.product.discounted_price
                      ? row.original.product.discounted_price
                      : row.original.product.price
                  ).toFixed(2)}
                </Text>
              ),
          },
          {
            Header: 'Subtotal',
            accessor: 'sub_total',
            width: '80px',
            Cell: ({ value }) => <Text>£{Number(value).toFixed(2)}</Text>,
          },
        ]}
        data={data}
        renderRowSubComponent={renderRowSubComponent}
        hidePagination
        loading={loading}
      />
    </Styles>
  );
};

export default OrderLinesTable;

function CustomTable({ columns, data, renderRowSubComponent, colorMode }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded },
  } = useTable(
    {
      columns,
      data,
    },
    useExpanded
  );

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  style={{ width: column.width }}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment {...row.getRowProps()}>
                <tr height='50'>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>

                {row.isExpanded ? (
                  <tr
                    style={{
                      background: colorMode === 'dark' ? 'black' : '#cbd500',
                      border: `1px ${
                        colorMode === 'dark' ? 'white' : 'black'
                      } solid`,
                    }}
                  >
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <br />
    </>
  );
}
