import React from 'react'
import { Box, Text, Alert } from '@builtbypixel/plasma'
import { useRecoilValue } from 'recoil'
import { formAtom } from '../../state/form'

const ErrorMessage = () => {
   const { errors } = useRecoilValue(formAtom)

   return errors ? (
      <Box w='100%' p='5px'>
         <Alert
            status='error'
            w='100%'
            title='Form did not submit successfully'
         >
            {process.env.REACT_APP_USE_AWS !== 'true' ? (
               errors.response?.data?.errors?.message ? (
                  <Text fontSize='sm' fontWeight='semibold'>
                     {errors.response?.data?.errors?.message}
                  </Text>
               ) : (
                  <Text fontSize='sm' fontWeight='semibold'>
                     {errors.response.status} - {errors.response.statusText}
                     {errors.response.data.exception
                        ? errors.response.data?.exception
                        : errors.response.data?.errors?.message}
                  </Text>
               )
            ) : (
               <Text fontSize='sm' fontWeight='semibold'>
                  {errors[0].message}
               </Text>
            )}
         </Alert>
      </Box>
   ) : null
}

export default ErrorMessage
