import { createCrudRoutes } from '@builtbypixel/nucleus';
import List from './List';
import Entry from '.';

import setup from './setup';

const crud = createCrudRoutes({
  setup,
  name: setup.title,
  edit: Entry,
  list: List,
  view: null,
  permissions: ['Super Admin', 'Admin', 'Sales'],
});

const otherRoutes = [
  /*
        {
        path: '/login',
        title: 'Login',
        exact: true,
        auth: false,
        component: Login,
    },
    */
];

const routes = [...crud, ...otherRoutes];

export default routes;
