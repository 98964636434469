import React from 'react';
import { EditView } from '@builtbypixel/nucleus';
import Form from './Form';
import setup from './setup';

const EditForm = () => {
  return (
    <EditView setup={setup} isFullWidth>
      <Form />
    </EditView>
  );
};

export default EditForm;
