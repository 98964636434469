import categories from './containers/Categories/routes';
import brochures from './containers/Brochures/routes';
import customers from './containers/Customers/routes';
import blogs from './containers/Blog/routes';
import BlogCategories from './containers/BlogCategories/routes';
import SearchFilterPrices from './containers/SearchFilterPrice/routes';
import SearchFilterColour from './containers/SearchFilterColour/routes';
import Events from './containers/Events/routes';
import Departments from './containers/Departments/routes';
import Pages from './containers/Pages/routes';
import Products from './containers/Products/routes';
import Orderwise from './containers/OrderwiseProducts/routes';
import Attributes from './containers/Attributes/routes';
import TemplateOptions from './containers/TemplateOptions/routes';
import ContactForm from './containers/ContactForm/routes';
import TermsCategories from './containers/TermsCategories/routes';
import Dashboard from './containers/Dashboard/routes';
import PrivacyPolicyCategory from './containers/PrivacyPolicyCategory/routes';
import PrivacyPolicy from './containers/PrivacyPolicy/routes';
import AttributeSets from './containers/AttributeSets/routes';
import SubCategories from './containers/SubCategories/routes';
import DiscountCodes from './containers/DiscountCodes/routes';
import BrochureRequests from './containers/BrochureRequests/routes';
import Orders from './containers/Orders/routes';
import ProductRequests from './containers/ProductRequests/routes';
import Sources from './containers/Sources/routes';
import Settings from './containers/Settings/routes';
import Vouchers from './containers/Vouchers/routes';
import DeliveryMethods from './containers/DeliveryMethods/routes';
import OrderwiseActivityLog from './containers/OrderwiseActivityLog/routes';
import OrderwiseIntegration from './containers/OrderwiseIntegration/routes';
import Countries from './containers/Countries/routes';
import Couriers from './containers/Couriers/routes';
import OrderSettings from './containers/OrderSettings/routes';
import Banners from './containers/Banners/routes';
import AccountType from './containers/AccountType/routes';
import AdminUsers from './containers/AdminUsers/routes';
import SiteBanner from './containers/SiteBanner/routes';
import Login from './Nucleus/containers/Auth/Login';
import ForgotPassword from './Nucleus/containers/Auth/ForgotPassword';
import ResetPassword from './Nucleus/containers/Auth/ResetPassword';
import Reports from './containers/Reports/routes';

const routes = [
  ...categories,
  ...brochures,
  ...customers,
  ...blogs,
  ...BlogCategories,
  ...SearchFilterPrices,
  ...SearchFilterColour,
  ...Events,
  ...Departments,
  ...Pages,
  ...Orderwise,
  ...Products,
  ...ContactForm,
  ...TermsCategories,
  ...Orders,
  ...PrivacyPolicyCategory,
  ...PrivacyPolicy,
  ...AttributeSets,
  ...TemplateOptions,
  ...Attributes,
  ...SubCategories,
  ...DiscountCodes,
  ...BrochureRequests,
  ...ProductRequests,
  ...Sources,
  ...Settings,
  ...Vouchers,
  ...DeliveryMethods,
  ...OrderwiseActivityLog,
  ...Countries,
  ...Couriers,
  ...Banners,
  ...AccountType,
  ...AdminUsers,
  ...SiteBanner,
  ...Dashboard,
  ...OrderwiseIntegration,
  ...OrderSettings,
  {
    path: '/',
    title: 'Login',
    exact: true,
    auth: false,
    component: Login,
    permissions: [
      'Super Admin',
      'Admin',
      'Sales',
      'Purchase',
      'Finance',
      'Director',
      'Stock & Codes',
      'Warehouse',
    ],
  },
  {
    path: '/forgot-password',
    title: 'Forgot Password',
    exact: true,
    auth: false,
    component: ForgotPassword,
    permissions: [
      'Super Admin',
      'Admin',
      'Sales',
      'Purchase',
      'Finance',
      'Director',
      'Stock & Codes',
      'Warehouse',
    ],
  },
  {
    path: '/reset-password',
    title: 'Reset Password',
    exact: true,
    auth: false,
    component: ResetPassword,
    permissions: [
      'Super Admin',
      'Admin',
      'Sales',
      'Purchase',
      'Finance',
      'Director',
      'Stock & Codes',
      'Warehouse',
    ],
  },
  ...Reports,
];

export default routes;
