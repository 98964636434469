import React from 'react';
import { Flex, Box, Text } from '@builtbypixel/plasma';
import PageContainer from '../Components/PageContainer';

const Orders = ({ setCurrentPage, currentPage }) => {
  return (
    <PageContainer
      title='Orders'
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    >
      Orders PAGE
    </PageContainer>
  );
};

export default Orders;
