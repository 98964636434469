import React from 'react'
import { Field } from '@builtbypixel/nucleus';
import { Flex , Text} from '@builtbypixel/plasma';

import { useFormContext } from 'react-hook-form';

function AttributesSet() {

    const { data } = useFormContext();
    const { attribute_sets } = data;
    const { groups } = attribute_sets;

  return (
    <Flex
        direction='column' 
        mt='25px'
        border= {'1px solid'}
        borderColor= {'#E2E8F0'}
        borderRadius= {'0.4rem'}
        padding={'8px'}
    >
        <Text
            marginBottom='16px'
        >
            Attributes Set
        </Text>
        <Field
            name='selected_attributes_set'
            label='All Attributes'
            component='select'
            labelKey='title'
            valueKey='id'
            isMulti={true}
            options={groups}
            help='Select the attributes for this product'
            isRequired
        >
        </Field>
    </Flex>
    
  )
}

export default AttributesSet