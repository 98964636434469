import React from 'react';
import { Flex, Box, Button, Text, Card } from '@builtbypixel/plasma';
import { TiArrowBack } from 'react-icons/ti';
import { useHistory, useLocation } from 'react-router-dom';

const Header = ({ title, currentPage, setCurrentPage }) => {
  const history = useHistory();
  const { search } = useLocation();
  return (
    <Flex position='relative'>
      {search && currentPage !== 'home' && (
        <Flex position='absolute'>
          <Flex align='center' w='100px'>
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              <TiArrowBack fontSize='1.5em' />
              <Text ml='1em'>Back</Text>
            </Button>
          </Flex>
          <Flex align='center' w='100px'>
            <Button
              onClick={() => {
                history.push('/reports?home');
              }}
            >
              <TiArrowBack fontSize='1.5em' />
              <Text ml='1em'>Home</Text>
            </Button>
          </Flex>
        </Flex>
      )}

      <Flex justify='center' w='100%'>
        <Text fontWeight='bold' fontSize='2em'>
          {title}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Header;
