import React, { useState, useEffect } from 'react';
import {
    Grid,
    Box,
    Table,
    useColorMode,
    Button,
    Input,
    Flex,
} from '@builtbypixel/plasma';
import { useHttp } from '@builtbypixel/nucleus';

const Modal = ({
    customCustomers,
    columns,
    selected,
    attributes,
    onRowSelect,
    customSetCustomers,
    pushSingle,
    onRowRemove,
}) => {
    const { colorMode } = useColorMode();
    const [pagination, setPagination] = useState(10);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState(null);
    const [Customers, setCustomers] = useState(null);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const Http = useHttp();

    useEffect(() => {
        setLoading(true);
        getCustomers(pagination, page, filters);
    }, [filters, page, pagination]);

    const getCustomers = (pagination, page, filters) => {
        Http.get('/customers', {
            params: { order: 'asc', sort: 'email',pagination, search, page, filters },
        })
            .then((res) => {
                customSetCustomers
                    ? customSetCustomers(res.data)
                    : setCustomers(res.data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    };

    const setSelected = (product) => {
        let _selected = selected ? [...selected] : [];

        const alreadySelected = _selected.findIndex(
            (s) => s?.id === product?.id
        );

        if (alreadySelected === -1) {
            if (pushSingle) {
                onRowSelect(product);
            } else {
                _selected.push(product);
            }
        } else {
            if (onRowRemove) {
                onRowRemove(product);
            } else {
                _selected.splice(alreadySelected, 1);
            }
        }
        if (!pushSingle) {
            onRowSelect(_selected);
        }
    };

    const isSelected = (id) => {
        return selected ? selected.some((s) => s?.id === id) : false;
    };

    return (
        <Grid templateColumns='1fr' w='100%' gap='10px' p='5px'>
            <Box p='10px'>
                <Flex mb='20px'>
                    <Input
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        placeholder='Search by name or SKU'
                    />
                    <Button
                        onClick={() => getCustomers()}
                        variantColor='secondary'
                        ml='20px'
                        isLoading={loading}
                        isDisabled={loading}
                    >
                        Search
                    </Button>
                </Flex>
                {(customCustomers || Customers) && (
                    <Table
                        columns={
                            columns ?? [
                                {
                                    Header: '',
                                    accessor: 'id',
                                    Cell: ({ row }) => (
                                        <Button
                                            onClick={() => {
                                                setSelected(row.original);
                                            }}
                                            size='sm'
                                            color={
                                                isSelected(row.original.id)
                                                    ? 'white'
                                                    : colorMode === 'light'
                                                    ? 'white'
                                                    : 'black'
                                            }
                                            rounded='md'
                                            variantColor={
                                                isSelected(row.original.id)
                                                    ? 'success'
                                                    : 'primary'
                                            }
                                        >
                                            {isSelected(row.original.id)
                                                ? 'Selected'
                                                : 'Select'}
                                        </Button>
                                    ),
                                },
                                {
                                    Header: 'First Name',
                                    accessor: 'first_name',
                                },
                                {
                                    Header: 'Surname',
                                    accessor: 'last_name',
                                },
                                {
                                    Header: 'Email',
                                    accessor: 'email',
                                },
                            ]
                        }
                        data={customCustomers ?? Customers}
                        isExpandable={false}
                        //onRowSelect={(e) => onRowSelect(e)}
                        onChangePageSize={(e) => setPagination(e)}
                        onChangePage={(e) => setPage(e)}
                        canSelect={false}
                        loading={loading}
                    />
                )}
            </Box>
        </Grid>
    );
};

export default Modal;
