import React, { useMemo, useState, useEffect } from 'react';
import { AutoFields, EditView, useGet, useHttp } from '@builtbypixel/nucleus';
import { useFormContext, useWatch } from 'react-hook-form';
import useSWR from 'swr';
import setup from './setup';

import VoucherProducts from './fields/VoucherProducts';
import VoucherCategories from './fields/VoucherCategories';
import VoucherCustomers from './fields/VoucherCustomers';
import { useParams } from 'react-router-dom';

const Form = () => {
  const [deliveryMethodInput, setDeliveryMethodInput] = useState(null);
  const [deliveryMethods, setDeliveryMethods] = useState(null);
  const { data: requirements } = useSWR('/voucher-requirements', useGet);
  const { data: regions } = useSWR('/countries/229/regions', useGet);
  const Http = useHttp();

  const voucherType = useWatch({ name: 'voucher_type' });

  const getDeliveryMethods = () =>
    Http.get(`/delivery-methods`, {
      params: { search: deliveryMethodInput },
    }).then((res) => {
      setDeliveryMethods(res.data);
    });

  useEffect(() => {
    getDeliveryMethods();
  }, [deliveryMethodInput]);

  const freeDeliveryRegions = {
    tab: 'Regions',
    group: 'Regions',
    fields: [
      {
        name: 'voucher_regions',
        component: 'select',
        isMulti: true,
        labelKey: 'name',
        valueKey: 'id',
        options: regions?.data,
        placeholder: 'Select regions',
      },
    ],
  };

  const { id, type } = useParams();

  const optionalFields = {
    name: 'voucher_delivery_method',
    component: 'select',
    placeholder: 'Voucher Delivery Method',
    label: 'Voucher Delivery Method',
    labelKey: 'name',
    valueKey: 'id',
    options: deliveryMethods?.data,
    rules: {
      required: true,
    },
    onInputChange: (val) => {
      setDeliveryMethodInput(val);
    },
  };

  const _fields = [
    {
      name: 'name',
      component: 'text',
      placeholder: 'Voucher Name',
      label: 'Voucher Name',
      isRequired: true,
      rules: { required: true },
      help: 'The name of the voucher for Nucleus Use',
    },
    {
      name: 'code',
      component: 'text',
      placeholder: 'Voucher Code',
      label: 'Voucher Code',
      isRequired: true,
      rules: { required: true },
      help: 'The code that the customer uses',
    },
    {
      name: 'discount_amount',
      component: 'text',
      placeholder: 'Discount Amount',
      label: 'Discount Amount',
      isRequired: true,
      rules: { required: true },
      help: 'The amount we want to reduce the basket by, depends on type',
    },
    {
      name: 'voucher_type',
      component: 'select',
      options: [
        { name: 'Fixed Amount', value: 'FIXED_AMOUNT' },
        { name: 'Percent', value: 'PERCENT' },
        { name: 'Free Shipping', value: 'FREE_SHIPPING' },
      ],
      labelKey: 'name',
      label: 'Voucher Type',
      valueKey: 'value',
      placeholder: 'Select Voucher Type',
      rules: { required: true },
      isRequired: true,
    },
  ];

  const fields = useMemo(() => {
    const fieldArr = [
      {
        tab: 'Voucher Details',
        group: 'Details',
        fields:
          voucherType?.value === 'FREE_SHIPPING'
            ? [..._fields, optionalFields]
            : _fields,
      },
      {
        tab: 'Voucher Details',
        group: 'Additional Details',
        fields: [
          {
            name: 'voucher_limit',
            component: 'text',
            placeholder: 'Voucher Limit',
            label: 'Voucher Limit',
            help: 'The limit that this voucher can be used (leave blank for unlimited)',
          },
          {
            name: 'from',
            component: 'date',
            label: 'Date From',
            help: 'Date the voucher is available from',
          },
          {
            name: 'to',
            component: 'date',
            label: 'Date To',
            help: 'Date the voucher is available to',
          },
        ],
      },
      {
        tab: 'Voucher Details',
        group: 'Voucher Requirements',
        fields: [
          {
            name: 'voucher_requirement',
            component: 'select',
            options: requirements?.data,
            labelKey: 'name',
            label: 'Voucher Requirements',
            valueKey: 'id',
            placeholder: 'Select Requirement',
            help: 'Whether the customer has to have a certain quantity/purchase price to use the voucher',
            rules: { required: true },
            isRequired: true,
          },
          {
            name: 'requirement_amount',
            component: 'text',
            placeholder: 'Requirement Amount',
            label: 'Requirement Amount',
            help: 'What is the amount to qualify if we have requirements',
            rules: { required: true },
            isRequired: true,
            condition: {
              watchField: 'voucher_requirement',
              matchValue: requirements?.data,
              operator: '===',
              childComponent: 'select',
            },
          },
        ],
      },
      {
        tab: 'Customers',
        group: 'Voucher Limit',
        fields: [
          {
            name: 'voucher_limit_per_customer',
            component: 'text',
            placeholder: 'Voucher Limit Per Customer',
            label: 'Voucher Limit Per Customer',
            isRequired: false,
            help: 'What is the limit per customer',
          },
        ],
      },
      {
        tab: 'Customers',
        group: 'Attach Customers',
        fields: [
          {
            name: 'categories',
            component: VoucherCustomers,
          },
        ],
      },
      {
        tab: 'Categories',
        group: 'Attach to Categories',
        fields: [
          {
            name: 'select_all_categories',
            label: 'Select All Categories',
            component: 'switch',
            help: 'Activate this to select all the categories',
          },
          {
            name: 'categories',
            component: VoucherCategories,
          },
        ],
      },
      {
        tab: 'Products',
        group: 'Attach to Products',
        fields: [
          {
            name: 'select_all_products',
            label: 'Select All Products',
            component: 'switch',
            help: 'Activate this to select all the products',
          },
          {
            name: 'products',
            component: VoucherProducts,
          },
        ],
      },
      freeDeliveryRegions,
    ];

    //  if (freeDeliveryRegions.length > 0)
    //    return [...fieldArr, freeDeliveryRegions];
    //  else return fieldArr;

    return fieldArr;
  }, [requirements, regions, deliveryMethods, voucherType]);

  return <AutoFields fields={fields} />;
};

export default Form;
