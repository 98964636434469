import React, { useState } from "react";
import {
  Card,
  Flex,
  Text,
  Grid,
  PseudoBox,
  Button,
  useColorMode,
  Box,
} from "@builtbypixel/plasma";
import useSWR from "swr";
import { useGet, Select } from "@builtbypixel/nucleus";
import { useFormContext } from "react-hook-form";

const OptionBox = ({ children, bgImage, isSelected, onClick }) => (
  <PseudoBox
    rounded="lg"
    boxShadow="sm"
    backgroundImage={bgImage}
    backgroundSize="50% auto"
    backgroundRepeat="no-repeat"
    backgroundPosition="center 100%"
    pt="40px"
    backgroundColor={isSelected ? "secondaryLight" : "global.elementBg"}
    position="relative"
    border="2px"
    borderColor={isSelected ? "secondary" : "darkBorder"}
    cursor="pointer"
    _hover={{ borderColor: "primary" }}
    onClick={onClick}
    py="10px"
    px="25px"
  >
    {children}
  </PseudoBox>
);

const InitialOptions = () => {
  const { setValue } = useFormContext();
  const { colorMode } = useColorMode();

  const { data: attributeTypes } = useSWR(
    `/attribute-sets?pagination=${9999}`,
    useGet
  );

  const [initial, setInitial] = useState({
    attribute_sets: null,
    product_type: null,
  });
  return (
    <Flex align="center" direction="column" w="100%" h="100vh" pt="35px">
      <Card w="100%" maxWidth="700px" mb="20px">
        <Flex direction="column" w="100%" p="20px">
          <Text fontSize="1.2em" mb="20px" fontWeight="semibold">
            Select Attribute Set
          </Text>

          <Select
            name="attribute_sets"
            options={attributeTypes?.data}
            size="lg"
            labelKey="title"
            onChange={(v) =>
              setInitial((old) => ({ ...old, attribute_sets: v }))
            }
            valueKey="id"
            placeholder="Select attribute set..."
          />
        </Flex>
      </Card>
      {initial?.attribute_sets && (
        <>
          <Card w="100%" maxWidth="700px" mb="20px">
            <Flex direction="column" w="100%" p="20px">
              <Text textAlign="center" fontSize="1.2em" fontWeight="semibold">
                Select Product Type
              </Text>
              <Grid
                templateColumns="1fr 1fr"
                gap="15px"
                my="20px"
                templateRows="200px 200px"
              >
                <OptionBox
                  bgImage={
                    colorMode === "light"
                      ? `url(/images/simple.svg)`
                      : `url(/images/simple-dark.svg)`
                  }
                  isSelected={initial.product_type?.id === 1}
                  onClick={() =>
                    setInitial((old) => ({
                      ...old,
                      product_type: {
                        name: "Simple Product",
                        id: 1,
                      },
                    }))
                  }
                >
                  <Text
                    textAlign="center"
                    position="absolute"
                    top="15px"
                    w="100%"
                    fontWeight="semibold"
                    left={0}
                  >
                    Simple Product
                  </Text>
                  <Box
                    px="20px"
                    position="absolute"
                    top="45px"
                    left={0}
                    w="100%"
                  >
                    <Text textAlign="center" fontSize="0.9em" opacity={0.5}>
                      An individual, standalone product
                    </Text>
                  </Box>
                </OptionBox>
                <OptionBox
                  bgImage={
                    colorMode === "light"
                      ? `url(/images/grouped.svg)`
                      : `url(/images/grouped-dark.svg)`
                  }
                  isSelected={initial.product_type?.id === 4}
                  onClick={() =>
                    setInitial((old) => ({
                      ...old,
                      product_type: {
                        name: "Bundled Product",
                        id: 4,
                      },
                    }))
                  }
                >
                  <Text
                    textAlign="center"
                    position="absolute"
                    top="15px"
                    w="100%"
                    left={0}
                    fontWeight="semibold"
                  >
                    Bundle Product
                  </Text>
                  <Box px="20px" position="absolute" top="45px" left={0}>
                    <Text textAlign="center" fontSize="0.9em" opacity={0.5}>
                      Bundle products together as a set, such as a set of medals
                    </Text>
                  </Box>
                </OptionBox>
                <OptionBox
                  bgImage={
                    colorMode === "light"
                      ? `url(/images/bundled.svg)`
                      : `url(/images/bundled-dark.svg)`
                  }
                  isSelected={initial.product_type?.id === 2}
                  onClick={() =>
                    setInitial((old) => ({
                      ...old,
                      product_type: {
                        name: "Grouped Product",
                        id: 2,
                      },
                    }))
                  }
                >
                  <Text
                    textAlign="center"
                    position="absolute"
                    top="15px"
                    left={0}
                    w="100%"
                    fontWeight="semibold"
                  >
                    Grouped Product
                  </Text>
                  <Box px="20px" position="absolute" top="45px" left={0}>
                    <Text textAlign="center" fontSize="0.9em" opacity={0.5}>
                      Group separate products together under one title
                    </Text>
                  </Box>
                </OptionBox>
                <OptionBox
                  bgImage={
                    colorMode === "light"
                      ? `url(/images/configurable.svg)`
                      : `url(/images/configurable-dark.svg)`
                  }
                  isSelected={initial.product_type?.id === 3}
                  onClick={() =>
                    setInitial((old) => ({
                      ...old,
                      product_type: {
                        name: "Configurable Product",
                        id: 3,
                      },
                    }))
                  }
                >
                  <Text
                    textAlign="center"
                    position="absolute"
                    top="15px"
                    left={0}
                    w="100%"
                    fontWeight="semibold"
                  >
                    Configurable Product
                  </Text>
                  <Box px="20px" position="absolute" top="45px" left={0}>
                    <Text textAlign="center" fontSize="0.9em" opacity={0.5}>
                      A product which has custom addons selectable by the
                      customer
                    </Text>
                  </Box>
                </OptionBox>
              </Grid>
            </Flex>
          </Card>
          <Button
            mt="20px"
            variantColor="primary"
            size="lg"
            isDisabled={!initial.product_type}
            onClick={() => {
              setValue("product_type", initial.product_type);
              //setValue('attribute_sets', initial.attribute_sets);
              localStorage.setItem(
                "attribute_sets",
                JSON.stringify(initial.attribute_sets)
              );
            }}
          >
            Continue
          </Button>
        </>
      )}
    </Flex>
  );
};

export default InitialOptions;
