import React, { useEffect, useState, useCallback } from "react";
import {
  InputGroup,
  Input,
  InputLeftElement,
  Icon,
  Tooltip,
} from "@builtbypixel/plasma";
import { useHttp } from "@builtbypixel/nucleus";

import { FaCheckCircle } from "react-icons/fa";
import { MdRadioButtonUnchecked, MdErrorOutline } from "react-icons/md";

const SKU = ({ value, onChange }) => {
  const Http = useHttp();

  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState(null);
  const [val, setVal] = useState();

  useEffect(() => {
    if (value) {
      setVal(value);
    } else {
      setVal("");
    }
  }, [value]);

  const searchSKU = async () => {
    setLoading(true);
    Http.post(`products-check-sku/${val}`)
      .then((res) => {
        if (res.data.data.sku) {
          setStatus(false);
        } else {
          setStatus(true);
        }
        setLoading(false);
      })
      .catch(() => {
        setStatus(false);

        setLoading(false);
      });
  };

  const handleChangeValue = (v) => {
    onChange(v);
  };

  return (
    <InputGroup
      errorBorderColor="red"
      focusBorderColor="primary"
      isFullWidth
      placeholder="Enter SKU..."
      size="md"
      variant="outline"
    >
      <InputLeftElement>
        {status === null && (
          <Icon color="rgba(0,0,0,0.1)">
            <MdRadioButtonUnchecked fontSize="22px" />
          </Icon>
        )}
        {status === false && (
          <Tooltip title="SKU already exists">
            <Icon color="red">
              <MdErrorOutline fontSize="22px" />
            </Icon>
          </Tooltip>
        )}
        {status === true && (
          <Icon color="success">
            <FaCheckCircle fontSize="22px" />
          </Icon>
        )}
      </InputLeftElement>
      <Input
        placeholder="Enter SKU..."
        onChange={(e) => handleChangeValue(e.target.value)}
        onBlur={() => searchSKU()}
        value={val}
      />
    </InputGroup>
  );
};

export default SKU;
