export const theme = {
   colors: {
      primary: '#18191a',
      secondary: '#3677f8',
      secondaryLight: 'rgba(53, 120, 248, 0.12)',
      global: {
         bg: '#f0f2f5',
         elementBgLight: '#ffffff'
      },
      grayBg: '#edf2f7',
      richText: {
         editorBg: '#ffffff'
      },
      modes: {
         dark: {
            primary: 'rgba(255,255,255,0.9)',
            secondary: '#689CFF',
            global: {
               bg: '#0F0F12',
               elementBg: '#1A1A1E',
               elementBgLight: '#161619'
            },
            grayBg: '#0C0C0E',
            richText: {
               editorBg: '#27272b'
            }
         }
      }
   },
   radii: {
      inputBorderRadiusSm: '0.3em'
   }
}
