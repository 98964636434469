import React, { useEffect, useState } from 'react';
import { Box, Grid, Flex, Text, Button, Image } from '@builtbypixel/plasma';
import { useFormContext, useWatch } from 'react-hook-form';
import ProductsTable from '../../Products/components/ProductsTable/index';

const VoucherProducts = ({ productTypes, attributes }) => {
  const select_all_products = useWatch({ name: 'select_all_products' });

  const [name, setName] = useState(
    select_all_products ? 'excluded_products' : 'products'
  );
  const { setValue } = useFormContext();

  const selected = useWatch({ name: name });

  useEffect(() => {
    if (select_all_products) {
      setName('excluded_products');
      setValue('products', []);
    } else {
      setName('products');
      setValue('excluded_products', []);
    }
  }, [select_all_products]);

  const onRowSelect = (rows) => {
    setValue(name, rows);
  };

  const remove = (id) => {
    let _selected = [...selected];
    const index = _selected.findIndex((e) => e.id === id);
    _selected.splice(index, 1);
    setValue(name, _selected);
  };

  return (
    <Box>
      <Text mb='25px'>
        {select_all_products
          ? 'Add products you want to exclude - voucher will apply to all other products.'
          : 'Add products here and this voucher will only be useable on those listed.'}
      </Text>
      <ProductsTable
        selected={selected}
        select_all_products={select_all_products}
        productTypes={productTypes}
        attributes={attributes}
        onRowSelect={onRowSelect}
      />
      <Grid gap='10px' mb='20px' mt='20px'>
        {selected &&
          selected?.map((item, i) => (
            <Flex
              align='center'
              key={`selected-${i}`}
              rounded='lg'
              p='5px'
              border='1px'
              borderColor='borderColour'
              boxShadow='md'
              bg='global.elementBg'
            >
              <Text opacity={0.5} mr='15px'>
                {item.id}
              </Text>
              <Text fontWeight='semibold'>{item.name}</Text>
              <Button
                ml='auto'
                variantColor='error'
                size='sm'
                onClick={() => remove(item.id)}
              >
                Remove
              </Button>
            </Flex>
          ))}
      </Grid>
    </Box>
  );
};

export default VoucherProducts;
