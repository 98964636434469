import React, { useMemo } from "react";

import { EditView, AutoFields } from "@builtbypixel/nucleus";
import setup from "../setup";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "Events",
        fields: [
          {
            name: "content.title",
            component: "text",
            placeholder: "Heading",
            label: "Heading",
            rules: { required: true },
          },
          {
            name: "content.text",
            component: "richtext",
            placeholder: "text",
            label: "Text",
            rules: { required: true },
          },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
