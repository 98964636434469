import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Table, Image } from '@builtbypixel/plasma';
import PageContainer from '../Components/PageContainer';
import { productsMostViewed } from '../Api';
import { useHttp } from '@builtbypixel/nucleus';
import Loader from '../Components/Loader';
import CustomTable from '../Components/CustomTable';
import moment from 'moment';

const Products = ({ currentPage, setCurrentPage }) => {
  const [productsRes, setProductsRes] = useState([]);
  const [loading, setLoading] = useState(false);
  const Http = useHttp();

  const defaultStart =
    window?.location.search.slice(1) === 'products/most-viewed'
      ? moment().startOf('month').format('YYYY-MM-DD')
      : null;

  const defaultEnd =
    window?.location.search.slice(1) === 'products/most-viewed'
      ? moment().format('YYYY-MM-DD')
      : null;

  const fetchData = (
    dateFrom = defaultStart,
    dateTo = defaultEnd,
    pagination = 20,
    page = 1
  ) => {
    setLoading(true);
    Http.get(productsMostViewed, {
      params: { from_date: dateFrom, to_date: dateTo, pagination, page },
    }).then((res) => {
      setProductsRes(res.data);
      setLoading(false);
    });
  };

  const columns = [
    {
      Header: 'Product',
      accessor: 'name',
      Cell: ({ row }) => {
        return (
          <Flex align='center'>
            <Image
              maxHeight='80px'
              src={
                row.original.images?.[0] ? row.original.images[0].url : 'N/A'
              }
            />
            <Text mx='1em'>{`${row.original.name}`}</Text>
          </Flex>
        );
      },
    },
    {
      Header: 'SKU',
      accessor: 'sku',
      Cell: ({ value }) => {
        return <Text mx='1em'>{`${value}`}</Text>;
      },
    },
    {
      Header: 'Price',
      accessor: 'price',
      Cell: ({ row }) => {
        return <Text mx='1em'>{`£${row.original.price}`}</Text>;
      },
    },
    {
      Header: 'Total',
      accessor: 'total',
    },
  ];

  return (
    <PageContainer
      title='Products - Most Viewed'
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      endpoint={productsMostViewed}
      fetchData={fetchData}
      data={productsRes}
      excelFields={['name', 'sku', 'price', 'total']}
    >
      {loading && <Loader />}
      {productsRes?.data?.length > 0 && (
        <CustomTable data={productsRes} columns={columns} />
      )}
    </PageContainer>
  );
};

export default Products;
