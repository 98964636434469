import React, { useMemo } from 'react';
import { EditView } from '@builtbypixel/nucleus';
//import { inputTypes } from '../../common/inputTypes';

import setup from './setup';
import Fields from './Fields';

const EditForm = () => {
    const fields = useMemo(
        () => [
            {
                group: 'Attribute Details',
                fields: [
                    {
                        name: 'type',
                        component: 'select',
                        options: [
                            {
                                id: 4,
                                name: 'Dropdown',
                            },

                            {
                                id: 6,
                                name: 'Checkbox',
                            },
                            {
                                id: 10,
                                name: 'Swatches',
                            },
                        ],
                        labelKey: 'name',
                        label: 'Input Type',
                        valueKey: 'id',
                        placeholder: 'Select type of field',
                    },
                    {
                        name: 'name',
                        component: 'text',
                        placeholder: 'Enter name',
                        label: 'Name',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'label',
                        component: 'text',
                        placeholder: 'Enter Label',
                        label: 'Label',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'slug',
                        component: 'text',
                        editOnly: true,
                        isDisabled: true,
                        placeholder: 'Enter url slug',
                        label: 'Slug',
                    },
                    {
                        name: 'description',
                        component: 'textarea',
                        placeholder: 'Enter Description',
                        label: 'Description',
                        type: 'text',
                        isRequired: true,
                        rules: { required: true, email: true },
                    },

                    {
                        name: 'required',
                        component: 'switch',
                        placeholder: 'Required',
                        label: 'Required',
                        type: 'switch',
                    },
                    {
                        name: 'configurable',
                        component: 'switch',
                        placeholder: 'Configurable',
                        label: 'Configurable',
                        type: 'switch',
                        help: 'Is this selectable by the customer?',
                    },
                ],
            },
        ],
        []
    );
    return (
        <EditView setup={setup}>
            <Fields fields={fields} />
        </EditView>
    );
};

export default EditForm;
