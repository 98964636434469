import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button, Text } from '@builtbypixel/plasma';
import pick from 'lodash/pick';
import { SiMicrosoftexcel } from 'react-icons/si';

const ExportToExcel = ({
  csvData,
  fileName,
  excelFields,
  excelSecondTable,
  excelSecondTableFields,
}) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const prepareExport = (data, fields) => {
      let arr = data?.data?.length > 0 ? data.data : data;
      return arr.map((item) => {
        return pick(item, fields);
      });
    };

    if (excelSecondTable && excelSecondTableFields) {
      let a = prepareExport(csvData, excelFields);
      let b = prepareExport(excelSecondTable, excelSecondTableFields);

      const merged = a.concat(['']).concat(b);

      let worksheet = XLSX.utils.json_to_sheet(merged, { skipHeader: false });

      const new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, 'worksheet');

      const excelBuffer = XLSX.write(new_workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      const ws = XLSX.utils.json_to_sheet(prepareExport(csvData, excelFields), {
        skipHeader: false,
      });
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  return (
    <Button
      color='black'
      variant='ghost'
      variantColor='blue'
      px='5em'
      onClick={(e) => exportToCSV(csvData, fileName)}
    >
      <SiMicrosoftexcel />
      <Text ml='0.5em'>Export</Text>
    </Button>
  );
};

export default ExportToExcel;
