import React, { useMemo } from 'react';

import { EditView, AutoFields } from '@builtbypixel/nucleus';

import setup from '../setup';

const EditForm = () => {
    const fields = useMemo(
        () => [
            {
                group: '',
                fields: [
                    {
                        name: 'content.terms',

                        component: 'repeater',
                        label: 'Terms and Conditions',
                        rules: { required: true },
                        layout: 'vertical',
                        fieldArray: [
                            {
                                name: 'title',
                                component: 'text',
                                placeholder: 'Section Title',
                                label: 'Section Title',
                                isRequired: true,
                                rules: { required: true },
                            },
                            {
                                name: 'content',
                                component: 'richtext',
                                placeholder: 'Section Content',
                                label: 'Section Content',
                                isRequired: true,
                                rules: { required: true },
                            },
                        ],
                    },
                ],
            },
        ],
        []
    );
    return (
        <EditView setup={setup} isFullWidth>
            <AutoFields fields={fields} isFullWidth />
        </EditView>
    );
};

export default EditForm;
