import Login from '../containers/Auth/Login'
import ForgotPassword from '../containers/Auth/ForgotPassword'
import ResetPassword from '../containers/Auth/ResetPassword'
import adminUsers from '../containers/AdminUsers/routes'
import NotFound from '../containers/404'

const routes = [
   {
      path: '/',
      title: 'Login',
      exact: true,
      auth: false,
      component: Login
   },
   {
      path: '/forgot-password',
      title: 'Forgot Password',
      exact: true,
      auth: false,
      component: ForgotPassword
   },
   {
      path: '/reset-password',
      title: 'Reset Password',
      exact: true,
      auth: false,
      component: ResetPassword
   },
   ...adminUsers,
   {
      path: '*',
      component: NotFound
   }
]

export default routes
