import React, { useEffect, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Icon, Flex, Text } from '@builtbypixel/plasma';
import { Image, Transformation } from 'cloudinary-react';
import ProductsTable from './ProductsTable';

import { CgOptions } from 'react-icons/cg';
import AttributesSet from './AttributesSet';

const ConfigProducts = () => {
    const { setValue } = useFormContext();
    const [selected, setSelected] = useState([]);
    const configProductsEdit = useWatch({ name: 'configurable_products' });

    const [configurableAttribute, setConfigurableAttribute] =
        useState(configProductsEdit);

    const onRowSelect = (products) => {
        let updateConfig = [...configurableAttribute];

        setSelected(products);

        if (products.length === 0) {
            setValue('configurable_products', []);
            setConfigurableAttribute([]);
            return;
        }

        let attributesArray = [];

        // Gran the attributes from each product and make a unique array
        products.forEach((product) => {
            let arr = [...attributesArray];
            product.attributes.forEach((attr) => {
                if (!arr.some((a) => a.id === attr.id)) {
                    arr.push({ ...attr, products: [] });
                }
            });
            attributesArray = arr;
        });

        // Map over each product and put each one in the applicable attribute object
        products.forEach((product) => {
            product.attributes.forEach((attr) => {
                const matchedAttrIndex = attributesArray.findIndex(
                    (e) => e.id === attr.id
                );
                let products = [...attributesArray[matchedAttrIndex].products];
                const isInArray = products.findIndex(
                    (p) => p.id === product.id
                );
                if (isInArray === -1) {
                    products.push(product);
                } else {
                    products.splice(isInArray, isInArray);
                }
                attributesArray[matchedAttrIndex].products = products;
            });
        });

        setValue('configurable_products', attributesArray);
        setConfigurableAttribute(attributesArray);
    };

    const getProducts = (parent) => {
        let options = [];
        parent.products.forEach((prod) => {
            prod.attributes.forEach((attr) => {
                if (attr.slug === parent.slug) {
                    if (typeof attr.value === 'object') {
                        let exists = options.some(
                            (e) => e === attr.value?.name
                        );
                        if (!exists) {
                            options.push(attr.value.name);
                        }
                    } else {
                        let exists = options.some((e) => e === attr.value);
                        if (!exists) {
                            options.push(attr.value);
                        }
                    }
                }
            });
        });
        return options;
    };

    return (
        <Box maxWidth='100%' w='100%'>
            <Text mb='25px'>
                Product variants are selectable by the customer, such as size or
                colour.
                <br />
                Variants are individual products.
            </Text>
            <Flex direction='column'>
                <ProductsTable
                    selected={selected}
                    productTypes={[]}
                    attributes={[]}
                    onRowSelect={(row) => onRowSelect(row)}
                    isProdVarient
                />
                <Flex direction='column' mt='25px'>
                    {configurableAttribute &&
                        configurableAttribute.map((attribute, key) => (
                            <Box
                                py='20px'
                                key={`attr-${key}`}
                                border='1px'
                                borderColor='global.borderColour'
                                rounded='lg'
                                p='5px'
                                mb='10px'
                            >
                                <Text style={{ fontWeight: 'bold' }} mb='5px'>
                                    {attribute.name}
                                </Text>

                                {attribute.products &&
                                    attribute.products.map((product, key) => (
                                        <Flex
                                            align='center'
                                            bg='global.bg'
                                            rounded='md'
                                            mb='3px'
                                            px='5px'
                                            py='4px'
                                        >
                                            {product.images.length !== 0 ? (
                                                <Image
                                                    publicId={
                                                        product.images[0]
                                                            .public_id
                                                    }
                                                    secure='true'
                                                >
                                                    <Transformation
                                                        width='40'
                                                        height='40'
                                                        crop='thumb'
                                                        fetchFormat='auto'
                                                    />
                                                </Image>
                                            ) : (
                                                <img
                                                    src='https://via.placeholder.com/300.png/e9e9e9/000?text="No Image"'
                                                    width={40}
                                                    alt='No pic'
                                                />
                                            )}
                                            <Text
                                                fontSize='0.9em'
                                                opacity={0.8}
                                                ml='10px'
                                                key={`product-${key}`}
                                            >
                                                {`${product.name}`}
                                            </Text>
                                        </Flex>
                                    ))}
                                <Text fontWeight='semibold' my='10px'>
                                    Options
                                </Text>

                                {getProducts(attribute).map((option, i) => (
                                    <Flex align='center' key={`option-${i}`}>
                                        <Icon mr='10px' opacity={0.5}>
                                            <CgOptions />
                                        </Icon>
                                        <p>{`${option}`}</p>
                                    </Flex>
                                ))}
                            </Box>
                        ))}
                </Flex>
            </Flex>
            <AttributesSet />
            {/* {configurableAttribute &&
                configurableAttribute.map((attribute, key) => (
                    <Box m='20px'>
                        <Card key={`attribute-${key}`} title={attribute.name}>
                            <Box p='20px'>
                                {attribute[attribute?.slug] &&
                                    attribute[
                                        attribute?.slug
                                    ].map((selectedAttribute, key) => (
                                        <p
                                            key={`option-${key}`}
                                        >{`Option: ${selectedAttribute.value}`}</p>
                                    ))}
                            </Box>
                        </Card>
                    </Box>
                ))} */}
        </Box>
    );
};

export default ConfigProducts;
