import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";
import setup from "./setup";
import RequestedProduct from "./RequestedProduct";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "Request Details",
        fields: [
          {
            name: "name",
            component: "text",
            placeholder: "Name",
            label: "Name",
            isRequired: true,
            rules: { required: true },
          },
          {
            name: "email",
            component: "text",
            placeholder: "Email Address",
            label: "Email Address",
            type: "email",
            isRequired: true,
            rules: { required: true, email: true },
          },
          {
            name: "phone",
            component: "text",
            placeholder: "Phone Number",
            label: "Phone Number",
            type: "number",
          },
          {
            name: "quantity",
            component: "text",
            placeholder: "Quantity",
            label: "Quantity",
            type: "number",
          },
          {
            name: "message",
            component: "textarea",
            placeholder: "Message",
            label: "Message",
          },
          {
            name: "event",
            component: "text",
            placeholder: "Company event",
            label: "Company event",
          },
          //   {
          //     name: 'product_id',
          //     component: 'text',
          //     placeholder: 'Product ID',
          //     label: 'Product ID (Search Below)',
          //     disabled: true,
          //   },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
      {/* <RequestProductTable /> */}
      <RequestedProduct></RequestedProduct>
    </EditView>
  );
};

export default EditForm;
