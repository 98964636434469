import React from 'react';
import { ListWrapper } from '@builtbypixel/nucleus';
import { Button, Tag, Text, Flex } from '@builtbypixel/plasma';
import { Link } from 'react-router-dom';
import setup from './setup';
import moment from 'moment';

// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const List = () => {
  const columns = [
    {
      Header: 'Full Name',
      accessor: 'full_name',
      Cell: ({ row }) => (
        <Link to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant='link' variantColor='secondary' rounded='md'>
            {row.original.full_name}
          </Button>
        </Link>
      ),
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phone Number',
      accessor: 'phone_number',
    },
    {
      Header: 'Organisation',
      accessor: 'organisation',
    },
    {
      Header: 'Department',
      accessor: 'department.name',
    },
    {
      Header: 'Date/Time Created',
      accessor: 'created_at',
      Cell: ({ row }) => (
        <Text>
          {moment(row.original.created_at).format('DD-MM-YYYY | hh:mm a')}
        </Text>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) => {
        const statusColours = [
          { name: 'Unassigned', colour: 'grey' },
          { name: 'In Progress', colour: 'blue' },
          { name: 'Completed', colour: 'green' },
        ];

        let colour = statusColours.find(
          (s) => s.name === row.original.status.name
        )?.colour;

        return (
          <Flex>
            <Tag size='md' variant='solid' variantColor={colour}>
              <Text>{row.original.status.name}</Text>
            </Tag>
          </Flex>
        );
      },
    },
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
