import React, { useMemo } from 'react';
import { AutoFields, EditView, useGet } from '@builtbypixel/nucleus';

import setup from './setup';

const EditForm = () => {
    const fields = useMemo(
        () => [
            {
                group: 'Brochure Request',
                fields: [
                    {
                        name: 'brochure.title',
                        component: 'text',
                        isReadOnly: true,
                        placeholder: 'Requested Brochure',
                        label: 'Requested Brochure',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'first_name',
                        component: 'textarea',
                        isReadOnly: true,
                        placeholder: 'First Name',
                        label: 'First Name',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'last_name',
                        component: 'textarea',
                        isReadOnly: true,
                        placeholder: 'Last Name',
                        label: 'Last Name',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'email',
                        component: 'text',
                        isReadOnly: true,
                        placeholder: 'Email',
                        label: 'Email',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'address_line_one',
                        component: 'text',
                        isReadOnly: true,
                        placeholder: 'Address Line 1',
                        label: 'Address Line 1',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'address_line_two',
                        component: 'text',
                        isReadOnly: true,
                        placeholder: 'Address Line 2',
                        label: 'Address Line 2',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'city',
                        component: 'text',
                        isReadOnly: true,
                        placeholder: 'City',
                        label: 'City',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'postcode',
                        component: 'text',
                        isReadOnly: true,
                        placeholder: 'Postcode',
                        label: 'Postcode',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'phone_number',
                        component: 'text',
                        isReadOnly: true,
                        placeholder: 'Phone Number',
                        label: 'Phone Number',
                    },
                ],
            },
        ],
        []
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
