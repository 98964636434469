import React from 'react';
import { NucleusCore } from '@builtbypixel/nucleus';
import config from './config';
import routes from './routes';
import links from './links';

import { theme } from './theme';

function App() {
    return (
        <NucleusCore
            config={config}
            routes={routes}
            links={links}
            theme={theme}
        />
    );
}

export default App;
