import {
    Box,
    Button,
    Modal,
    ModalHeader,
    ModalContent,
    ModalButton,
    ModalBody,
    ModalFooter,
    Text,
} from '@builtbypixel/plasma';
import React from 'react';
import CustomerTable from './Modal';

const CustomersTable = ({
    columns,
    attributes,
    onRowSelect,
    onRowRemove,
    pushSingle,
    selected,
    customSetCustomers,
    customCustomers,
}) => {
    return (
        <Box maxWidth='100%' w='100%'>
            <Modal size='xl'>
                <ModalButton>
                    <Button
                        variantColor='secondary'
                        variant='outline'
                        size='sm'
                    >
                        Select Customers
                    </Button>
                </ModalButton>
                <ModalContent>
                    <ModalHeader>
                        Select Customers -{' '}
                        <Text ml='10px' fontWeight='normal'>
                            {selected?.length} customers selected
                        </Text>
                    </ModalHeader>
                    <ModalBody>
                        <CustomerTable
                            selected={selected}
                            columns={columns}
                            customCustomers={customCustomers}
                            attributes={attributes}
                            onRowSelect={onRowSelect}
                            customSetCustomers={customSetCustomers}
                            pushSingle={pushSingle}
                            onRowRemove={onRowRemove}
                        />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default CustomersTable;
