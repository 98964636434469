import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Table } from '@builtbypixel/plasma';
import PageContainer from '../Components/PageContainer';
import { averageBasketProducts } from '../Api';
import { useHttp } from '@builtbypixel/nucleus';
import Loader from '../Components/Loader';
import CustomTable from '../Components/CustomTable';
import moment from 'moment';

const AverageBasketProducts = ({ setCurrentPage, currentPage }) => {
  const [averageBasketProductsRes, setAverageBasketProductsRes] = useState([]);
  const [loading, setLoading] = useState(false);
  const Http = useHttp();

  const defaultStart =
    window?.location.search.slice(1) === 'average-basket-products'
      ? moment().startOf('month').format('YYYY-MM-DD')
      : null;

  const defaultEnd =
    window?.location.search.slice(1) === 'average-basket-products'
      ? moment().format('YYYY-MM-DD')
      : null;

  const fetchData = (
    dateFrom = defaultStart,
    dateTo = defaultEnd,
    pagination = 20,
    page = 1
  ) => {
    setLoading(true);
    Http.get(averageBasketProducts, {
      params: { from_date: dateFrom, to_date: dateTo, pagination, page },
    }).then((res) => {
      setAverageBasketProductsRes(res.data.data);
      setLoading(false);
    });
  };

  return (
    <PageContainer
      title='Average Basket Products'
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      endpoint={averageBasketProducts}
      fetchData={fetchData}
      // data={averageBasketProductsRes ? averageBasketProductsRes] : []}
      excelFields={['products_total', 'basket_total', 'average_products']}
    >
      {loading && <Loader />}

      <Flex width='100%' align='center' justify='space-evenly' mb='2em'>
        <Flex
          w='200px'
          direction='column'
          border='2px solid black'
          // bg='#cbd500'
          bg='#666666'
          color='white'
        >
          <Text textAlign='center' fontSize='1.4em'>
            Product Total
          </Text>

          <Text textAlign='center' fontSize='2em' fontWeight='bold'>
            {averageBasketProductsRes?.products_total || 0}
          </Text>
        </Flex>
        <Flex
          w='200px'
          direction='column'
          border='2px solid black'
          // bg='#cbd500'
          bg='#666666'
          color='white'
        >
          <Text textAlign='center' fontSize='1.4em'>
            Basket Total
          </Text>
          <Text textAlign='center' fontSize='2em' fontWeight='bold'>
            {averageBasketProductsRes?.basket_total || 0}
          </Text>
        </Flex>
        <Flex
          w='200px'
          direction='column'
          border='2px solid black'
          // bg='#cbd500'
          bg='#666666'
          color='white'
        >
          <Text textAlign='center' fontSize='1.4em'>
            Average Products
          </Text>
          <Text textAlign='center' fontSize='2em' fontWeight='bold'>
            {averageBasketProductsRes?.average_products || 0}
          </Text>
        </Flex>
      </Flex>
    </PageContainer>
  );
};

export default AverageBasketProducts;
