import React from 'react';
import Cloudinary from './Cloudinary';
import Standard from './Standard';

const FileUpload = (props) => {
  if (props?.uploadType === 'standard') {
    return <Standard {...props} />;
  } else {
    return process.env.REACT_APP_UPLOAD_METHOD === 'cloudinary' ? (
      <Cloudinary {...props} />
    ) : (
      <Standard {...props} />
    );
  }
};

export default FileUpload;
