import { Box, Grid, Flex, Text, Button, Image } from '@builtbypixel/plasma';
import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CategoriesTable from '../../Categories/components/CategoriesTable';
import { cloneDeep, uniqBy } from 'lodash';

const VoucherCategories = ({ productTypes, attributes }) => {
  const select_all_categories = useWatch({ name: 'select_all_categories' });

  const [name, setName] = useState(
    select_all_categories ? 'excluded_categories' : 'categories'
  );

  const { setValue } = useFormContext();
  const selected = useWatch({ name: name });

  const onRowSelect = (rows) => {
    let _rows = [];

    if (rows.length === 0) _rows = selected;
    else _rows = rows;

    setValue(name, _rows);
  };

  const remove = (id) => {
    let _selected = [...selected];
    const index = _selected.findIndex((e) => e.id === id);
    _selected.splice(index, 1);
    setValue(name, _selected);
  };

  useEffect(() => {
    if (select_all_categories) {
      setName('excluded_categories');
      setValue('categories', []);
    } else {
      setName('categories');
      setValue('excluded_categories', []);
    }
  }, [select_all_categories]);

  console.log(useWatch());
  return (
    <Box>
      <Text mb='25px'>
        {select_all_categories
          ? 'Add categories you want to exclude - voucher will apply to all products not in the specified categories.'
          : 'Add categories here and this voucher will be attached to all products in each category.'}
      </Text>
      <CategoriesTable
        selected={selected}
        attributes={attributes}
        onRowSelect={onRowSelect}
      />
      <Grid gap='10px' mb='20px' mt='20px'>
        {selected?.length > 0 &&
          selected?.map((item, i) => (
            <Flex
              align='center'
              key={`selected-${i}`}
              rounded='lg'
              p='5px'
              border='1px'
              borderColor='borderColour'
              boxShadow='md'
              bg='global.elementBg'
            >
              <Text opacity={0.5} mr='15px'>
                {item.id}
              </Text>
              <Text fontWeight='semibold'>
                {item.name ? item.name : item.values.name}
              </Text>
              <Button
                ml='auto'
                variantColor='error'
                size='sm'
                onClick={() => remove(item.id || item.id)}
              >
                Remove
              </Button>
            </Flex>
          ))}
      </Grid>
    </Box>
  );
};

export default VoucherCategories;
