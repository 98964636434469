const modelName = 'Orderwise Integrations';
const modelNameSingular = 'Orderwise Integration';
const endpoint = '/dashboard';
const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: false,
    canAddNew: false,
    canSearch: false,
    endpoint,
    accessor: 'id',
};

export default setup;
