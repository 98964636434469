import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Table, Image } from '@builtbypixel/plasma';
import PageContainer from '../Components/PageContainer';
import { customerHearUs } from '../Api';
import { useHttp } from '@builtbypixel/nucleus';
import Loader from '../Components/Loader';
import CustomTable from '../Components/CustomTable';
import moment from 'moment';

const CustomerHearUs = ({ setCurrentPage, currentPage }) => {
  const [hearUs, setHearUs] = useState([]);
  const [loading, setLoading] = useState(false);
  const Http = useHttp();

  const defaultStart =
    window?.location.search.slice(1) === 'customers-hear-us'
      ? moment().startOf('month').format('YYYY-MM-DD')
      : null;

  const defaultEnd =
    window?.location.search.slice(1) === 'customers-hear-us'
      ? moment().format('YYYY-MM-DD')
      : null;

  const fetchData = (
    dateFrom = defaultStart,
    dateTo = defaultEnd,
    pagination = 20,
    page = 1
  ) => {
    setLoading(true);
    Http.get(customerHearUs, {
      params: { from_date: dateFrom, to_date: dateTo, pagination, page },
    }).then((res) => {
      setHearUs(res.data);
      setLoading(false);
    });
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const columns = [
    {
      Header: 'Preference',
      accessor: 'title',
      Cell: ({ row }) => {
        return (
          <Flex align='center'>
            <Text mx='1em'>{`${row.original.title}`}</Text>
          </Flex>
        );
      },
    },
    {
      Header: 'Count',
      accessor: 'count',
    },
  ];

  return (
    <PageContainer
      title='Customer - Hear Us'
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      endpoint={customerHearUs}
      fetchData={fetchData}
      data={hearUs?.data}
      excelFields={['title', 'count']}
    >
      {loading && <Loader />}
      {hearUs?.data?.length > 0 && (
        <CustomTable data={hearUs} columns={columns} />
      )}
    </PageContainer>
  );
};

export default CustomerHearUs;
