import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Table, Image } from '@builtbypixel/plasma';
import PageContainer from '../Components/PageContainer';
import { brochures } from '../Api';
import { useHttp } from '@builtbypixel/nucleus';
import Loader from '../Components/Loader';
import CustomTable from '../Components/CustomTable';

const Brochures = ({ setCurrentPage, currentPage }) => {
  const [brochuresRes, setBrochuresRes] = useState([]);
  const [loading, setLoading] = useState(false);
  const Http = useHttp();

  const fetchData = (
    dateFrom = null,
    dateTo = null,
    pagination = 20,
    page = 1
  ) => {
    setLoading(true);
    Http.get(brochures, {
      params: { from_date: dateFrom, to_date: dateTo, pagination, page },
    }).then((res) => {
      setBrochuresRes(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      Header: 'Brochure',
      accessor: 'title',
      Cell: ({ row }) => {
        return (
          <Flex align='center'>
            <Image maxHeight='80px' src={row.original.images[0].url} />
            <Text mx='1em'>{`${row.original.title}`}</Text>
          </Flex>
        );
      },
    },
    {
      Header: 'Requests',
      accessor: 'requests',
    },
  ];

  return (
    <PageContainer
      title='Brochures'
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      endpoint={brochures}
      fetchData={fetchData}
      data={brochuresRes?.data}
      excelFields={['id', 'title', 'requests']}
    >
      {loading && <Loader />}
      {brochuresRes?.data?.length > 0 && (
        <CustomTable data={brochuresRes} columns={columns} />
      )}
    </PageContainer>
  );
};

export default Brochures;
