import React from 'react'
import setup from './setup'
import { Button} from '@builtbypixel/plasma';
import { Link } from 'react-router-dom';
import { ListWrapper } from '@builtbypixel/nucleus';

// Extra Menu appears next to the create button
const extraMenu = () => {
   return null
}

const List = () => {
   const columns = [
      {
            Header: 'ID',
            accessor: 'id',
            width: '20px',
        },

        {
            Header: 'Voucher name',
            accessor: 'voucher_name',
            Cell: ({ row }) => (
                <Link
                    to={`${setup.model}/edit/${row.original[setup.accessor]}`}
                >
                    <Button
                        variant='link'
                        variantColor='secondary'
                        rounded='md'
                    >
                        {row.original.name}
                    </Button>
                </Link>
            ),
        },

      {
         Header: 'Voucher Code',
         accessor: 'code'
      },
      {
         Header: 'Discount Amount',
         accessor: 'discount_amount'
      },
      {
         Header: 'Voucher Type',
         accessor: 'voucher_type.name'
      },
      {
         Header: 'Voucher Used',
         accessor: 'uses'
      }
   ]

   return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />
}

export default List
