import React, { useEffect, useState, useCallback } from 'react';
import { EditView, useHttp, Field } from '@builtbypixel/nucleus';
import {
  Flex,
  Box,
  Grid,
  Badge,
  Input,
  Button,
  Text,
  Switch,
  useColorMode,
} from '@builtbypixel/plasma';
import { useParams } from 'react-router-dom';
import setup from './setup';
import { useFormContext, useWatch } from 'react-hook-form';

const VatRegionSwitch = ({ item, index, countryVAT }) => {
  const [checked, setChecked] = useState(item.vat_region);
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    setValue(`regions[${index}]`, { ...item, vat_region: checked });
  }, [checked]);

  useEffect(() => {
    if (!countryVAT) {
      setValue(`regions[${index}]`, { ...item, vat_region: false });
    }
  }, [countryVAT]);

  const { colorMode } = useColorMode();
  return (
    <Flex
      align='center'
      bg={colorMode === 'dark' ? '#b6b9832b' : '#e3eff3ad'}
      px='1em'
      py='2px'
      borderRadius='5px'
    >
      <Text mr='0.5em'>VAT</Text>
      <Switch
        isChecked={checked}
        onChange={() => {
          setChecked(!checked);
        }}
      />
    </Flex>
  );
};
const VatCountrySwitch = ({ setHasCountryVAT }) => {
  const { getValues, setValue } = useFormContext();
  const [checked, setChecked] = useState(null);

  useEffect(() => {
    setHasCountryVAT(getValues('vat_country'));
    if (!checked) setChecked(getValues('vat_country'));
  }, [useWatch({ name: 'vat_country' })]);

  useEffect(() => {
    setValue('vat_country', checked);
    setHasCountryVAT(checked);
  }, [checked]);

  return (
    <>
      <Flex align='center' mb='1em'>
        <Switch
          isChecked={checked}
          onChange={() => {
            setChecked(!checked);
          }}
        />
        <Text mr='0.5em'>VAT?</Text>
      </Flex>
      <Text
        mt='0.5em'
        mb='1em'
        fontFamily='-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
        color='rgba(255, 255, 255, 0.48)'
        lineHeight='normal'
        fontSize='0.7em'
      >
        This will determine if VAT is applied to orders from this country.
      </Text>
    </>
  );
};

const UpdateName = (item, index) => {
  const { setValue } = useFormContext();
  const [regions, setRegions] = useState([]);
  const { id } = useParams();
  const Http = useHttp();
  const [regionName, setRegionName] = useState('');
  const countryId = id;

  useEffect(() => {
    getRegions();
  }, []);

  const getRegions = useCallback(() => {
    Http.get(`/countries/${countryId}/regions`)
      .then((data) => {
        setRegions(data.data.data);
      })
      .catch((error) => {
        console.log(`REGION ${error}`);
      });
  }, [Http, countryId]);

  const updateRegion = (e, item, index) => {

    setRegionName(e.target.value);

    const value = e.target.value;    

    const region_obj = {
      ...item.item,
      name: value
    };

    const updateRegion = [...regions];
    updateRegion[item.index] = region_obj;

    setValue('regions', updateRegion);

  }

  return (
    <Input
      component='text'
      placeholder='New name...'
      width='100%'
      value={regionName}
      onChange={(e) => updateRegion(e, item, index)}
      mr={5}
    />
  );
}

const EditForm = (props) => {
  const [hasCountryVAT, setHasCountryVAT] = useState(false);
  const [regions, setRegions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const { id } = useParams();
  const Http = useHttp();
  const [regionId, setRegionId] = useState('');
  const countryId = id;

  const getRegions = useCallback(() => {
    Http.get(`/countries/${countryId}/regions`)
      .then((data) => {
        setRegions(data.data.data);
      })
      .catch((error) => {
        console.log(`REGION ${error}`);
      });
  }, [Http, countryId]);

  useEffect(() => {
    getRegions();
  }, [getRegions]);

  const addRegion = (e) => {
    if (!inputValue) return;
    switch (e.key) {
      case 'Enter':
      case 'Tab':
        Http.post(`/countries/${countryId}/regions`, createOption(inputValue))
          .then((data) => {
            getRegions();
            setInputValue('');
          })
          .catch((error) => {
            console.log(`ADD REGION ${error}`);
          });
        setInputValue('');

        break;
      default:
        return;
    }
  };

  const deleteRegion = (regionId) => {
    console.log(regionId);
    Http.delete(`/countries/${countryId}/regions/${regionId}`)
      .then((data) => {
        getRegions();
      })
      .catch((error) => {
        console.log(`DELETE REGION ${error}`);
      });
  };

  const createOption = (value) => ({
    name: value,
  });

  const editRegion = (regionId) => {
    console.log(regionId);
    setRegionId(regionId);
  }

  return (
    <EditView setup={setup}>
      <Flex align='center' w='100%' justify='center' mt='20px'>
        <Grid templateColumns='1fr' w='60%' gap='10px' p='5px'>
          <Box w='500px' bg='grayBg' mb='20px' rounded='lg' p='20px'>
            <Field
              label='Name'
              name='name'
              component='text'
              placeholder='Country Name'
              isRequired={true}
              rules={{ required: true }}
              isReadOnly={true}
              mb={10}
            />

            {/* <Field
              component='switch'
              name='vat_country'
              help='This will determine if VAT is applied to orders from this country'
              mb={0}
              defaultValue={false}
              onClick={(event) => setHasCountryVAT(event.target.value)}
            >
              VAT?
            </Field> */}
            <VatCountrySwitch setHasCountryVAT={setHasCountryVAT} />

            <Field
              component='switch'
              name='deliver'
              help="This will determine if it's selectable on the delivery page"
              mb={0}
            >
              Deliverable?
            </Field>
            <Input
              label='Add a region'
              component='text'
              placeholder='Add a region and press Enter to add it to the list below'
              width='100%'
              name='addRegion'
              value={inputValue}
              onKeyUp={(e) => addRegion(e)}
              onChange={(e) => setInputValue(e.target.value)}
              mt={10}
              mb={5}
            />
            <Grid
              templateColumns='repeat(auto-fit, minmax(250px, 1fr))'
              gap={3}
            >
              {regions.map((item, index) => {
                return (
                  <Box width='100%'>
                    <Badge key={index} p={2} width='100%'>
                      <Flex align='center' justify='space-between'>
                        {regionId === item.id
                          ? <UpdateName item={item} index={index} />
                          : item.name
                        }
                        <Flex align='center'>
                          {hasCountryVAT && (
                            <VatRegionSwitch
                              item={item}
                              index={index}
                              countryVAT={hasCountryVAT}
                            />
                          )}

                          <Button
                            align='center'
                            as='button'
                            iconSpacing={2}
                            size='xs'
                            ml={3}
                            p='1.2em'
                            variant='solid'
                            variantColor='red'
                            onClick={() => deleteRegion(item.id)}
                          >
                            Delete
                          </Button>
                          <Button
                            align='center'
                            as='button'
                            iconSpacing={2}
                            size='xs'
                            ml={3}
                            p='1.2em'
                            variant='solid'
                            variantColor='primary'
                            onClick={() => editRegion(item.id)}
                          >
                            Edit
                          </Button>
                        </Flex>
                      </Flex>
                    </Badge>
                  </Box>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Flex>
    </EditView>
  );
};

export default EditForm;
