import React, { useState, useEffect, useContext } from 'react';
import {
  Flex,
  Box,
  Text,
  Button,
  Input,
  Checkbox,
  InputLeftAddon,
  Spinner,
} from '@builtbypixel/plasma';
import ExportToExcel from '../ExportToExcel';
import { GrTrash } from 'react-icons/gr';
import { BsSearch } from 'react-icons/bs';
import { FaGlobe, FaPhoneAlt, FaSms, FaEnvelope } from 'react-icons/fa';
import '../Toolbar/index.css';
import ReportsDatePicker from './ReportsDatePicker';
import moment from 'moment';
import { SiMicrosoftexcel } from 'react-icons/si';

import { useRecoilState } from 'recoil';
import { tableAtom } from '../../../../Nucleus/state/global';
const DateField = ({ setDate, placeholder, defaultDate }) => {
  return (
    <Flex align='center'>
      <Box bg='white'>
        <ReportsDatePicker
          format='YYYY-MM-DD'
          onChange={(date) => setDate(date)}
          placeholder={placeholder}
          defaultDate={defaultDate}
        />
      </Box>
    </Flex>
  );
};

const ToolBar = ({
  endpoint,
  currentPage,
  fetchData,
  data,
  excelFields,
  excelSecondTable,
  excelSecondTableFields,
  toolbarFilters,
  addStats,
  customExport,
}) => {
  const defaultFilters = {
    email: false,
    post: false,
    sms: false,
    phone: false,
  };
  const routesWithDefaultDates = [
    'products/most-viewed',
    'products/best-sellers',
    'customers-hear-us',
    'average-basket-products',
  ];
  const [dateFrom, setDateFrom] = useState(
    routesWithDefaultDates.includes(window?.location.search.slice(1))
      ? moment().startOf('month').format('YYYY-MM-DD')
      : null
  );
  const [dateTo, setDateTo] = useState(
    routesWithDefaultDates.includes(window?.location.search.slice(1))
      ? moment().format('YYYY-MM-DD')
      : null
  );

  const [preferenceFilter, setPreferenceFilter] = useState(defaultFilters);
  const [downloadURL, setDownloadURL] = useState(null);

  const [tableState, setTableState] = useRecoilState(tableAtom);

  const [loading, setLoading] = useState(false);
  // let defaultStart =
  //   window?.location.search.slice(1) === 'customers-hear-us'
  //     ? moment().startOf('month')
  //     : null;

  // let defaultEnd =
  //   window?.location.search.slice(1) === 'customers-hear-us' ? moment() : null;

  useEffect(() => {
    if (fetchData) {
      fetchData(
        dateFrom,
        dateTo,
        tableState.params.pagination,
        tableState.params.page,
        toolbarFilters?.includes('preference') ?? preferenceFilter
      );
    }
  }, [tableState.params.pagination, tableState.params.page]);

  useEffect(() => {
    setDateFrom(null);
    setDateTo(null);
    setPreferenceFilter(defaultFilters);
  }, [currentPage]);

  useEffect(() => {
    if (customExport) {
      setLoading(true);
      customExport()
        .then((res) => {
          setDownloadURL(res.data);
        })
        .catch((error) => {
          // Handle error here
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [customExport]);

  const handleSubmit = () => {
    fetchData(
      dateFrom,
      dateTo,
      tableState.params.pagination,
      tableState.params.page,
      preferenceFilter
    );
  };

  const renderFilters = () => {
    return toolbarFilters.map((el) => {
      if (el === 'preference') {
        return (
          <Flex>
            <Flex align='center'>
              <FaGlobe style={{ margin: '0 5px' }} />
              <Checkbox
                checked={preferenceFilter?.email}
                onChange={() =>
                  setPreferenceFilter({
                    ...preferenceFilter,
                    email: !preferenceFilter.email,
                  })
                }
              />
              <Text style={{ margin: '0 5px' }}>|</Text>
            </Flex>
            <Flex align='center'>
              <FaPhoneAlt style={{ margin: '0 5px' }} />
              <Checkbox
                checked={preferenceFilter?.phone}
                onChange={() =>
                  setPreferenceFilter({
                    ...preferenceFilter,
                    phone: !preferenceFilter.phone,
                  })
                }
              />
              <Text style={{ margin: '0 5px' }}>|</Text>
            </Flex>
            <Flex align='center'>
              <FaEnvelope style={{ margin: '0 5px' }} />
              <Checkbox
                checked={preferenceFilter?.post}
                onChange={() =>
                  setPreferenceFilter({
                    ...preferenceFilter,
                    post: !preferenceFilter.post,
                  })
                }
              />
              <Text style={{ margin: '0 5px' }}>|</Text>
            </Flex>
            <Flex align='center'>
              <FaSms style={{ margin: '0 5px' }} />
              <Checkbox
                checked={preferenceFilter?.sms}
                onChange={() =>
                  setPreferenceFilter({
                    ...preferenceFilter,
                    sms: !preferenceFilter.sms,
                  })
                }
              />
            </Flex>
          </Flex>
        );
      }
    });
  };

  return (
    <Flex minHeight='50px' p='0.2em' my='2em'>
      <Flex justify='space-between' gap={'50px'} width='100%' align='center'>
        <Flex
          justify='space-between'
          width={toolbarFilters ? '60%' : '45%'}
          mr={addStats && '3em'}
        >
          <DateField
            name='date_from'
            placeholder=' Select From Date'
            setDate={setDateFrom}
            date={dateFrom}
            defaultDate={
              routesWithDefaultDates.includes(window?.location.search.slice(1))
                ? moment().startOf('month')
                : null
            }
          />
          <DateField
            name='date_to'
            placeholder=' Select To Date'
            setDate={setDateTo}
            date={dateTo}
            defaultDate={
              routesWithDefaultDates.includes(window?.location.search.slice(1))
                ? moment()
                : null
            }
          />
          {toolbarFilters?.length > 0 && renderFilters()}
          <Button
            color='black'
            variant='solid'
            variantColor='black'
            bg='#cbd500'
            px='5em'
            _hover={{ background: '#e2e8f0' }}
            onClick={() => handleSubmit()}
          >
            <BsSearch />
            <Text ml='0.5em'>Filter</Text>
          </Button>
        </Flex>
        <Flex w={addStats && '70%'}>
          {addStats && addStats}
          {loading && <Spinner size='xs' speed='0.75s' />}
          { !loading&&  downloadURL &&  (
            <>
            
              <a href={downloadURL} target='_blank' rel="noreferrer">
                <Button
                  color='black'
                  variant='ghost'
                  variantColor='blue'
                  px='5em'
                >
                  <SiMicrosoftexcel />
                  <Text ml='0.5em'>Export</Text>
                </Button>
              </a>
            </>
          ) }
          {!loading&& !downloadURL && 
          (
            <ExportToExcel
              csvData={data}
              excelFields={excelFields}
              fileName={`${endpoint}-${
                dateFrom && dateTo ? dateFrom + '-' + dateTo : null
              }`}
              excelSecondTable={excelSecondTable}
              excelSecondTableFields={excelSecondTableFields}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ToolBar;
