import React from 'react';
import { ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@builtbypixel/plasma';

import setup from './setup';
import { Link } from 'react-router-dom';

// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const List = () => {
  const columns = [
    {
      Header: 'First Name',
      accessor: 'first_name',
      Cell: ({ row }) => (
        <Link to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant='link' variantColor='secondary' rounded='md'>
            {row.original.first_name}
          </Button>
        </Link>
      ),
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
      Cell: ({ row }) => (
        <Link to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant='link' variantColor='secondary' rounded='md'>
            {row.original.last_name}
          </Button>
        </Link>
      ),
    },
    {
      Header: 'Company Name',
      accessor: 'company_name',
    },
    {
      Header: 'Email Address',
      accessor: 'email',
    },
    {
      Header: 'Account Type',
      accessor: 'account_type',
      Cell: ({ row }) => <p>{row.original.account_type.name}</p>,
    },
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
