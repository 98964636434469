const modelName = 'Discount Codes';
const modelNameSingular = 'Discount Code';
const endpoint = '/discount-codes';

const setup = {
    title: modelName,
    singular: modelNameSingular,
    model: modelName.replace(/\s+/g, '-').toLowerCase(),
    canDelete: true,
    canAddNew: true,
    canSearch: true,
    endpoint,
    accessor: 'id',
};

export default setup;
