import React from 'react'
import { ListWrapper } from '@builtbypixel/nucleus'
import { Button } from '@builtbypixel/plasma';
import { Link } from 'react-router-dom';
import setup from './setup'

// Extra Menu appears next to the create button
const extraMenu = () => {
   return null
}

const List = () => {
   const columns = [
      {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ row }) => (
                <Link
                    to={`${setup.model}/edit/${row.original[setup.accessor]}`}
                >
                    <Button variantColor='secondary' variant='link'>
                        {row.original.name}
                    </Button>
                </Link>
            ),
        },
        {
         Header: 'Deliverable',
         accessor: 'deliver',
         Cell: ({ row }) => (
                row.original.deliver ? "True" : "False"
            ),
      },
   ];

   const filters = [
        {
            component: 'select',
            options: [
                {
                    "value": true,
                    "name": "True"
                },
                {
                    "value": false,
                    "name": "false"
                }
            ],
            labelKey: 'name',
            valueKey: 'value',
            name: 'deliver',
            placeholder: 'Filter Deliverable',
        },
    ];

   return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} filters={filters}/>
}

export default List
