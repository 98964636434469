import React from 'react';
import { Flex, Box, Text, Card } from '@builtbypixel/plasma';
import { FiShoppingCart, FiUser } from 'react-icons/fi';
import PageContainer from '../Components/PageContainer';
import { useHistory } from 'react-router-dom';
import NavBoxWrapper from '../Components/NavBoxWrapper';

const routes = [
  { text: 'Home', icon: null, path: 'home' },
  {
    text: 'Customers',
    icon: <FiUser />,
    path: 'customers',
    image: '/images/customers.jpg',
  },
  {
    text: 'Products',
    icon: <FiUser />,
    path: 'products',
    image: '/images/products.jpg',
  },
  {
    text: 'Discount Codes',
    icon: <FiUser />,
    path: 'discount-codes',
    image: '/images/discounts.jpg',
  },
  {
    text: 'Average Basket Products',
    icon: <FiUser />,
    path: 'average-basket-products',
    image: '/images/itemsbasket.jpg',
  },
  // { text: "POA", icon: <FiUser />, path: "poa" },
  // {
  //   text: 'Blogs',
  //   icon: <FiUser />,
  //   path: 'blogs',
  //   image: '/images/blogs.jpg',
  // },
  // { text: "Brochures", icon: <FiUser />, path: "brochures" },
  {
    text: 'Customer Preferences',
    icon: <FiUser />,
    path: 'customers-preferences',
    image: '/images/comms.jpg',
  },
  {
    text: 'Hear About Us',
    icon: <FiUser />,
    path: 'customers-hear-us',
    image: '/images/hearus.jpg',
  },
  // { text: 'Orders', icon: <FiShoppingCart />, path: 'orders' },
  // { text: 'Contact Us', icon: <FiUser />, path: 'contact-us' },
];

const Home = ({ setCurrentPage, currentPage }) => {
  return (
    <PageContainer title='Reports Dashboard' currentPage={currentPage}>
      <Flex
        width='100%'
        justify='space-evenly'
        flexWrap='wrap'
        mx='5em'
        h='fit-content'
      >
        {routes.map((route) => {
          return route.path === 'home' ? null : (
            <NavBoxWrapper
              text={route.text}
              icon={route.icon}
              image={route.image}
              path={route.path}
              setCurrentPage={setCurrentPage}
            ></NavBoxWrapper>
          );
        })}
      </Flex>
    </PageContainer>
  );
};

export default Home;
