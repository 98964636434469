import React, { useMemo } from 'react';

import { AutoFields, EditView, useGet } from '@builtbypixel/nucleus';
import useSWR from 'swr';
import setup from './setup';

const EditForm = () => {
  const { data: blogCategories } = useSWR('/blog-categories', useGet);

  const fields = useMemo(
    () => [
      {
        group: '',
        fields: [
          {
            name: 'title',
            component: 'text',
            placeholder: 'Article Title',
            label: 'Article Title',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.excerpt',
            component: 'text',
            placeholder: 'Article Excerpt',
            label: 'Article Excerpt',
            isRequired: true,
            rules: { required: true },
            help: 'please enter an excerpt for the article - Text only',
          },
          {
            name: 'content.body',
            component: 'richtext',
            label: 'Article Content',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.thumbnail',
            component: 'file',
            max: 1,
            label: 'Thumbnail',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.image',
            component: 'file',
            max: 1,
            label: 'Image',
            isRequired: true,
            rules: { required: true },
          },

          {
            name: 'date',
            component: 'date',
            valueKey: 'id',
            label: 'Date',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'category',
            component: 'select',
            isMulti: true,
            options: blogCategories?.data,
            labelKey: 'name',
            valueKey: 'id',
            label: 'Blog Category',
            isRequired: true,
            rules: { required: true },
          },

          {
            name: 'active',
            component: 'switch',
            label: 'Active?',
          },
          {
            name: 'content.countdown_date',
            component: 'dateTime',
            placeholder: 'Set Countdown Timer',
            label: 'Countdown Timer',
          },
          {
            name: 'content.countdown_active',
            component: 'switch',
            label: 'Countdown Timer Active',
            defaultValue: false,
          },
        ],
      },
    ],
    [blogCategories]
  );
  return (
    <EditView setup={setup} isFullWidth>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
