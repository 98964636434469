import React, { useMemo } from 'react';
import { AutoFields } from '@builtbypixel/nucleus';
import { useWatch } from 'react-hook-form';

const Fields = ({ fields }) => {
  const type = useWatch({ name: 'type' });

  const dropdownFields = useMemo(() =>
    type?.id === 4 || type?.id === 5 || type?.id === 10
      ? [
          {
            group: 'Manage Options',
            fields: [
              {
                name: 'options',
                keyName: 'attset',
                component: 'repeater',
                label: 'Groups',
                rules: { required: true },
                fieldArray: [
                  {
                    name: 'visible',
                    label: 'Visible?',
                    component: 'switch',
                  },
                  {
                    name: 'name',
                    component: 'text',
                    placeholder: 'Name',
                    label: 'Name',
                    rules: { required: true },
                  },
                  {
                    name: 'content',
                    component: 'file',
                    placeholder: 'Image',
                    label: 'Image',
                    max: 1,
                    useBaseUrl: true,
                  },
                ],
              },
            ],
          },
        ]
      : []
  );
  return <AutoFields fields={[...fields, ...dropdownFields]} />;
};

export default Fields;
