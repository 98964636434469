const menuLinks = [
  {
    group: 'Dashboard',
    link: '/dashboard',
    items: [],
    permissions: [
      'Super Admin',
      'Admin',
      'Sales',
      'Purchase',
      'Finance',
      'Director',
      'Stock & Codes',
      'Warehouse',
    ],
  },
  {
    group: 'Blogs',
    link: '/blogs',
    items: [
      {
        title: 'Blog',
        href: '/blog-articles',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Blog Categories',
        href: '/blog-categories',
        permissions: ['Super Admin', 'Admin'],
      },
    ],
    permissions: ['Super Admin', 'Admin'],
  },
  {
    group: 'Notifications',
    link: '/notifications',
    items: [
      {
        title: 'Brochure Requests',
        href: '/brochure-requests',
        items: [],
        permissions: ['Super Admin', 'Admin', 'Sales'],
      },
      {
        title: 'Contact Forms',
        href: '/contact-forms',
        items: [],
        permissions: ['Super Admin', 'Admin', 'Sales', 'Purchase'],
      },
    ],
    permissions: ['Super Admin', 'Admin', 'Sales', 'Purchase'],
  },
  {
    group: 'Administrator',
    link: '/Administrators',
    items: [],
    permissions: ['Super Admin'],
  },
  {
    group: 'Departments',
    link: '/departments',
    items: [],
    permissions: ['Super Admin', 'Admin'],
  },
  {
    group: 'Pages',
    link: '/pages',
    items: [
      {
        title: 'Pages',
        href: '/pages',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Brochures',
        href: '/brochures',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Events',
        href: '/events',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Site Banner',
        href: '/site-banner/edit/1',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
    ],
    permissions: ['Super Admin', 'Admin'],
  },
  {
    group: 'Products',
    link: '/products',
    items: [
      {
        title: 'Orderwise Products',
        href: '/orderwise-products',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Attribute Sets',
        href: '/attribute-sets',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Attributes',
        href: '/attributes',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Product Banners',
        href: '/banners',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Template Options',
        href: '/template-options',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Categories',
        href: '/product-categories',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Sub Categories',
        href: '/product-sub-categories',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Products',
        href: '/products',
        items: [],
        permissions: ['Super Admin', 'Admin', 'Stock & Codes'],
      },
      // {
      //     title: 'Discount Codes',
      //     href: '/discount-codes',
      //     items: [],
      // permissions: ['Super Admin', 'Admin'],

      //     isDisabled: true,
      // },
      {
        title: 'Sources',
        href: '/sources',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
    ],
    permissions: ['Super Admin', 'Admin', 'Stock & Codes'],
  },
  {
    group: 'Customers',
    link: '/customers',
    items: [
      {
        title: 'Customers',
        href: '/customers',
        items: [],
        permissions: ['Super Admin', 'Admin', 'Sales', 'Finance'],
      },
      {
        title: 'Account Types',
        href: '/account-types',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
    ],
    permissions: ['Super Admin', 'Admin', 'Finance', 'Sales'],
  },
  {
    group: 'Orders',
    link: '/orders',
    items: [
      {
        title: 'Orders',
        href: '/orders',
        items: [],
        permissions: ['Super Admin', 'Admin', 'Sales', 'Finance'],
      },
      {
        title: 'Order Settings',
        href: '/order-settings/edit/1',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
    ],
    permissions: ['Super Admin', 'Admin', 'Sales', 'Warehouse'],
  },
  {
    group: 'Product price requests',
    link: '/product-requests',
    items: [],
    permissions: ['Super Admin', 'Admin', 'Sales'],
  },
  {
    group: 'Vouchers',
    link: '/vouchers',
    items: [],
    permissions: ['Super Admin', 'Admin'],
  },
  {
    group: 'Deliveries',
    link: '/delivery-methods',
    items: [
      {
        title: 'Countries',
        href: '/countries',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Delivery Methods',
        href: '/delivery-methods',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Couriers',
        href: '/couriers',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
    ],
    permissions: ['Super Admin', 'Admin'],
  },
  {
    group: 'Orderwise Integration',
    link: '/orderwise-integrations',
    items: [],
    permissions: ['Super Admin', 'Admin'],
  },
  {
    group: 'Orderwise Activity Log',
    link: '/orderwise-activity-logs',
    items: [],
    permissions: ['Super Admin', 'Admin'],
  },

  {
    group: 'Reports',
    link: '/reports',
    items: [
      {
        title: 'Home',
        href: '/reports?home',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Average Basket Products',
        href: '/reports?average-basket-products',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      // {
      //   title: 'Brochures',
      //   href: '/reports?brochures',
      //   items: [],
      //   permissions: ['Super Admin', 'Admin'],
      // },
      // {
      //   title: 'Contact Us',
      //   href: '/reports?contact-us',
      //   items: [],
      //   permissions: ['Super Admin', 'Admin'],
      // },
      {
        title: 'Customers',
        href: '/reports?customers',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Customers - Hear Us',
        href: '/reports?customers-hear-us',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Customers - Preferences',
        href: '/reports?customers-preferences',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Discount Codes',
        href: '/reports?discount-codes',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      {
        title: 'Products',
        href: '/reports?products',
        items: [],
        permissions: ['Super Admin', 'Admin'],
      },
      // {
      //   title: 'Orders',
      //   href: '/reports?orders',
      //   items: [],
      //   permissions: ['Super Admin', 'Admin'],
      // },
      // {
      //   title: 'POA',
      //   href: '/reports?poa',
      //   items: [],
      //   permissions: ['Super Admin', 'Admin'],
      // },
    ],
    permissions: ['Super Admin', 'Admin'],
  },
  {
    group: 'Settings',
    link: '/settings',
    items: [],
    permissions: ['Super Admin', 'Admin'],
  },
];

export default menuLinks;
