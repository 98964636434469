import { createPageRoutes } from '@builtbypixel/nucleus';
import List from './List';

import setup from './setup';

import Home from './Home';
import FAQ from './FAQ';
import Terms from './TermsConditions';
import Privacy from './PrivacyPolicy';
import DeliveryInformation from './DeliveryInformation';
import About from './About';
import ContactUs from './ContactUs';
import Events from "./Events";

const pages = [
  {
    id: 1,
    component: Home,
    title: "Home",
  },
  {
    id: 2,
    component: FAQ,
    title: "FAQ",
  },
  {
    id: 3,
    component: Terms,
    title: "Terms and Conditions",
  },
  {
    id: 4,
    component: Privacy,
    title: "Privacy Policy",
  },
  {
    id: 5,
    component: About,
    title: "About Us",
  },
  {
    id: 6,
    component: DeliveryInformation,
    title: "Delivery Information",
  },
  {
    id: 7,
    component: ContactUs,
    title: "Contact Us",
  },
  {
    id: 8,
    component: Events,
    title: "Events",
  },
];

const crud = createPageRoutes({ list: List, pages, setup });

const otherRoutes = [];

const routes = [...crud, ...otherRoutes];

export default routes;
