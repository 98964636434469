import React from 'react';
import { Box, Table, Text } from '@builtbypixel/plasma';

const PriceTable = ({ data }) => {
  const columns = [
    {
      Header: 'Product',
      accessor: 'name',
    },
    {
      Header: 'Value',
      accessor: 'value',
    },
    {
      Header: 'Characters',
      accessor: 'characters',
    },
    {
      Header: 'Engraving Price',
      accessor: 'price',
      Cell: ({ row }) => {
        return <Text>{`£${Number(row.original.price).toFixed(2)}`}</Text>;
      },
    },
    {
      Header: 'Service Charge',
      accessor: 'service_charge',
      Cell: ({ row }) => {
        return (
          <Text>{`£${Number(row.original.service_charge).toFixed(2)}`}</Text>
        );
      },
    },
    {
      Header: 'Total',
      Cell: ({ row }) => {
        return (
          <Text>{`£${Number(
            Number(row.original.service_charge) + Number(row.original.price)
          ).toFixed(2)}`}</Text>
        );
      },
    },
  ];

  return (
    <Box mt='1em'>
      <Table
        columns={columns}
        data={{ data: data?.products }}
        hidePagination={true}
      />
    </Box>
  );
};

export default PriceTable;
