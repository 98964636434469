import { EditView, useGet, useHttp } from '@builtbypixel/nucleus';
import {
  Button,
  Flex,
  PopConfirm,
  useToast,
  Text,
  Box,
} from '@builtbypixel/plasma';
import React, { useMemo, useState, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import RelatedProducts from '../Products/Fields/RelatedProducts';
import AttributeFields from '../Products/Fields/AttributeFields';
import Upsells from '../Products/Fields/Upsells';
import Slug from '../Products/Fields/Slug';
import SKU from '../Products/Fields/SKU';

import setup from './setup';
import DynamicFields from '../Products/Fields/DynamicFields';
import InitialOptions from '../Products/Fields/InitialOptions';
import inventoryFields from '../Products/Fields/inventoryFields';
import { GrView } from 'react-icons/gr';
import { BiCopy } from 'react-icons/bi';
import FreeDeliveryRegions from '../Products/components/FreeDeliveryRegions';

const EditForm = () => {
  const { type } = useParams();
  const selectedAttrSet = window.localStorage.getItem('attributeSet');

  useEffect(() => {
    if (type === 'create' && selectedAttrSet) {
      window.localStorage.removeItem('configurable_attribute');
      window.localStorage.removeItem('attributeSet');
    }
  }, []);

  return (
    <EditView setup={setup} isFullWidth>
      <FieldComponent />
    </EditView>
  );
};

const ProductTitle = () => {
  const title = useWatch({ name: 'name' });
  const sku = useWatch({ name: 'sku' });

  return (
    <Flex align='center' mr='25px' w='100%'>
      <Text mr='25px' fontSize='1.3em' fontWeight='semibold'>
        {title}
      </Text>
      <pre style={{ opacity: 0.6 }}>{sku}</pre>
    </Flex>
  );
};

const FieldComponent = () => {
  const { type, id } = useParams();

  const selectedProductType = useWatch({ name: 'product_type' });
  const [duplicating, setDuplicating] = useState(false);

  console.log(selectedProductType, 'selected product');

  const Http = useHttp();
  const toast = useToast();

  const { data: productTypes } = useSWR('/product-types', useGet);
  const { data: attributeTypes } = useSWR('/attribute-sets', useGet);
  const { data: countries } = useSWR('/countries', useGet);
  const { data: banners } = useSWR('/banners?pagination=9999', useGet);

  const duplicateProduct = () => {
    setDuplicating(true);
    Http.post(`/products/${id}/duplicate`)
      .then((res) => {
        window.location.href = `/products/edit/${res.data.data.id}`;
      })
      .catch((err) => {
        toast({
          status: 'error',
          message: 'Error duplicating product',
        });
      });
  };

  useEffect(() => {
    localStorage.removeItem('attribute_sets');
  }, []);

  const fields = useMemo(
    () => [
      {
        tab: 'General',
        fields: [
          {
            name: 'name',
            component: 'text',
            label: 'Name',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'sku',
            label: 'SKU',
            isRequired: true,
            rules: { required: true },
            component: SKU,
            help: 'When this field is clicked off, a check will be made if the SKU already exists or not.',
          },
          {
            name: 'slug',
            label: 'Slug',
            component: Slug,
            help: 'Leaving blank will auto generate a slug when saved (if creating) ',
          },
          {
            name: 'featured_position',
            component: 'text',
            label: 'Featured Position',
            help: 'Enter a number to rank this product in the products list e.g. 1',
          },
          {
            name: 'description',
            component: 'richtext',
            label: 'Description',
          },
          {
            name: 'additional_info',
            component: 'textarea',
            label: 'Additional Info',
          },
          {
            name: 'notes',
            component: 'textarea',
            label: 'Notes',
          },

          {
            name: 'status',
            component: 'select',
            options: [
              { name: 'Active', id: 1 },
              { name: 'Inactive', id: 2 },
              { name: 'Not Visible Individually', id: 3 },
            ],
            labelKey: 'name',
            label: 'Status',
            valueKey: 'id',
            placeholder: 'Select status of field',
            rules: { required: true },
            isRequired: true,
          },
        ],
      },
      {
        tab: 'Prices',
        fields: [
          {
            name: 'showcase',
            component: 'switch',
            label: 'Show Price Request Form?',
            help: 'Select this to show a price request form to the customer rather than live price',
          },
          {
            name: 'showcase_price',
            component: 'switch',
            label: 'Show Price When Request Form Is Visible',
          },
          {
            name: 'price',
            component: 'text',
            label: 'Price',
          },
          {
            name: 'information.price_prefix',
            component: 'text',
            label: 'Price prefix',
            placeholder: 'Eg "Only"...',
          },
          {
            name: 'information.price_suffix',
            component: 'text',
            label: 'Price suffix',
            placeholder: 'Eg "each"...',
          },
          {
            name: 'vat_applicable',
            component: 'switch',
            defaultValue: true,
            label: 'VAT Applicability',
            help: 'Select whether the product is taxable or not.',
          },
          {
            name: 'discounted_price',
            component: 'text',
            label: 'Discounted Price',
          },
          {
            name: 'discount_from',
            component: 'date',
            label: 'Discounted From',
          },
          {
            name: 'discount_to',
            component: 'date',
            label: 'Discounted To',
          },
          {
            name: 'information.hide_discount_dates',
            component: 'switch',
            label: 'Hide Discount Dates',
            help: 'Turn this on to hide the date',
            defaultValue: false,
          },
          {
            name: 'price_tiers',
            component: 'repeater',
            label: 'Price Tiers',
            singleName: 'Tier',
            fieldArray: [
              {
                component: 'text',
                name: 'price',
                type: 'number',
                label: 'Price',
              },
              {
                component: 'text',
                name: 'quantity',
                type: 'number',
                label: 'Quantity',
              },
            ],
          },
        ],
      },
      ...inventoryFields,
      {
        tab: 'Meta Information',
        fields: [
          {
            name: 'seo_index.meta_title',
            component: 'text',
            label: 'Meta Title',
            rules: { required: true },
            isRequired: true,
          },
          {
            name: 'seo_index.meta_description',
            component: 'textarea',
            label: 'Meta Description',
            rules: { required: true },
            isRequired: true,
          },
          {
            name: 'seo_index.meta_keywords',
            component: 'textarea',
            label: 'Meta Keywords',
          },
        ],
      },
      {
        group: 'Product Attributes',
        tab: 'Product Attributes',
        component: <AttributeFields />,
        fields: [],
      },

      {
        group: 'Images',
        tab: 'Images',

        fields: [
          {
            name: 'images',
            label: 'Product Images',
            component: 'file',
          },
        ],
      },
      {
        tab: 'Extra Information',
        fields: [
          {
            name: 'content.title',
            label: 'Banner Title',
            component: 'text',
          },
          {
            name: 'content.body',
            component: 'richtext',
            label: 'Extra Product Information',
            help: 'Add information such as link to bundle set',
          },
          {
            name: 'content.background',
            component: 'colour',
            label: 'Banner Background',
          },
          {
            name: 'content.fontColour',
            component: 'colour',
            label: 'Banner Font Colour',
          },
          {
            name: 'content.active',
            component: 'switch',
            label: 'Active',
          },
        ],
      },
      {
        tab: 'Orderwise',
        fields: [
          {
            name: 'orderwise_product_id',
            component: 'text',
            label: 'Orderwise ID',
          },
          {
            name: 'ow_standard_eng',
            component: 'text',
            label: 'Standard Engraving Orderwise Code',
          },
          {
            name: 'ow_individual_eng',
            component: 'text',
            label: 'Individual Engraving Orderwise Code',
          },
          {
            name: 'nominal_code_id',
            component: 'text',
            label: 'Nominal Code',
          },
        ],
      },

      {
        group: 'Related Products',
        tab: 'Related Products',
        auto: false,
        fields: [
          {
            name: 'related_products',
            component: RelatedProducts,
            productTypes: productTypes?.data,
            attributes: attributeTypes?.data,
          },
        ],
      },
      {
        group: 'Up-sells',
        tab: 'Up-sells',
        fields: [
          {
            name: 'upsells',
            component: Upsells,
            productTypes: productTypes?.data,
            attributes: attributeTypes?.data,
          },
        ],
      },
      {
        group: 'Product Surcharge',
        tab: 'Surcharges',
        auto: false,
        fields: [
          {
            name: 'surcharge_amount',
            component: 'text',
            label: 'Surcharge Amount',
            help: 'The amount of items to trigger the surcharge',
          },
          {
            name: 'surcharge_price',
            component: 'text',
            label: 'Surcharge Price',
            help: 'The price per surcharge',
          },
          {
            name: 'surcharge_orderwise',
            component: 'text',
            label: 'Surcharge Orderwise Code',
            help: 'The price per surcharge',
          },
        ],
      },
      {
        group: 'Free Delivery Countries',
        tab: 'Free Delivery Countries',
        fields: [
          {
            name: 'free_delivery_countries',
            component: 'select',
            isMulti: true,
            options: countries?.data,
            labelKey: 'name',
            valueKey: 'id',
            label: 'Countries',
            help: 'Select the countries for which this product will have free delivery.',
            // isRequired: true,
            // rules: { required: true },
          },
          {
            name: 'free_delivery_regions',
            component: FreeDeliveryRegions,
          },
        ],
      },
      {
        group: 'Product Banners',
        tab: 'Product Banners',
        fields: [
          {
            name: 'banners',
            component: 'select',
            isMulti: true,
            options: banners?.data,
            labelKey: 'name',
            valueKey: 'id',
            label: 'Banners',
            help: 'Select the offer banners to be displayed on the product page.',
          },
        ],
      },
    ],
    [attributeTypes, productTypes, banners]
  );

  const slug = useWatch({ name: 'slug' });

  return !selectedProductType ? (
    <Box w='100%'>
      <InitialOptions />
    </Box>
  ) : (
    <Box w='100%' display={selectedProductType ? 'block' : 'none'}>
      <Flex justify='flex-start' w='100%' p='10px' align='center'>
        <ProductTitle />
        {type === 'edit' && (
          <>
            <Button
              variantColor='primary'
              as='a'
              size='sm'
              variant='ghost'
              px='20px'
              leftIcon={<GrView />}
              mr='20px'
              ml='10px'
              href={`${process.env.REACT_APP_FRONTEND_URL}/products/${slug}`}
              target='_blank'
            >
              View on website
            </Button>
            <PopConfirm
              onConfirm={() => duplicateProduct()}
              title='Are you sure you want to duplicate this product?'
            >
              <Button
                variantColor='primary'
                size='sm'
                variant='ghost'
                leftIcon={<BiCopy />}
                isLoading={duplicating}
                isDisabled={duplicating}
              >
                Duplicate Product
              </Button>
            </PopConfirm>
          </>
        )}
      </Flex>
      <DynamicFields
        fields={fields}
        productTypes={productTypes}
        attributeTypes={attributeTypes}
      />
    </Box>
  );
};

export default EditForm;
