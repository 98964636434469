/* global localStorage, */

import axios from 'axios'
import { generateSlugFromSite } from '../helpers'

const hasToken = JSON.parse(
   localStorage.getItem(`${generateSlugFromSite()}-token`)
)
const site = JSON.parse(localStorage.getItem(`${generateSlugFromSite()}-site`))

if (hasToken) {
   axios.defaults.headers.common.Authorization = `Bearer ${hasToken.token}`
}

const api = process.env.REACT_APP_API
const prefix = process.env.REACT_APP_API_PREFIX

export const useHttp = () => {
   const baseApi = `${api}/${prefix}/${site?.path}`
   axios.defaults.baseURL = baseApi
   axios.defaults.headers.common.Accept = 'application/json'
   axios.defaults.headers.common['Content-Type'] = 'application/json'

   return axios
}
