import ConfigProducts from './ConfigProducts';

const configProductsField = (usedProductType, productTypes, attributeTypes) =>
    usedProductType?.id === 3
        ? [
              {
                  group: 'Product Variants',
                  tab: 'Product Variants',
                  fields: [
                      {
                          name: 'configurable_products',
                          component: ConfigProducts,
                          productTypes: productTypes?.data,
                          attributes: attributeTypes?.data,
                      },
                  ],
              },
          ]
        : [];

export default configProductsField;
