import { Box, Grid } from '@builtbypixel/plasma';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ProductsTable from '../components/ProductsTable';
import SelectedProductsList from '../components/SelectedProductsList';

const CrossSells = ({ productTypes, attributes }) => {
    const name = 'products';
    const { setValue } = useFormContext();
    const selected = useWatch({ name: name });

    const onRowSelect = (rows) => {
        setValue(name, rows);
    };

    return (
        <Box>
            <ProductsTable
                selected={selected}
                productTypes={productTypes}
                attributes={attributes}
                onRowSelect={onRowSelect}
            />
            <Grid gap='10px' mb='20px' mt='20px'>
                {selected &&
                    selected?.map((item, i) => (
                        <SelectedProductsList
                            item={item}
                            name={name}
                            key={`item-${i}`}
                            index={i}
                        />
                    ))}
            </Grid>
        </Box>
    );
};

export default CrossSells;
