import React, { memo, useRef } from 'react';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Button,
  Image,
  Flex,
  useColorMode,
  Icon,
} from '@builtbypixel/plasma';
import { use100vh } from 'react-div-100vh';
import { globalAtom, menuAtom } from '../../state/global';
import { useRecoilState } from 'recoil';
import LinkButton from '../LinkButton';
import { useRouteMatch } from 'react-router-dom';
import { FiArrowUpRight } from 'react-icons/fi';
import { checkPermissions, getSite } from '../../helpers';
import { useOutsideClick } from '../../hooks';

const MenuLinkButton = memo(({ to, children, target, leftIcon, isChild }) => {
  const match = useRouteMatch({
    path: to,
    exact: true,
  });

  return (
    <LinkButton to={to}>
      <Button
        variant={match ? 'solid' : 'ghost'}
        isFullWidth
        justifyContent='flex-start'
        rightIcon={target && <FiArrowUpRight />}
        fontWeight={isChild ? 'normal' : 'semibold'}
        opacity={isChild ? 0.7 : 1}
        leftIcon={
          <Icon fontSize='20px' color={match ? 'primary' : 'menuIconColour'}>
            {leftIcon}
          </Icon>
        }
        size='md'
        color={match && 'primary'}
        mb={isChild ? '0px' : '5px'}
      >
        {children}
      </Button>
    </LinkButton>
  );
});

const Menu = ({ links }) => {
  const height = use100vh();
  const site = getSite;
  const { colorMode } = useColorMode();
  const [global, setGlobal] = useRecoilState(globalAtom);
  const [_menu, setMenu] = useRecoilState(menuAtom);
  const menu = useRef();

  useOutsideClick(menu, () => setMenu(false));

  const hasPermission = (permissions) => {
    if (global.user) {
      if (global.user?.data?.roles?.[0]?.name) {
        return checkPermissions({
          has: global.user?.data?.roles?.[0]?.name,
          required: permissions,
        });
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const getTransform = _menu ? 'translateX(50px)' : 'translateX(-100%)';

  return (
    <React.Fragment>
      <Box
        h={height}
        maxHeight={height}
        w='200px'
        minWidth='200px'
        py={2}
        px={3}
        overflowY='scroll'
        bg='global.elementBg'
        left={0}
        top={0}
        zIndex={901}
        transform={{
          xs: getTransform,
          md: 'none',
        }}
        transition='0.3s all ease-in-out'
        ref={menu}
        position={{
          xs: 'fixed',
          md: 'relative',
        }}
      >
        <Flex px={3} justify='flex-start' textAlign='center' my='20px'>
          <Image
            w='auto'
            objectFit='contain'
            h='60px'
            src={site?.clientLogoUrl[colorMode]}
          />
        </Flex>
        <Box>
          {links &&
            links.map((group, groupIndex) =>
              group.items && group.items.length === 0
                ? hasPermission(group.permissions ? group.permissions : []) && (
                    <MenuLinkButton
                      key={`group--${groupIndex}`}
                      to={group.link}
                      target={group.target}
                      leftIcon={group.icon && group.icon}
                    >
                      {group.group}
                    </MenuLinkButton>
                  )
                : hasPermission(group.permissions ? group.permissions : []) && (
                    <Accordion
                      key={`${group.group}-${groupIndex}`}
                      collapsible
                      multiple
                    >
                      <AccordionItem>
                        <AccordionButton arrowAlign='right' pl='16px'>
                          {group.group}
                        </AccordionButton>
                        <AccordionPanel px={0}>
                          {group.items &&
                            group.items.map(
                              (item, index) =>
                                hasPermission(
                                  item.permissions ? item.permissions : []
                                ) && (
                                  <MenuLinkButton
                                    target={item.target}
                                    to={item.href}
                                    key={`${item.title}-${index}-${groupIndex}`}
                                    isChild
                                  >
                                    {item.title}
                                  </MenuLinkButton>
                                )
                            )}
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  )
            )}
        </Box>
      </Box>
      <Box
        w='100vw'
        h='100vh'
        bg='rgba(0,0,0,0.2)'
        position='fixed'
        zIndex={800}
        top={0}
        left={0}
        content="' '"
        display={_menu ? 'block' : 'none'}
      />
    </React.Fragment>
  );
};

export default Menu;
