import React from 'react';
import { ListWrapper } from '@builtbypixel/nucleus';
import { Button, Text, Icon } from '@builtbypixel/plasma';
import { Image, Transformation } from 'cloudinary-react';
import { Link } from 'react-router-dom';
import setup from './setup';
import { MdCheck } from 'react-icons/md';
import moment from 'moment';
// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const List = () => {
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'model_id',
      accessor: 'model_id',
      Cell: ({ row, value }) => {
        const models = {
          Order: `orders/view/${value}`,
          Customer: `customers/edit/${value}`,
          Product: `products/edit/${value}`,
        };

        return (
          <Link
            target={'_blank'}
            rel='noopener noreferrer'
            to={`${models[row.original.model]}`}
          >
            <Button
              variant='link'
              href={`${models[row.original.model]}`}
              target={'_blank'}
              rel='noopener noreferrer'
              as='a'
              variantColor='secondary'
              rounded='md'
            >
              {value}
            </Button>
          </Link>
        );
      },
    },
    {
      Header: 'model',
      accessor: 'model',
    },
    {
      Header: 'description',
      accessor: 'description',
    },
    {
      Header: 'date',
      accessor: 'created_at',
      Cell: ({ value }) => moment(value).format('DD/MM/YYYY hh:mm a'),
    },
  ];

  const filters = [];

  return (
    <ListWrapper
      setup={setup}
      columns={columns}
      extraMenu={extraMenu}
      filters={filters}
    />
  );
};

export default List;
