import React from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';

import { inputTypes } from '../../common/inputTypes';

import setup from './setup';

const status = [
  {
    id: 1,
    name: 'Active',
  },
  {
    id: 2,
    name: 'Inactive',
  },
];

const priceTypes = [
  {
    id: 1,
    name: 'Fixed',
  },
  {
    id: 2,
    name: 'Percent',
  },
  {
    id: 3,
    name: 'Fixed Per Character',
  },
  {
    id: 4,
    name: 'Fixed + Fixed Per Character',
  },
];
export const sharedFieldArray = [
  {
    name: 'title',
    component: 'text',
    placeholder: 'Enter title',
    label: 'Title',
  },
  {
    name: 'view_mode',
    component: 'select',
    options: status,
    labelKey: 'name',
    valueKey: 'id',
    label: 'Is Active?',
  },
  {
    name: 'is_required',
    component: 'switch',
    label: 'Is Required',
  },
  {
    name: 'input_type',
    component: 'select',
    options: inputTypes,
    labelKey: 'name',
    label: 'Input Type',
    valueKey: 'id',
    placeholder: 'Select type of field',
  },
  {
    name: 'information.dropdownOptions',
    component: 'repeater',
    placeholder: 'Add dropdown options',
    label: 'Dropdown Options',
    defaultValue: [],
    parentName: 'information',
    condition: {
      repeater: true,
      watchField: 'input_type',
      matchValue: [inputTypes[3], inputTypes[4]],
      operator: '===',
      currentName: 'blocks',
      childComponent: 'repeater',
    },
    fieldArray: [
      {
        name: 'title',
        component: 'text',
        placeholder: 'Enter title',
        label: 'Title',
      },
      {
        name: 'price',
        component: 'text',
        type: 'number',
        placeholder: 'Price',
        label: 'Price',
      },
      {
        name: 'description',
        component: 'textarea',
        placeholder: 'Enter description',
        label: 'Description',
      },
      {
        name: 'sku',
        component: 'text',
        placeholder: 'Enter SKU',
        label: 'SKU',
      },
    ],
  },
  {
    name: 'information.swatch_options',
    component: 'repeater',
    placeholder: 'Add swatch options',
    label: 'Swatch Options',
    defaultValue: [],
    parentName: 'information',
    condition: {
      repeater: true,
      watchField: 'input_type',
      matchValue: [inputTypes[7]],
      operator: '===',
      currentName: 'blocks',
    },
    fieldArray: [
      {
        name: 'colour',
        component: 'colour',
        placeholder: 'Select Colour',
        label: 'Colour',
      },
      {
        name: 'label',
        component: 'text',
        type: 'text',
        placeholder: 'Label',
        label: 'Label',
      },
    ],
  },
  {
    name: 'description',
    component: 'textarea',
    placeholder: 'Enter description',
    label: 'Description',
  },
  {
    name: 'information.price_type',
    component: 'select',
    options: priceTypes,
    labelKey: 'name',
    label: 'Price Type',
    valueKey: 'id',
    placeholder: 'Select price type',
    parentName: 'blocks',
    condition: {
      repeater: true,
      watchField: 'input_type',
      matchValue: [inputTypes[3], inputTypes[4], inputTypes[8]],
      operator: '!==',
      currentName: 'blocks',
      childComponent: 'select',
    },
  },
  {
    name: 'information.price_type',
    component: 'select',
    options: [priceTypes[2]],
    labelKey: 'name',
    label: 'Price Type',
    valueKey: 'id',
    placeholder: 'Select price type',
    parentName: 'blocks',
    condition: {
      repeater: true,
      watchField: 'input_type',
      matchValue: [inputTypes[8]], //COMPLEX ENGRAVING ONLY
      operator: '===',
      currentName: 'blocks',
      childComponent: 'select',
    },
  },
  {
    name: 'information.fixed_price',
    component: 'text',

    placeholder: '£0.00',
    parentName: 'information',
    label: 'Fixed Price',
    condition: {
      repeater: true,
      watchField: 'information.price_type',
      matchValue: [priceTypes[3]],
      operator: '===',
      currentName: 'blocks',
      childComponent: 'text',
    },
  },
  {
    name: 'information.fixed_price',
    component: 'text',

    placeholder: '£0.00',
    parentName: 'information',
    label: 'Service Charge',
    condition: {
      repeater: true,
      watchField: 'input_type',
      matchValue: [inputTypes[8]], //FOR COMPLEX ENGRAVING ONLY
      operator: '===',
      currentName: 'blocks',
      childComponent: 'text',
    },
  },
  {
    name: 'information.fixed_char_limit',
    component: 'text',

    placeholder: 'Enter max number of characters that applies to fixed amount',
    parentName: 'information',
    label: 'Fixed Character Limit',
    condition: {
      repeater: true,
      watchField: 'information.price_type',
      matchValue: [priceTypes[3]],
      operator: '===',
      currentName: 'blocks',
      childComponent: 'text',
    },
  },
  {
    name: 'information.price_per_character',
    component: 'text',

    placeholder: '£0.00',
    parentName: 'information',
    label: 'Price per character',
    condition: {
      repeater: true,
      watchField: 'information.price_type',
      matchValue: [priceTypes[3]],
      operator: '===',
      currentName: 'blocks',
      childComponent: 'text',
    },
  },
  // {
  //   name: 'dependancy',
  //   component: 'select',
  //   options: dependency,
  //   labelKey: 'name',
  //   label: 'Dependency',
  //   valueKey: 'id',
  //   placeholder: 'Select dependency',
  // },
  // {
  //   name: 'information.sku',
  //   component: 'text',
  //   placeholder: 'Enter SKU',
  //   label: 'SKU',
  //   condition: {
  //     repeater: true,
  //     watchField: 'input_type',
  //     matchValue: [inputTypes[3]],
  //     operator: '!==',
  //     currentName: 'blocks',
  //     childComponent: 'text',
  //   },
  // },
  {
    name: 'information.price',
    component: 'text',

    placeholder: '£0.00 or %',
    parentName: 'information',
    label: 'Price',
    condition: {
      repeater: true,
      watchField: 'input_type',
      matchValue: [inputTypes[3], inputTypes[4]],
      operator: '!==',
      currentName: 'blocks',
      childComponent: 'text',
    },
    condition: {
      repeater: true,
      watchField: 'information.price_type',
      matchValue: [priceTypes[3]],
      operator: '!==',
      currentName: 'blocks',
      childComponent: 'text',
    },
  },
  {
    name: 'information.max_characters',
    component: 'text',
    type: 'number',
    label: 'Max Characters',
    parentName: 'information',
    condition: {
      repeater: true,
      watchField: 'input_type',
      matchValue: [
        inputTypes[2],
        inputTypes[3],
        inputTypes[4],
        inputTypes[5],
        inputTypes[6],
        inputTypes[7],
      ],
      operator: '!==',
      currentName: 'blocks',
      childComponent: 'text',
    },
    condition: {
      repeater: true,
      watchField: 'information.price_type',
      matchValue: [priceTypes[3]],
      operator: '!==',
      currentName: 'blocks',
      childComponent: 'text',
    },
  },
  {
    name: 'information.free_characters',
    component: 'text',
    type: 'number',
    label: 'Free Characters',
    parentName: 'information',
    condition: {
      repeater: true,
      watchField: 'input_type',
      matchValue: [
        inputTypes[2],
        inputTypes[3],
        inputTypes[4],
        inputTypes[5],
        inputTypes[6],
        inputTypes[7],
      ],
      operator: '!==',
      currentName: 'blocks',
      childComponent: 'text',
    },
    condition: {
      repeater: true,
      watchField: 'information.price_type',
      matchValue: [priceTypes[3]],
      operator: '!==',
      currentName: 'blocks',
      childComponent: 'text',
    },
  },
];

const fields = [
  {
    group: 'Main Details',
    fields: [
      {
        name: 'title',
        component: 'text',
        placeholder: 'Enter title',
        label: 'Title',
        isRequired: true,
      },
      {
        name: 'status',
        component: 'select',
        options: status,
        labelKey: 'name',
        valueKey: 'id',
        placeholder: 'Select status',
        label: 'Status',
        isRequired: true,
      },
      {
        component: 'repeater',
        name: 'blocks',
        label: 'Blocks',
        singularName: 'block',
        rules: { required: false },
        columns: 1,
        fieldArray: sharedFieldArray,
      },
    ],
  },
];

const EditForm = () => {
  //const attributeSet = useWatch({ name: 'attribute_set' });
  // const { data: modes } = useSWR(`/view-modes`, useGet);

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} isFullWidth />
    </EditView>
  );
};

export default EditForm;
