const modelName = 'Orders';
const modelNameSingular = 'Order';
const endpoint = '/orders';

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: false,
  canAddNew: false,
  canSearch: true,
  endpoint,
  accessor: 'id',
};

export default setup;
