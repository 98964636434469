import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';

import setup from './setup';

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: 'Order Settings',
        fields: [
          {
            name: 'content.minimum_order',
            label: 'Minimum Order',
            component: 'text',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.minimum_order_orderwise_code',
            label: 'Orderwise Minimum Order Code',
            component: 'text',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.disabled_days',
            label: 'Days Delay for Delivery',
            component: 'text',
            type: 'number',
            isRequired: true,
            rules: { required: true },
            help: "This will be the number of days an order cannot be delivered for. For example if you are on monday and you chose '2', the product can only be requested to be delivered Wednesday at the earliest",
          },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
