import React, { useState, useEffect } from 'react';
import { useHttp } from '@builtbypixel/nucleus';
import { useParams } from 'react-router-dom';
import { Button, Flex, Alert, Text, Box } from '@builtbypixel/plasma';
import { useFormContext } from 'react-hook-form';
import AttributeDropdown from '../fields/AttributeDropdown';

const ReassignAttributes = ({}) => {
  const [attributes, setAttrib] = useState(null);
  const [inputAttribSets, setInputAttribSets] = useState(null);

  const [attrToAssign, setAttrToAssign] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [attributeDataFromFetch, setAttributeDataFromFetch] = useState(null);
  const [productId, setProductId] = useState(null);

  const { id } = useParams();
  const Http = useHttp();
  const { getValues } = useFormContext();

  const products = getValues('products');

  const getAttributeSets = () =>
    Http.get(`/attribute-sets`, {
      params: { search: inputAttribSets },
    }).then((res) => {
      setAttrib(res.data);
    });

  const reassignAttribute = (product_id) => {
    setSuccess(false);
    setError(false);

    let set_id = attrToAssign.id;

    Http.post(`/products/${product_id}/attribute-set/${set_id}`)
      .then((res) => {
        setSuccess(true);
      })
      .catch((err) => {
        setError(true);
      });
  };

  useEffect(() => {
    Http.get(`/attribute-sets/${id}`).then((data) => {
      setAttributeDataFromFetch(data);
    });
  }, [productId]);

  useEffect(() => {
    getAttributeSets();
  }, [inputAttribSets]);

  useEffect(() => {
    productId && reassignAttribute(productId);
  }, [attributeDataFromFetch]);

  return (
    <Flex w='100%' direction='column'>
      {success && (
        <Alert
          ml='1em'
          variant='solid'
          status='success'
          title='Success'
        ></Alert>
      )}
      {error && (
        <Alert ml='1em' variant='solid' status='error' title='Failed'></Alert>
      )}
      {products?.length > 0 ? (
        products.map((product) => {
          return (
            <Flex align='center' justify='space-evenly' flex='1 1 0px'>
              <Text>{product.name}</Text>

              <Box w='40%' m='0.7em'>
                <AttributeDropdown
                  attributes={attributes}
                  setInputAttribSets={setInputAttribSets}
                  setAttrToAssign={setAttrToAssign}
                />
              </Box>
              <Button
                onClick={() => {
                  setProductId(product.id);
                }}
              >
                Reassign
              </Button>
            </Flex>
          );
        })
      ) : (
        <Text>There Are No Products Assigned To This Attribute Set</Text>
      )}
    </Flex>
  );
};

export default ReassignAttributes;
