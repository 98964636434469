import React from 'react';
import { ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@builtbypixel/plasma';
import { Link } from 'react-router-dom';
import setup from './setup';

// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const List = () => {
  const columns = [
    {
      Header: 'First Name',
      accessor: 'first_name',
      Cell: ({ row }) => (
        <Link to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant="link" variantColor="secondary" rounded="md">
            {row.original.first_name}
          </Button>
        </Link>
      ),
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
      Cell: ({ row }) => (
        <Link to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant="link" variantColor="secondary" rounded="md">
            {row.original.last_name}
          </Button>
        </Link>
      ),
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ row }) => (
        <>{row.original.email ? row.original.email : 'Not provided'}</>
      ),
    },
    {
        Header: 'Requested Brochure',
        accessor: 'brochure.title',
        Cell: ({ row }) => (
          <>{row.original.brochure?.title ? row.original.brochure.title : 'Not provided'}</>
        ),
      },
    {
      Header: 'Postcode',
      accessor: 'postcode',
    },
    {
      Header: 'Phone Number',
      accessor: 'phone_number',
      Cell: ({ row }) => (
        <>{row.original.phone_number ? row.original.phone_number : 'Not provided'}</>
      ),
    },
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
