import React, { useEffect } from 'react'
import { Flex } from '@builtbypixel/plasma'
import { Helmet } from 'react-helmet'
import { useSetRecoilState } from 'recoil'
import { menuAtom } from '../../state/global'
import { use100vh } from 'react-div-100vh'

const Page = (props) => {
   const { children, title } = props
   const height = use100vh()
   const setMenu = useSetRecoilState(menuAtom)

   useEffect(() => {
      setMenu(false)
   }, [setMenu])

   return (
      <React.Fragment>
         <Helmet>
            <title>{title}</title>
         </Helmet>
         <Flex
            h={height}
            w='100%'
            direction='column'
            maxHeight={height}
            overflowY='auto'
            overflowX='hidden'
            p='0px'
         >
            {children}
         </Flex>
      </React.Fragment>
   )
}

export default Page
