import {
  Box,
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalButton,
  ModalBody,
  ModalFooter,
  Text,
  Card,
  Divider,
} from '@builtbypixel/plasma';
import React from 'react';
import ProductTable from './Modal';
import { useFormContext, useWatch } from 'react-hook-form';

const TableModal = ({
  productTypes,
  columns,
  attributes,
  onRowSelect,
  onRowRemove,
  pushSingle,
  selected,
  customSetProducts,
  customProducts,
  selectAll,
}) => {
  return (
    <Modal size='xl'>
      <ModalButton>
        <Button variantColor='secondary' variant='outline' size='sm'>
          Select Products
        </Button>
      </ModalButton>
      <ModalContent>
        <ModalHeader>
          Select Products -{' '}
          <Text ml='10px' fontWeight='normal'>
            {selected?.length} products selected
          </Text>
        </ModalHeader>
        <ModalBody>
          <ProductTable
            selectAll={selectAll}
            selected={selected}
            columns={columns}
            customProducts={customProducts}
            productTypes={productTypes}
            attributes={attributes}
            onRowSelect={onRowSelect}
            customSetProducts={customSetProducts}
            pushSingle={pushSingle}
            onRowRemove={onRowRemove}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ProductsTable = ({
  productTypes,
  columns,
  attributes,
  onRowSelect,
  onRowRemove,
  pushSingle,
  selected,
  customSetProducts,
  customProducts,
}) => {
  const selectAll = useWatch({ name: 'select_all_products' });

  return (
    <Box maxWidth='100%' w='100%' pb='25px'>
      {selectAll ? (
        <Box>
          <Text fontWeight='bold'>Excluded Products</Text>
          <Divider mb='1em' />
          <TableModal
            selectAll={selectAll}
            productTypes={productTypes}
            columns={columns}
            attributes={attributes}
            onRowSelect={onRowSelect}
            onRowRemove={onRowRemove}
            pushSingle={pushSingle}
            selected={selected}
            customSetProducts={customSetProducts}
            customProducts={customProducts}
          />
        </Box>
      ) : (
        <TableModal
          productTypes={productTypes}
          columns={columns}
          attributes={attributes}
          onRowSelect={onRowSelect}
          onRowRemove={onRowRemove}
          pushSingle={pushSingle}
          selected={selected}
          customSetProducts={customSetProducts}
          customProducts={customProducts}
        />
      )}
    </Box>
  );
};

export default ProductsTable;
