import React from 'react';
import { ListWrapper } from '@builtbypixel/nucleus';
import { Button, Badge, Text } from '@builtbypixel/plasma';
import { Link } from 'react-router-dom';
import setup from './setup';

// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const getStatusColour = (id) => {
  if (id === 5) {
    return 'grey';
  }
  if (id === 4) {
    return 'success';
  }
  if (id === 3) {
    return 'error';
  }
  if (id === 2) {
    return 'error';
  }
  if (id === 1) {
    return 'warning';
  }
};

const List = () => {
  const columns = [
    {
      Header: 'Order Number',
      accessor: 'id',
      Cell: ({ row }) => (
        <Link
          to={`${setup.model}/${
            row.original.purchase_order ? 'edit' : 'view'
          }/${row.original[setup.accessor]}`}
        >
          <Button variantColor='secondary' variant='link'>
            {row.original.id}
          </Button>
        </Link>
      ),
    },
    {
      Header: 'Order Reference',
      accessor: 'id933',
      Cell: ({ row }) => {
        return <Text>{row.original.reference}</Text>;
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
    },

    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'PO Number',
      accessor: 'purchase_order',
      Cell: ({ row }) =>
        row.original.purchase_order ? (
          <Text>{row.original.purchase_order}</Text>
        ) : (
          'N/A'
        ),
    },
    {
      Header: 'Total',
      accessor: 'total',
      Cell: ({ row }) => {
        return <Text>£{row.original.price}</Text>;
      },
    },
    {
      Header: 'Order Status',
      accessor: 'order_status',
      Cell: ({ value }) => (
        <Badge variantColor={getStatusColour(value.id)}>{value.name}</Badge>
      ),
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
    },
  ];

  const filters = [
    {
      component: 'text',
      label: 'PO Number',
      name: 'purchase_order',
      placeholder: 'Enter a PO Number',
    },
    {
      component: 'select',
      options: [
        {
          id: 1,
          name: 'POA',
          value: 'poa',
        },
        {
          id: 2,
          name: 'Pallet',
          value: 'pallet',
        },
        {
          id: 3,
          name: 'Non Deliverable',
          value: 'non-deliverable',
        },
        {
          id: 4,
          name: 'Purchase Order',
          value: 'purchase-order',
        },
      ],
      labelKey: 'name',
      valueKey: 'id',
      name: 'status',
      placeholder: 'Status',
    },
  ];

  return (
    <ListWrapper
      setup={setup}
      columns={columns}
      extraMenu={extraMenu}
      filters={filters}
    />
  );
};

export default List;
