import {
    Box,
    Button,
    Modal,
    ModalHeader,
    ModalContent,
    ModalButton,
    ModalBody,
    ModalFooter,
    Text,
} from '@builtbypixel/plasma';
import React from 'react';
import ProductTable from './Modal';

const ProductsTable = ({
    productTypes,
    columns,
    attributes,
    onRowSelect,
    onRowRemove,
    pushSingle,
    selected,
    customSetProducts,
    customProducts,
    isProdVarient
}) => {
    return (
        <Box maxWidth='100%' w='100%' pb='25px'>
            <Modal size='xl'>
                <ModalButton>
                    <Button
                        variantColor='secondary'
                        variant='outline'
                        size='sm'
                    >
                        Select Products
                    </Button>
                </ModalButton>
                <ModalContent>
                    <ModalHeader>
                        Select Products -{' '}
                        <Text ml='10px' fontWeight='normal'>
                            {selected?.length} products selected
                        </Text>
                    </ModalHeader>
                    <ModalBody>
                        <ProductTable
                            selected={selected}
                            columns={columns}
                            customProducts={customProducts}
                            productTypes={productTypes}
                            attributes={attributes}
                            onRowSelect={onRowSelect}
                            customSetProducts={customSetProducts}
                            pushSingle={pushSingle}
                            onRowRemove={onRowRemove}
                            isProdVarient={isProdVarient}
                        />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default ProductsTable;
