import React, { useMemo } from 'react';
import { AutoFields, EditView, useGet } from '@builtbypixel/nucleus';
import useSWR from 'swr';
import setup from './setup';
import CommunicationPreferences from './components/CommunicationPreferences';
const EditForm = () => {
  const { data: countries } = useSWR('/countries', useGet);
  const { data: accountTypes } = useSWR('/account-types', useGet);

  const fields = useMemo(
    () => [
      {
        group: 'Main Details',
        fields: [
          {
            name: 'account_type',
            component: 'select',
            options: accountTypes?.data,
            labelKey: 'name',
            label: 'Account Type',
            valueKey: 'id',
            placeholder: 'Select customer account type',
            rules: { required: true },
            isRequired: true,
          },
          {
            name: 'company_name',
            component: 'text',
            placeholder: 'Company Name',
            label: 'Company Name',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'first_name',
            component: 'text',
            placeholder: 'First Name',
            label: 'First Name',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'last_name',
            component: 'text',
            placeholder: 'Last Name',
            label: 'Last Name',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'mobile_numbers',
            component: 'repeater',
            label: 'Phone Numbers',
            singleName: 'Phone Number',
            fieldArray: [
              {
                name: 'mobile',
                component: 'text',
                placeholder: 'Phone Number',
                label: 'Phone Number',
              },
              {
                name: 'default',
                component: 'switch',
                placeholder: 'Default',
                label: 'Default',
              },
            ],
          },
          {
            name: 'date_of_birth',
            component: 'date',
            placeholder: 'Date Of Birth',
            label: 'Date Of Birth',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'email_addresses',
            component: 'repeater',
            label: 'Email Addresses',
            singleName: 'Email Address',
            defaultValue: [
              {
                email: '',
              },
            ],
            fieldArray: [
              {
                name: 'email',
                component: 'text',
                placeholder: 'Email',
                label: 'Email',
                rules: { required: true },
              },
              {
                name: 'default',
                component: 'switch',
                placeholder: 'Default',
                label: 'Default',
              },
            ],
          },
          {
            name: 'email_verified',
            component: 'switch',
            placeholder: 'Email Verified',
            label: 'Email Verified',
          },
          {
            name: 'addresses',
            component: 'repeater',
            label: 'Addresses',
            singleName: 'Address',
            fieldArray: [
              {
                name: 'address_line_one',
                component: 'text',
                placeholder: 'Address Line 1',
                label: 'Address Line 1',
                rules: { required: true },
              },
              {
                name: 'address_line_two',
                component: 'text',
                placeholder: 'Address Line 2',
                label: 'Address Line 2',
              },
              {
                name: 'address_line_three',
                component: 'text',
                placeholder: 'Address Line 3',
                label: 'Address Line 3',
              },
              {
                name: 'county',
                component: 'text',
                placeholder: 'County',
                label: 'County',
              },
              {
                name: 'postcode',
                component: 'text',
                placeholder: 'Postcode',
                label: 'Postcode',
              },
              {
                name: 'city',
                component: 'text',
                placeholder: 'City',
                label: 'City',
              },
              {
                name: 'country',
                component: 'select',
                labelKey: 'name',
                valueKey: 'id',
                options: countries?.data,
                placeholder: 'Country',
                label: 'Country',
              },
              {
                name: 'default',
                component: 'switch',
                placeholder: 'Default',
                label: 'Default',
              },
              {
                name: 'default_billing',
                component: 'switch',
                placeholder: 'Default Billing',
                label: 'Default Billing',
              },
            ],
          },
          {
            name: 'orderwise_customer_id',
            component: 'text',
            placeholder: 'Orderwise Customer',
            label: 'Orderwise Customer',
          },
        ],
      },
    ],
    [countries]
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
      <CommunicationPreferences />
    </EditView>
  );
};

export default EditForm;
