import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button, Text, Flex, Box } from '@builtbypixel/plasma';
import { Link } from 'react-router-dom';
import { IconButton, Switch } from '@builtbypixel/plasma';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';

// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const List = () => {
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Banner Text',
      accessor: 'name',
      Cell: ({ row }) => (
        <Link to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant='link' variantColor='secondary' rounded='md'>
            {row.original.name}
          </Button>
        </Link>
      ),
    },
    {
      Header: 'Colour',
      accessor: 'colour',
      Cell: ({ row }) => (
        <Flex>
          <Box w='20px' h='20px' bg={row.original.colour}></Box>
          <Text ml='1em'>{row.original.colour}</Text>
        </Flex>
      ),
    },
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
