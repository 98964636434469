import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { IconButton, Switch } from '@builtbypixel/plasma';
import { FiEdit } from 'react-icons/fi';
import setup from './setup';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Value',
            accessor: 'value',
        },
        {
            Header: () => null,
            id: 'edit',
            Cell: ({ row }) => (
                <LinkButton
                    to={`${setup.model}/edit/${row.original[setup.accessor]}`}
                >
                    <IconButton
                        variant='ghost'
                        variantColor='primary'
                        rounded='md'
                    >
                        <FiEdit fontSize={18} />
                    </IconButton>
                </LinkButton>
            ),
        },
    ];

    return (
        <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />
    );
};

export default List;
