import React from 'react'
import { NativeSelect } from '@builtbypixel/plasma'

const NativeSelectField = (props) => {
   const {
      setActive,
      onChange,
      onBlur,
      value,
      labelKey,
      valueKey,
      options,
      ...rest
   } = props
   return (
      <NativeSelect
         onChange={(e) => onChange(e.target.value)}
         isFullWidth
         onBlur={() => {
            onBlur()
            setActive(false)
         }}
         onFocus={() => setActive(true)}
         value={value}
         {...rest}
      >
         {options &&
            options.map((op, i) => (
               <option value={valueKey ? op[valueKey] : op} key={`option-${i}`}>
                  {labelKey ? op[labelKey] : op}
               </option>
            ))}
      </NativeSelect>
   )
}

export default NativeSelectField
