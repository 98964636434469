import React, { useMemo } from 'react';

import { EditView, AutoFields } from '@builtbypixel/nucleus';
import setup from '../setup';

const EditForm = () => {
    const fields = useMemo(
        () => [
            {
                group: 'About Page',
                fields: [
                    {
                        name: 'content.title',
                        component: 'text',
                        placeholder: 'Heading',
                        label: 'Heading',
                        rules: { required: true },
                    },
                    {
                        name: 'content.image',
                        component: 'file',
                        max: 1,
                        label: 'Image',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'content.page_content',
                        component: 'richtext',
                        placeholder: 'Page Content',
                        label: 'Page Content',
                        rules: { required: true },
                    },
                ],
            },
        ],
        []
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
