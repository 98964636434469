import React from 'react';
import { SketchPicker } from 'react-color';
import {
  Flex,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Text,
} from '@builtbypixel/plasma';

const ColourPicker = (props) => {
  const { setActive, onChange, defaultValue, value } = props;

  const [colour, setColour] = React.useState(value ? value : '#000000');

  React.useEffect(() => {
    if (value) {
      setColour(value);
    }
  }, [value]);

  React.useEffect(() => {
    if (defaultValue) {
      setColour(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Flex align='center'>
      <Popover>
        <PopoverTrigger>
          <Box w='100px' h='40px' rounded='lg' bg={colour}></Box>
        </PopoverTrigger>
        <PopoverContent>
          <Flex align='center' justify='center' p='10px'>
            <SketchPicker
              color={colour}
              onChange={(color) => {
                setColour(color.hex);
                onChange(color.hex);
              }}
            />
          </Flex>
        </PopoverContent>
      </Popover>
      <Text ml='20px'>{colour}</Text>
    </Flex>
  );
};

export default ColourPicker;
