import Home from './home';
import POA from './poa';
import Orders from './orders';
import Products from './products';
import Blogs from './blogs';
import Customers from './customers';
import ContactUs from './contactUs';
import ProductsMostViewed from './productsMostViewed';
import ProductsBestSellers from './productsBestSellers';
import Brochures from './brochures';

import AverageBasketProducts from './averageBasketProducts';
import DiscountCodes from './discountCodes';
import CustomerHearUs from './customerHearUs';
import CustomerPreferences from './customerPreferences';

export const HomePage = (props) => <Home {...props} />;
export const POAPage = (props) => <POA {...props} />;
export const OrdersPage = (props) => <Orders {...props} />;
export const POAPagePage = (props) => <POA {...props} />;
export const ProductsPage = (props) => <Products {...props} />;
export const ProductsMostViewedPage = (props) => (
  <ProductsMostViewed {...props} />
);
export const ProductsBestSellersPage = (props) => (
  <ProductsBestSellers {...props} />
);
export const DiscountCodesPage = (props) => <DiscountCodes {...props} />;
export const BlogsPage = (props) => <Blogs {...props} />;
export const BrochuresPage = (props) => <Brochures {...props} />;
export const AverageBasketProductsPage = (props) => (
  <AverageBasketProducts {...props} />
);
export const ContactUsPage = (props) => <ContactUs {...props} />;
export const CustomersPage = (props) => <Customers {...props} />;
export const CustomersHearUs = (props) => <CustomerHearUs {...props} />;
export const CustomersPreferences = (props) => (
  <CustomerPreferences {...props} />
);

export const routeSelectPages = ['home', 'products'];
