import React, { useMemo } from 'react';

import { EditView, AutoFields } from '@builtbypixel/nucleus';
import setup from '../setup';

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: 'Delivery Information',
        fields: [
          {
            name: 'content.image',
            component: 'file',
            placeholder: 'Image',
            label: 'Image',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.page_content',
            component: 'richtext',
            placeholder: 'Page Content',
            label: 'Page Content',
            isRequired: true,
            rules: { required: true },
          },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
