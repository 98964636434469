import React, { useState, useEffect } from 'react';
import {
  Button,
  Flex,
  Text,
  Box,
  PopConfirm,
  Alert,
} from '@builtbypixel/plasma';
import { useHttp, Select, Field } from '@builtbypixel/nucleus';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import { sharedFieldArray } from '../../../TemplateOptions';

const CustomOptionFields = () => {
  const [templates, setTemplates] = useState([]);
  const type = useWatch({ name: 'product_type' });
  const { setValue } = useFormContext();
  const Http = useHttp();

  const fetchOptionTemplates = () => {
    Http.get('/product-templates').then((res) => {
      setTemplates(res.data.data);
    });
  };

  useEffect(() => {
    fetchOptionTemplates();
  }, []);

  const selectTemplate = (template, index) => {
    setValue(
      `template_options.${index}.name`,
      template ? template.title : null
    );
    setValue(
      `template_options.${index}.blocks`,
      template ? (template.blocks ? template.blocks : []) : []
    );
    setValue(`parent_template_option`, template);
  };

  const { fields, append, remove } = useFieldArray({
    name: 'template_options',
  });

  return (
    <Flex direction='column'>
      {(type && type.id === 4) || (type && type.id === 2) ? (
        <Alert
          status='warning'
          title='You cannot set customisable options on the parent product of
                a bundled or grouped product.'
        >
          Customisable options should be set on the individual bundled product
          (if using bundled product type).
          <br />
          <br /> Grouped products cannot be customsied by customers.
        </Alert>
      ) : (
        <>
          <Text>
            Custom options allow customers to personalise the product, such as
            engraving.{' '}
          </Text>
          <Text mb='10px'>
            Select a custom option group and click add to activate the group.
          </Text>

          {fields &&
            fields.map((field, i) => (
              <Flex
                key={field.id}
                direction='column'
                justify='flex-start'
                align='flex-start'
                mb='25px'
                bg='grayBg'
                rounded='lg'
                p='10px'
              >
                <Box mb='10px'>
                  <Field
                    component='select'
                    name='parent_template_option'
                    options={templates}
                    labelKey='title'
                    maxWidth='200px'
                    valueKey='id'
                    placeholder='Select from template'
                    onChange={(e) => selectTemplate(e, i)}
                  />
                </Box>
                <Flex align='center'>
                  <Field
                    component='text'
                    name={`template_options[${i}].name`}
                    defaultValue={field.name}
                    label='Group Name'
                    placeholder="Eg.. 'Engraving'"
                  />
                  <PopConfirm
                    onConfirm={() => remove(i)}
                    justifySelf='flex-end'
                    title='Are you sure you want to remove this group?'
                  >
                    <Button variantColor='error' ml='25px' size='sm'>
                      Remove group
                    </Button>
                  </PopConfirm>
                </Flex>
                <Field
                  component='repeater'
                  name={`template_options.${i}.blocks`}
                  defaultValue={field.blocks}
                  fieldArray={sharedFieldArray}
                  isReadOnly={true}
                />
              </Flex>
            ))}
          {fields?.length === 0 && (
            <Box>
              <Button onClick={() => append({})}>Add New Option Group</Button>
            </Box>
          )}
          <Flex
            direction='column'
            my='2em'
            border='1px dashed black'
            borderRadius='5px'
            p='1em'
            bg='lightYellow'
          >
            <Text color='black' textDecoration='underline' fontWeight='bold'>
              Note
            </Text>
            <Text color='black'>
              If you want to make changes to Customisable Options, edit the
              Template Option Directly.
            </Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default CustomOptionFields;
