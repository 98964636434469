import { atom } from 'recoil'

export const formAtom = atom({
   key: 'formState',
   default: {
      errors: null,
      loading: false,
      success: false
   }
})
