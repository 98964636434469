import React from 'react'
import { BiAtom, BiExit } from 'react-icons/bi'
import { FiUsers, FiRepeat, FiSun } from 'react-icons/fi'
import {
   Flex,
   Icon,
   IconButton,
   Tooltip,
   Stack,
   useColorMode,
   Modal,
   ModalButton,
   ModalHeader,
   ModalContent,
   Box,
   useTheme
} from '@builtbypixel/plasma'
import { useRecoilValue } from 'recoil'
import { useHistory } from 'react-router-dom'
import SiteSelector from './SiteSelector'
import { logout } from '../../helpers'
import { use100vh } from 'react-div-100vh'
import { globalAtom, menuAtom } from '../../state/global'

const version = '../../package.json'

const SideBar = () => {
   const globalState = useRecoilValue(globalAtom)
   const menuState = useRecoilValue(menuAtom)
   const { toggleColorMode, colorMode } = useColorMode()
   const history = useHistory()
   const height = use100vh()
   const theme = useTheme()

   return (
      <Flex
         h={height}
         w='60px'
         direction='column'
         justify='space-between'
         align='center'
         pt='15px'
         pb='5px'
         px='8px'
         bg='global.elementBg'
         zIndex={1002}
         position={{ xs: 'fixed', md: 'relative' }}
         left={0}
         top={0}
         transform={{
            xs: menuState ? 'translateX(0%)' : 'translateX(-100%)',
            md: 'none'
         }}
         transition='0.3s all ease-in-out'
         borderRight='1px'
         style={{ borderColor: theme.colors.global.borderColour }}
      >
         <Flex mb={20}>
            <Icon fontSize='26px' color='primary'>
               <BiAtom />
            </Icon>
         </Flex>

         <Stack spacing='10px' flex={1} justifyContent='flex-end'>
            {/* <IconButton rounded='full' size='lg' variant='ghost'>
               <Tooltip title='Content Manager' placement='right'>
                  <FiLayers fontSize={20} />
               </Tooltip>
            </IconButton> */}

            <IconButton
               rounded='full'
               size='lg'
               variant='ghost'
               onClick={() => history.push('/administrators')}
            >
               <Tooltip title='Admin Users' placement='right'>
                  <FiUsers fontSize={20} />
               </Tooltip>
            </IconButton>

            {globalState.config?.siteSwitcher.show && (
               <Modal>
                  <ModalButton>
                     <IconButton rounded='full' size='lg' variant='ghost'>
                        <Tooltip title='Site Switcher' placement='right'>
                           <FiRepeat fontSize={20} />{' '}
                        </Tooltip>
                     </IconButton>
                  </ModalButton>

                  <ModalContent>
                     <ModalHeader>Select a site</ModalHeader>
                     <SiteSelector
                        sites={globalState.config?.siteSwitcher.sites}
                     />
                  </ModalContent>
               </Modal>
            )}
            <IconButton
               rounded='full'
               size='lg'
               variant='ghost'
               onClick={() => toggleColorMode()}
               mt='auto'
            >
               <Tooltip
                  title={colorMode === 'light' ? 'Dark Mode' : 'Light Mode'}
                  placement='right'
               >
                  <FiSun fontSize={20} />{' '}
               </Tooltip>
            </IconButton>
            <IconButton
               rounded='full'
               size='lg'
               variant='ghost'
               onClick={() => logout()}
               mt='auto'
            >
               <Tooltip title='Logout' placement='right'>
                  <BiExit fontSize={20} />{' '}
               </Tooltip>
            </IconButton>
            <Box fontSize='12px' opacity={0.5} textAlign='center'>
               {version.version}
            </Box>
         </Stack>
      </Flex>
   )
}

export default SideBar
