export const inputTypes = [
  {
    id: 1,
    name: 'Text Field',
  },
  {
    id: 2,
    name: 'Text Area',
  },
  {
    id: 3,
    name: 'Date',
  },
  {
    id: 4,
    name: 'Dropdown',
  },
  {
    id: 5,
    name: 'Multi-Select Dropdown',
  },
  {
    id: 6,
    name: 'Checkbox',
  },
  {
    id: 8,
    name: 'Media Image',
  },
  {
    id: 10,
    name: 'Swatch',
  },
  {
    id: 11,
    name: 'Complex Engraving Popup',
  },
];
