import React from 'react';
import { Flex, Box, Text } from '@builtbypixel/plasma';
import PageContainer from '../Components/PageContainer';

const Blogs = ({ setCurrentPage, currentPage }) => {
  return (
    <PageContainer
      title='Blogs'
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    >
      Blogs PAGE
    </PageContainer>
  );
};

export default Blogs;
