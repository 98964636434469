import React from 'react'
import { Modal, ModalButton, ModalContent, Image } from '@builtbypixel/plasma'

const ImagePreview = ({ url, children }) => {
   return (
      <Modal>
         <ModalButton>{children}</ModalButton>
         <ModalContent>
            <Image src={url} w='100%' h='auto' flexShrink={0} />
         </ModalContent>
      </Modal>
   )
}

export default ImagePreview
