import React from 'react';
import { Flex, Text, Button, IconButton } from '@builtbypixel/plasma';
import { Image, Transformation } from 'cloudinary-react';
import { useFormContext, useWatch } from 'react-hook-form';
import { HiOutlineChevronUp, HiOutlineChevronDown } from 'react-icons/hi';

const SelectedProductsList = ({ item, name, index }) => {
  const { setValue } = useFormContext();
  const selected = useWatch({ name: name });

  const remove = (id) => {
    let _selected = [...selected];
    const index = _selected.findIndex((e) => e.id === id);
    _selected.splice(index, 1);

    _selected = _selected.map((item, index) => {
      return { ...item, position: index };
    });
    setValue(name, _selected);
  };

  const move = (old_index, new_index) => {
    let _selected = [...selected];
    if (new_index >= _selected.length) {
      var k = new_index - _selected.length + 1;
      while (k--) {
        _selected.push(undefined);
      }
    }
    _selected.splice(new_index, 0, _selected.splice(old_index, 1)[0]);

    _selected = _selected.map((item, index) => {
      let obj = { ...item, position: index };
      return obj;
    });

    setValue(name, _selected);
  };

  return (
    <Flex
      align='center'
      rounded='lg'
      p='5px'
      border='1px'
      borderColor='borderColour'
      boxShadow='md'
      bg='global.elementBg'
    >
      {item.images.length !== 0 ? (
        <Image publicId={item.images[0].public_id} secure='true'>
          <Transformation
            width='40'
            height='40'
            crop='thumb'
            fetchFormat='auto'
          />
        </Image>
      ) : (
        <img
          src='https://via.placeholder.com/300.png/e9e9e9/000?text="No Image"'
          width={40}
          alt='No pic'
        />
      )}

      <Text opacity={0.5} mr='15px'>
        {item.id}
      </Text>
      <Flex direction='column'>
        <Text
          as='a'
          href={`${process.env.REACT_APP_FRONTEND_URL}/products/${item.slug}`}
          target='_blank'
          fontWeight='semibold'
          color='secondary'
        >
          {item.name}
        </Text>
        <Flex align='center' pr='15px' w='150px'>
          <Text fontSize='0.8em' mr='5px' opacity={0.5}>
            <pre>{item.sku}</pre>{' '}
          </Text>
        </Flex>
      </Flex>
      <Button
        as='a'
        href={`/products/edit/${item.id}`}
        target='_blank'
        variant='outline'
        ml='auto'
        mr='10px'
        size='sm'
      >
        View/Edit
      </Button>
      <Button
        mr='10px'
        variant='outline'
        variantColor='error'
        size='sm'
        onClick={() => remove(item.id)}
      >
        Remove
      </Button>
      <Flex direction='column'>
        <IconButton
          icon={<HiOutlineChevronUp fontSize='20px' />}
          variant='link'
          onClick={() => move(index, index - 1)}
          isDisabled={index === 0}
          mb='5px'
        ></IconButton>
        <IconButton
          icon={<HiOutlineChevronDown fontSize='20px' />}
          variant='link'
          onClick={() => move(index, index + 1)}
          isDisabled={selected.length === index + 1}
        ></IconButton>
      </Flex>
    </Flex>
  );
};

export default SelectedProductsList;
