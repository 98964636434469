import React, { forwardRef, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { Input, InputGroup, InputLeftElement } from '@builtbypixel/plasma';
import { FiCalendar } from 'react-icons/fi';

const ReportsDatePicker = ({ placeholder, date, defaultDate, onChange }) => {
  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    if (defaultDate) {
      onChange(defaultDate);
      setSelectedDate(new Date(defaultDate));
    }
  }, []);

  useEffect(() => {
    if (!selectedDate) onChange('');
  }, [selectedDate]);

  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={(date) => {
        setSelectedDate(date);
        onChange(moment(date).format('YYYY-MM-DD'));
      }}
      isClearable
      placeholderText={placeholder}
      dateFormat='dd/MM/yyyy'
    />
  );
};

export default ReportsDatePicker;
