import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { Flex, Box, Text, Card } from '@builtbypixel/plasma';
import { AutoFields, EditView, useGet } from '@builtbypixel/nucleus';
import useSWR from 'swr';
import setup from './setup';
import {
  HomePage,
  POAPage,
  CustomersPage,
  OrdersPage,
  ProductsPage,
  BlogsPage,
  BrochuresPage,
  ContactUsPage,
  ProductsMostViewedPage,
  ProductsBestSellersPage,
  DiscountCodesPage,
  AverageBasketProductsPage,
} from './Page';
import { useLocation } from 'react-router-dom';
import CustomerPreferences from './Page/customerPreferences';
import CustomerHearUs from './Page/customerHearUs';
import ReportsContext from './context';
import { useRecoilState } from 'recoil';
import { tableAtom } from '../../Nucleus/state/global';

const Reports = () => {
  let { search } = useLocation();
  let location = useLocation();

  const [currentPage, setCurrentPage] = useState('home');
  const [tableState, setTableState] = useRecoilState(tableAtom);

  const onChangePageSize = useCallback(
    (size) => {
      setTableState((old) => ({
        ...old,
        params: { ...old.params, pagination: size },
      }));
    },
    [setTableState]
  );

  const onChangePage = useCallback(
    (page) => {
      setTableState((old) => ({
        ...old,
        params: { ...old.params, page },
      }));
    },
    [setTableState]
  );

  useEffect(() => {
    setCurrentPage(location.search.slice(1));
  }, [search]);

  const renderPage = () => {
    switch (currentPage) {
      case 'home':
        return (
          <HomePage currentPage={currentPage} setCurrentPage={setCurrentPage} />
        );
        break;
      case 'poa':
        return (
          <POAPage currentPage={currentPage} setCurrentPage={setCurrentPage} />
        );
        break;
      case 'customers':
        return (
          <CustomersPage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
        break;
      case 'orders':
        return (
          <OrdersPage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
        break;
      case 'products':
        return (
          <ProductsPage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
        break;
      case 'products/most-viewed':
        return (
          <ProductsMostViewedPage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
        break;
      case 'products/best-sellers':
        return (
          <ProductsBestSellersPage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
        break;
      case 'blogs':
        return (
          <BlogsPage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
        break;
      case 'brochures':
        return (
          <BrochuresPage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
        break;
      case 'customers-preferences':
        return (
          <CustomerPreferences
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
        break;
      case 'average-basket-products':
        return (
          <AverageBasketProductsPage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
        break;
      case 'discount-codes':
        return (
          <DiscountCodesPage
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
        break;
      case 'customers-hear-us':
        return (
          <CustomerHearUs
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
        break;
      // case 'contact-us':
      //   return (
      //     <ContactUsPage
      //       currentPage={currentPage}
      //       setCurrentPage={setCurrentPage}
      //     />
      //   );
      //   break;

      default:
        return (
          <HomePage currentPage={currentPage} setCurrentPage={setCurrentPage} />
        );
    }
  };
  return (
    <ReportsContext.Provider value={{ onChangePageSize, onChangePage }}>
      {renderPage()}
    </ReportsContext.Provider>
  );
};

export default Reports;
