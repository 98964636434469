import {
  Box,
  Grid,
  Flex,
  Text,
  Button,
  Image,
  Alert,
} from '@builtbypixel/plasma';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ProductsTable from '../components/ProductsTable';
import SelectedProductsList from '../components/SelectedProductsList';

const BundleItems = ({ productTypes, attributes }) => {
  const [orderedArr, setOrderedArr] = useState([]);
  const name = 'bundle_items';
  const { setValue, getValues } = useFormContext();
  const selected = useWatch({ name: name });
  const type = useWatch({ name: 'product_type' });

  const onRowSelect = (rows) => {
    setValue(name, rows);
  };

  useEffect(() => {
    let arr = [];
    if (selected) {
      selected.forEach((item, index) => {
        if (item.position === 0) arr.unshift(item);
        else if (item.position) arr.splice(item.position, 0, item);
        else arr.push(item);
      });
      setOrderedArr(arr);
    }
  }, [selected]);

  return (type && type.id === 4) || (type && type.id === 2) ? (
    <Box>
      {/* // If the product type is a bundled product, these are items within the bundle.  If it's a grouped product, these bundled items can be purchased separately   */}
      {type.id === 4 && (
        <Text mb='25px'>
          Bundle items are individual products bundled into this product, such
          as Gold, Silver and Bronze medals.
          <br /> Customers can customise each one depending on their custom
          options.
        </Text>
      )}
      {type.id === 2 && (
        <Text mb='25px'>
          Select the products to group together in this product. Customers can
          purchase them separately.
        </Text>
      )}
      <ProductsTable
        selected={selected}
        productTypes={productTypes}
        attributes={attributes}
        onRowSelect={onRowSelect}
      />
      <Grid gap='10px' mb='20px' mt='20px'>
        {orderedArr &&
          orderedArr?.map((item, i) => {
            return (
              <SelectedProductsList
                item={item}
                name={name}
                key={`item-${i}`}
                index={i}
              />
            );
          })}
      </Grid>
    </Box>
  ) : (
    <Box>
      <Alert mb='25px' status='warning'>
        You cannot add bundled products to this product type. Bundled items are
        used if a product consists of multiple individual products such as a
        set.
      </Alert>
    </Box>
  );
};

export default BundleItems;
