import React, { useEffect, createContext, useRef, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from '../routes'
import useSWR from 'swr'
import { Flex, Box } from '@builtbypixel/plasma'
import {
   saveSiteToStorage,
   checkAuthTokenExpiry,
   hasAuthToken
} from '../helpers'
import SideBar from '../components/Sidebar'
import Menu from '../components/Menu'
import MobileHeader from '../components/MobileHeader'
import { useGet } from '../hooks'
import { globalAtom } from '../state/global'
import { useRecoilState, useSetRecoilState } from 'recoil'
import sha256 from 'js-sha256'
import { mediaAtom as _mediaAtom } from '../state/media'

export const CloudinaryUploadContext = createContext()

const testUser = {
   first_name: 'Test',
   last_name: 'User',
   email: 'test@test.com',
   profile_image: {
      url:
         'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'
   },
   permissions: ['ALL']
}

const Kernel = (props) => {
   const { config, routes, links } = props

   const cloudinaryContainer = useRef()
   const setState = useSetRecoilState(globalAtom)
   const bypassAuth = process.env.REACT_APP_BYPASS_AUTH

   const authPrefix = process.env.REACT_APP_AUTH_PREFIX

   useEffect(() => {
      // Set the site from config
      saveSiteToStorage(config.siteSwitcher.sites)
   }, [config.siteSwitcher.sites])

   checkAuthTokenExpiry()

   const { data } = useSWR(
         authPrefix
            ? `${authPrefix}/current`
            : 'auth/current',
      useGet
   );

   /* eslint-disable */
   useEffect(() => {
      if (data) {
         setState((old) => ({
            ...old,
            config,
            routes,
            links,
            user: data
         }))
      } else {
         if (bypassAuth) {
            setState((old) => ({
               ...old,
               config,
               routes,
               links,
               user: testUser
            }))
         }
      }
   }, [config, routes, links, data])

   const [mediaAtom, setMediaAtom] = useRecoilState(_mediaAtom)

   ///// CLOUDINARY

   const [ml, setML] = useState(null)

   const show = (name) => {
      localStorage.setItem('activeMediaField', name)
      ml.show()
   }

   useEffect(() => {
      if (process.env.REACT_APP_UPLOAD_METHOD === 'cloudinary') {
         // Initiate Cloudinary

         const timestamp = ((Date.now() / 1000) | 0).toString()
         const auth = `cloud_name=${process.env.REACT_APP_CLOUDINARY_CLOUDNAME}&timestamp=${timestamp}&username=${process.env.REACT_APP_CLOUDINARY_USERNAME}${process.env.REACT_APP_CLOUDINARY_SECRET}`
         const signature = sha256(auth)

         const handleSelected = (files) => {
            const active = localStorage.getItem('activeMediaField')

            let fields = [...mediaAtom]

            const index = fields.findIndex((f) => f.name === active)

            if (index !== -1) {
               fields[index] = {
                  ...fields[index],
                  files: files.assets
               }

               setMediaAtom(fields)
            } else {
               fields = [...fields, { name: active, files: files.assets }]
               setMediaAtom(fields)
            }
         }

         if (!ml) {
            console.log('CLOUDINARY CALLED')
            setML(
               window.cloudinary.createMediaLibrary(
                  {
                     cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
                     api_key: process.env.REACT_APP_CLOUDINARY_KEY,
                     username: process.env.REACT_APP_CLOUDINARY_USERNAME,
                     timestamp: timestamp,
                     signature: signature
                  },
                  {
                     insertHandler: function (data) {
                        handleSelected(data)
                     }
                  }
               )
            )
         }
      }
   }, [])

   return (
      <CloudinaryUploadContext.Provider
         value={{ mediaLibrary: ml ? ml : null, show }}
      >
         <Router>
            {hasAuthToken() && <MobileHeader />}
            <Flex w='100vw' h='100vh' maxHeight='100vh' overflow='hidden'>
               {hasAuthToken() && (
                  <React.Fragment>
                     <SideBar />
                     <Menu links={links} user={data ? data?.data : testUser} />
                  </React.Fragment>
               )}
               <Flex
                  direction='column'
                  w='100%'
                  maxHeight='100vh'
                  overflowX='hidden'
                  borderLeft='1px'
                  borderColor='global.borderColour'
               >
                  <Routes clientRoutes={routes} />
               </Flex>
            </Flex>
            <Box ref={cloudinaryContainer} />
         </Router>
      </CloudinaryUploadContext.Provider>
   )
}

export default Kernel
