import React from 'react';
import { ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@builtbypixel/plasma';
import { Link } from 'react-router-dom';
import setup from './setup';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'Name',
            accessor: 'title',
            Cell: ({ row }) => (
                <Link
                    to={`${setup.model}/edit/${row.original[setup.accessor]}`}
                >
                    <Button
                        variant='link'
                        variantColor='secondary'
                        rounded='md'
                    >
                        {row.original.title}
                    </Button>
                </Link>
            ),
        },
        // {
        //    Header: 'Image',
        //    accessor: 'content.image.url',
        //    Cell: ({ row }) => (
        //       <Image
        //          src={row.original.content.image.url}
        //          isRounded
        //          size='50px'
        //          objectFit='cover'
        //       />
        //    )
        // },
    ];

    return (
        <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />
    );
};

export default List;
