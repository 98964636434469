import React from 'react'

import { Box, useColorMode, useTheme } from '@builtbypixel/plasma'
import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/theme-tomorrow'
import 'ace-builds/src-noconflict/theme-twilight'

const Html = (props) => {
   const {
      setActive,
      onChange,
      onBlur,
      mode,
      placeholder,
      value,
      ...rest
   } = props

   const { colorMode } = useColorMode()
   const theme = useTheme()

   return (
      <Box
         {...rest}
         w='100%'
         overflow='hidden'
         rounded='inputBorderRadiusMd'
         border='1px'
         borderColor={theme.colors.inputs.borderColour}
      >
         <AceEditor
            mode={mode || 'html'}
            placeholder={placeholder}
            theme={colorMode === 'light' ? 'tomorrow' : 'twilight'}
            onChange={onChange}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            value={value}
            height='200px'
            width='100%'
            setOptions={{
               enableBasicAutocompletion: true,
               enableLiveAutocompletion: true,
               enableSnippets: false,
               showLineNumbers: true,
               debounceChangePeriod: 1000,
               tabSize: 2
            }}
            editorProps={{
               $blockScrolling: true
            }}
         />
      </Box>
   )
}

export default Html
