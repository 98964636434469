import React, { useState } from 'react';
import { Select } from '@builtbypixel/plasma';

const AttributeDropdown = ({
  attributes,
  setInputAttribSets,
  setAttrToAssign,
  attrToAssign,
  loading,
}) => {
  const [value, setValue] = useState(null);
  return (
    <Select
      placeholder='Replace Atribute Set'
      label='Edit Attribute Set'
      labelKey='title'
      valueKey='id'
      options={attributes?.data}
      onInputChange={(val) => {
        setInputAttribSets(val);
      }}
      onChange={(val) => {
        console.log(val, 'val att ');
        setAttrToAssign(val);
        setValue(val);
      }}
      isLoading={loading}
      value={value}
    />
  );
};

export default AttributeDropdown;
