import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';

import setup from './setup';

const EditForm = () => {
    const fields = useMemo(
        () => [
            {
                group: '',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        placeholder: 'Title',
                        label: 'Title',
                        isRequired: true,
                        rules: { required: true },
                    },
                ],
            },
        ],
        []
    );
    return (
        <EditView setup={setup} isFullWidth>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
