import React, { useMemo } from 'react';

import { EditView, AutoFields } from '@builtbypixel/nucleus';
import setup from '../setup';

const EditForm = () => {
    const fields = useMemo(
        () => [
            {
                group: '',
                fields: [
                    {
                        name: 'content.faq_category',
                        keyName: 'category',
                        component: 'repeater',
                        label: 'FAQ Category',
                        rules: { required: true },
                        layout: 'vertical',
                        fieldArray: [
                            {
                                name: 'name',
                                component: 'text',
                                placeholder: 'FAQ category name',
                                label: 'FAQ category name',
                                isRequired: true,
                                rules: { required: true },
                            },
                            {
                                name: 'qanda',
                                keyName: 'qanda',
                                component: 'repeater',
                                label: 'Question and Answers',
                                rules: { required: true },
                                layout: 'vertical',
                                fieldArray: [
                                    {
                                        name: 'question',
                                        component: 'text',
                                        placeholder: 'FAQ Question',
                                        label: 'FAQ Question',
                                        isRequired: true,
                                        rules: { required: true },
                                    },
                                    {
                                        name: 'answer',
                                        component: 'richtext',
                                        placeholder: 'FAQ Answer',
                                        label: 'FAQ Answer',
                                        isRequired: true,
                                        rules: { required: true },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
        []
    );
    return (
        <EditView setup={setup} isFullWidth>
            <AutoFields fields={fields} isFullWidth />
        </EditView>
    );
};

export default EditForm;
