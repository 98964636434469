import React from 'react'
import { Flex, Text } from '@builtbypixel/plasma'

const NotFound = () => {
   return (
      <Flex w='100%' h='100vh' align='center' justify='center'>
         <Text fontSize='1.5em'>Page Not Found</Text>
      </Flex>
   )
}

export default NotFound
