import {
  Flex,
  Box,
  Grid,
  Text,
  Image,
  Button,
  PopConfirm,
} from "@builtbypixel/plasma";
import { Field, useGet, useHttp } from "@builtbypixel/nucleus";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import ProductsTable from "../components/ProductsTable";
import { Controller, useFieldArray } from "react-hook-form";
import useSWR from "swr";

const AssociatedProducts = () => {
  const { fields, append, remove } = useFieldArray({
    name: `associated_products`,
  });

  return (
    <Flex direction="column">
      <Text mb="25px">
        Associated products are products which can be linked to this product.
        <br /> For example - if the product is a medal, a customer can associate
        or 'link' a ribbon to it.
        <br />
        <br />
        You can attach indivdual products or allow customers to select from an
        entire category.
      </Text>

      <Box>
        {fields.length !== 0 &&
          fields.map((field, i) => {
            return <Item field={field} index={i} remove={remove} />;
          })}
        <Button onClick={() => append({})}>Add New Group</Button>
      </Box>
    </Flex>
  );
};

const Item = ({ field, index, remove }) => {
  const { setValue, watch } = useFormContext();
  const [subCategories, setSubCategories] = useState(null);
  const [input, setInput] = useState(null);

  const type = watch(`associated_products.${index}.type`);
  // const { data: categories } = useSWR(`/categories`, useGet);
  const Http = useHttp();

  const getSubCategories = () =>
    Http.get(`/sub-categories`, {
      params: { search: input },
    }).then((res) => {
      setSubCategories(res.data);
    });

  useEffect(() => {
    getSubCategories();
  }, [input]);

  useEffect(() => {
    if (type && type.id === 1) {
      setValue(`associated_products.${index}.sub_categories`, null);
    }
    if (type && type.id === 2) {
      setValue(`associated_products.${index}.products`, []);
    }
  }, [type]);
  return (
    <Box bg="grayBg" mb="20px" rounded="lg" p="10px" key={field.id}>
      <Field
        label="Group Title"
        name={`associated_products.${index}.title`}
        component="text"
        defaultValue={field.title}
        placeholder="Enter a group title, eg Ribbons..."
      />
      <Field
        label="Type"
        help="To select specific products, choose 'Products' - to select one or multiple sub-categories choose 'Sub-Categories'"
        name={`associated_products.${index}.type`}
        component="select"
        defaultValue={field.type}
        options={[
          { id: 1, name: "Products" },
          { id: 2, name: "Sub-Categories" },
        ]}
        labelKey="name"
        valueKey="id"
      />
      {type && type.id === 2 && (
        <Field
          label="Sub-Category"
          name={`associated_products.${index}.sub_categories`}
          component="select"
          isMulti={true}
          defaultValue={field.sub_categories}
          options={subCategories?.data}
          onInputChange={(val) => {
            setInput(val);
          }}
          labelKey="name"
          valueKey="id"
          help="Customers will be allowed to select any product from any selected sub-categories"
        />
      )}
      {type && type.id === 1 && <GroupProducts index={index} />}
      <PopConfirm onConfirm={() => remove(index)}>
        <Button variantColor="error" variant="outline" size="sm">
          Remove Group
        </Button>
      </PopConfirm>
    </Box>
  );
};

const GroupProducts = ({ index }) => {
  const { fields, append, remove } = useFieldArray({
    name: `associated_products.${index}.products`,
  });

  const onRowSelect = (row) => {
    append(row);
  };

  const onRowRemove = (row) => {
    let _fields = [...fields];
    const _index = _fields.findIndex((e) => e.id === row.id);
    remove(_index);
  };

  return (
    <>
      <ProductsTable
        selected={fields}
        onRowSelect={onRowSelect}
        pushSingle
        onRowRemove={onRowRemove}
      />
      <Grid gap="10px" mb="20px" mt="20px">
        {fields &&
          fields?.map((item, i) => (
            <Flex
              align="center"
              key={item.id}
              rounded="lg"
              p="5px"
              border="1px"
              borderColor="borderColour"
              boxShadow="md"
              bg="global.elementBg"
            >
              {item.images && (
                <Image
                  mr="15px"
                  fallbackSrc='https://via.placeholder.com/300.png/e9e9e9/000?text="No Image"'
                  src={`${process.env.REACT_APP_API}/${item.images[0]?.url}`}
                  w="30px"
                  h="30px"
                  objectFit="cover"
                />
              )}
              <Text opacity={0.5} mr="15px">
                <Controller
                  name={`associated_products.${index}.products.${i}.id`}
                  defaultValue={item.id}
                  render={({ field: { value } }) => <Box>{value}</Box>}
                />
                {/* <Field
                                    component='text'
                                    variant='unstyled'
                                    defaultValue={item.id}
                                    isReadOnly
                                    name={`associated_products.${index}.products.${i}.id`}
                                    maxWidth='80px'
                                    
                                /> */}
              </Text>
              <Flex ml="35px" align="center" pr="15px" w="150px">
                <Controller
                  name={`associated_products.${index}.products.${i}.sku`}
                  defaultValue={item.sku}
                  render={({ field: { value } }) => <Box>{value}</Box>}
                />
              </Flex>
              <Controller
                name={`associated_products.${index}.products.${i}.name`}
                defaultValue={item.name}
                render={({ field: { value } }) => (
                  <Text fontWeight="semibold">{value}</Text>
                )}
              />

              <Button
                ml="auto"
                variantColor="error"
                size="sm"
                onClick={() => onRowRemove(item)}
              >
                Remove
              </Button>
            </Flex>
          ))}
      </Grid>
    </>
  );
};

export default AssociatedProducts;
