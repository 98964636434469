import { Box, Grid, Flex, Text, Button, Image } from '@builtbypixel/plasma';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CustomersTable from '../../Customers/components/CustomersTable';

const VoucherCustomers = ({ productTypes, attributes }) => {
    const name = 'customers';
    const { setValue } = useFormContext();
    const selected = useWatch({ name: name });

    const onRowSelect = (rows) => {
        setValue(name, rows);
    };

    const remove = (id) => {
        let _selected = [...selected];
        const index = _selected.findIndex((e) => e.id === id);
        _selected.splice(index, 1);
        setValue(name, _selected);
    };

    return (
        <Box>
            <Text mb='25px'>
                The voucher will only be useable by the selected customers
            </Text>
            <CustomersTable
                selected={selected}
                attributes={attributes}
                onRowSelect={onRowSelect}
            />
            <Grid gap='10px' mb='20px' mt='20px'>
                {selected &&
                    selected?.map((item, i) => (
                        <Flex
                            align='center'
                            key={`selected-${i}`}
                            rounded='lg'
                            p='5px'
                            border='1px'
                            borderColor='borderColour'
                            boxShadow='md'
                            bg='global.elementBg'
                        >
                            <Text opacity={0.5} mr='15px'>
                                {item.id}
                            </Text>
                            <Text fontWeight='semibold'>{item.first_name} {item.last_name} - {item.email}</Text>
                            <Button
                                ml='auto'
                                variantColor='error'
                                size='sm'
                                onClick={() => remove(item.id)}
                            >
                                Remove
                            </Button>
                        </Flex>
                    ))}
            </Grid>
        </Box>
    );
};

export default VoucherCustomers;
