import { EditView, useGet, useHttp } from '@builtbypixel/nucleus';
import {
  Button,
  Flex,
  PopConfirm,
  useToast,
  Text,
  Box,
} from '@builtbypixel/plasma';
import React, { useMemo, useState, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import setup from './setup';
import DynamicFields from '../Products/Fields/DynamicFields';

const EditForm = () => {
  const { type } = useParams();

  return (
    <EditView setup={setup} isFullWidth>
      <FieldComponent />
    </EditView>
  );
};

const FieldComponent = () => {
  return (
    <DynamicFields
      // fields={fields}
    />
  );
};

export default EditForm;
