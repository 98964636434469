import React, { useEffect, useState } from 'react';
import { Box } from '@builtbypixel/plasma';
import { LinkButton, useGet, EditView } from '@builtbypixel/nucleus';
import useSWR from 'swr';
import { useParams } from 'react-router';
import setup from './setup';
import OrdersView from './OrdersView';

const View = () => {
  const { id } = useParams();
  const { data: order } = useSWR(`/orders/${id}`, useGet);
  return (
    <Box w='100%' p='20px'>
      {order?.data?.purchase_order ? (
        <EditView setup={setup}>
          <OrdersView order={order}></OrdersView>
        </EditView>
      ) : (
        <OrdersView order={order}></OrdersView>
      )}
    </Box>
  );
};

export default View;
