import React from 'react';
import { Field, useGet } from '@builtbypixel/nucleus';
import { useWatch } from 'react-hook-form';
import useSWR from 'swr';

const FreeDeliveryRegions = () => {
  const countriesWatch = useWatch({ name: 'free_delivery_countries' });
  const UK = Array.isArray(countriesWatch)
    ? countriesWatch?.find((c) => {
        return c.id === 229 || c.name === 'United Kingdom';
      })
    : null;

  const { data: regions } = useSWR(
    UK ? `/countries/${UK.id}/regions` : null,
    useGet
  );

  if (regions?.data?.length > 0) {
    return (
      <Field
        name='free_delivery_regions'
        label='Regions (UK ONLY)'
        component='select'
        labelKey='name'
        valueKey='id'
        isMulti={true}
        options={regions?.data}
        help='Select the regions eligible for free delivery'
      ></Field>
    );
  } else return null;
};

export default FreeDeliveryRegions;
