import React, { useEffect, useState } from 'react';
import { Field, useGet } from '@builtbypixel/nucleus';
import {
  PseudoBox,
  useColorMode,
  Text,
  Box,
  Flex,
  Image,
  Card,
  Select,
} from '@builtbypixel/plasma';

import { Link, useParams } from 'react-router-dom';
import { useFormContext, useWatch } from 'react-hook-form';
import useSWR from 'swr';

const AttributeParent = () => {
  const { data } = useFormContext();
  const attribute_sets = JSON.parse(localStorage.getItem('attribute_sets'));

  // useEffect(() => {
  //     if (value) {
  //         localStorage.setItem('attribute_sets', JSON.stringify(value));
  //     }
  // }, [value]);
  // console.log({ attribute_sets });
  return (
    <AttributeComponent
      attributeSet={attribute_sets}
      attributes={data ? data.attribute_sets : attribute_sets}
      data={data}
    />
  );
};

const AttributeComponent = ({ attributes, attributeSet, data }) => {
  const { setValue } = useFormContext();
  const { data: attributeTypes } = useSWR('/attribute-sets', useGet);
  const value = useWatch({ name: 'attribute_sets' });
  const [ready, setReady] = useState(false);
  const [sel, setSel] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const { type } = useParams();
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (data?.from_orderwise && selectedAttribute) {
      setReady(true);
      setTimeout(() => {
        setSel(selectedAttribute);
        setValue('attribute_sets', selectedAttribute);
      }, 300);
    } else if (attributes && type === 'edit') {
      setReady(true);
      setTimeout(() => {
        setSel(attributes);
        setValue('attribute_sets', attributes);
      }, 300);
    } else {
      setReady(true);
      setTimeout(() => {
        setSel(attributes);
        setValue('attribute_sets', attributes);
      }, 300);
    }
  }, [attributes, type, setValue, selectedAttribute]);

  useEffect(() => {
    if (value) {
      localStorage.setItem('attribute_sets', JSON.stringify(value));
    }
  }, [value]);

  const renderLabel = (option) => {
    if (option?.content && option?.content[0]?.url) {
      return (
        <Flex align='center' py='3px'>
          <Image
            src={process.env.REACT_APP_API + option.content[0].url}
            w='24px'
            h='24px'
            objectFit='contain'
            mr='15px'
            rounded='5px'
          />
          <Text>{option.name}</Text>
        </Flex>
      );
    } else {
      return (
        <Flex align='center'>
          <Text>{option.name}</Text>
        </Flex>
      );
    }
  };

  const getComponent = (id) => {
    switch (id) {
      case 4:
        return 'select';

      case 5:
        return 'select';

      case 6:
        return 'switch';
      case 8:
        return 'file';
      case 10:
        return 'select';
      default:
        return 'text';
    }
  };
  return (
    <Flex direction='column'>
      {data?.from_orderwise && (
        <Flex direction='column' w='100%' mb={10} mt={5}>
          <Text fontSize='1em' mb='10px' fontWeight='semibold'>
            Select Attribute Set
          </Text>

          <Select
            name='attribute_sets'
            options={attributeTypes?.data}
            size='lg'
            labelKey='title'
            valueKey='id'
            onChange={
              (v) => {
                console.log(v, 'VVVV');
                setSelectedAttribute(v);
                setSel(v);
                setValue('attribute_sets', (old) => ({
                  ...old,
                  attribute_sets: v,
                }));
              }
              // setInitial((old) => ({ ...old, attribute_sets: v }))
            }
            placeholder='Select attribute set...'
            value={selectedAttribute}
            isRequired
            rules={{ required: true }}
          />
        </Flex>
      )}

      <Box
        px='1em'
        py='0.3em'
        bg='#f0f2f5'
        border='solid 1px black'
        borderRadius='5px'
      >
        <Flex align='center'>
          <Text my='1em' fontWeight='bold' color={'black'}>
            Attribute Set:
          </Text>
          <Link to={`/attribute-sets/edit/${value?.id}`}>
            <Text ml='1em' my='1em' fontWeight='bold' color={'black'}>
              {value?.name || value?.title || selectedAttribute?.title}
            </Text>
          </Link>
        </Flex>
      </Box>

      {sel &&
        sel.groups.map(
          (g, groupIndex) =>
            g.attributes.length !== 0 && (
              <PseudoBox
                py='10px'
                borderBottom='1px'
                borderColor='global.borderColour'
                _last={{ borderBottom: 'none' }}
                key={`group-${groupIndex}`}
              >
                <Text mb='15px' fontWeight='semibold'>
                  {g.title}
                </Text>

                {ready &&
                  g.attributes.map((field, i) => (
                    <Field
                      component={getComponent(field.type?.id)}
                      key={`af-${i}`}
                      name={`attribute_sets.groups.${groupIndex}.attributes.${i}.value`}
                      label={field.label}
                      description={field.description}
                      options={field?.options ?? []}
                      labelKey='name'
                      valueKey='id'
                      isMulti={field?.type?.id === 5}
                      isMultiple={field?.type?.id === 8}
                      useBaseUrl
                      defaultValue={field.value}
                      // isRequired={
                      //     field?.type?.id === 6
                      //         ? false
                      //         : field.required
                      //         ? field.required
                      //         : false
                      // }
                      // rules={{
                      //     required:
                      //         field?.type?.id === 6
                      //             ? false
                      //             : field.required
                      //             ? field.required
                      //             : false,
                      // }}
                      getOptionLabel={(option) => renderLabel(option)}
                    />
                  ))}
              </PseudoBox>
            )
        )}
    </Flex>
  );
};

export default AttributeParent;
