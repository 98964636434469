import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';

import setup from './setup';

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: 'Main Details',
        fields: [
          {
            name: 'name',
            component: 'text',
            placeholder: 'Name',
            label: 'Account Type Name',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'free_delivery',
            component: 'switch',
            label: 'Free Delivery',
          },
          {
            name: 'free_delivery_amount',
            component: 'text',
            placeholder: 'Amount',
            label: 'Free Delivery Amount',
            help: "enter 0.00 if free delivery doesn't apply",
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'payable_by_account',
            component: 'switch',
            label: 'Payable on Account',
          },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
