import React, { useState, useEffect } from 'react';
import { EditView, useGet, useHttp, Field } from '@builtbypixel/nucleus';
import { Box, Card } from '@builtbypixel/plasma';
import useSWR from 'swr';
import setup from './setup';
import regionSelect from './components/RegionSelect';

const EditForm = () => {
  const { data: couriers } = useSWR('/couriers', useGet);
  const [countries, setCountries] = useState([]);

  const Http = useHttp();

  // get only delivered countries
  useEffect(() => {
    Http.get('/countries', {
      params: {
        order: 'asc',
        sort: 'name',
        pagination: 500,
        filters: {
          deliver: {
            value: true,
          },
        },
      },
    }).then((res) => {
      setCountries(res.data.data);
    });
  }, [Http]);

  return (
    <EditView setup={setup}>
      <Box w='100%' maxWidth='600px'>
        <Card my='20px' maxWidth='600px' p='20px'>
          <Field
            component='select'
            name='country'
            label='Country'
            options={countries}
            labelKey='name'
            valueKey='id'
          />
          <Field
            label='Regions'
            name='regions'
            isMulti={true}
            labelKey='name'
            valueKey='id'
            component={regionSelect}
            placeholder='Select regions'
            isRequired={true}
            rules={{ required: true }}
          />
          <Field
            component='switch'
            name='poa'
            help='Whether this is for Price on Application'
            mt={5}
          >
            POA?
          </Field>
          <Field
            component='switch'
            name='pallet'
            help='Whether this is for a Pallet'
            mt={5}
          >
            Pallet?
          </Field>
        </Card>
        <Card my='20px' maxWidth='600px' p='20px'>
          <Field
            label='Name'
            name='name'
            component='text'
            placeholder='Name of the delivery'
            isRequired={true}
            rules={{ required: true }}
          />
          <Field
            label='Price'
            name='price'
            component='text'
            placeholder='Price of the delivery'
            isRequired={true}
            rules={{ required: true }}
          />
          <Field
            label='Weight Limit'
            name='weight_limit'
            component='text'
            placeholder='Weight of the delivery'
            isRequired={true}
            rules={{ required: true }}
          />
          <Field
            label='Courier'
            name='courier'
            options={couriers?.data}
            labelKey='name'
            valueKey='id'
            component='select'
            placeholder='Select a courier'
            isRequired={true}
            rules={{ required: true }}
          />
          <Field
            label='Orderwise Variant ID'
            name='orderwise_variant_id'
            component='text'
            isRequired={true}
            rules={{ required: true }}
          />
          <Field
            component='switch'
            name='free_delivery_switch'
            help='Whether this delivery method is free'
            mt={5}
          >
            Free Delivery?
          </Field>
        </Card>
      </Box>
    </EditView>
  );
};

export default EditForm;
