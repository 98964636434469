import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Table,
  useColorMode,
  Spinner,
  Button,
  Input,
  Flex,
} from '@builtbypixel/plasma';
import { useHttp } from '@builtbypixel/nucleus';
import _ from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';

const Modal = ({
  customCategories,
  columns,
  selected,
  attributes,
  onRowSelect,
  customSetCategories,
  pushSingle,
  onRowRemove,
}) => {
  const { colorMode } = useColorMode();
  const [pagination, setPagination] = useState(10);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const [Categories, setCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState('');
  const Http = useHttp();
  const { getValues, setValue } = useFormContext();

  useEffect(async () => {
    setLoading(true);
    await getCategories(pagination, page, filters);
  }, [filters, page, pagination]);

  useEffect(() => {
    if (selectAll) setValue('categories', Categories?.data);
    else setValue('categories', [...selected]);
  }, [selectAll]);

  const getCategories = async (pagination, page, filters) => {
    await Http.get('/sub-categories', {
      params: { order: 'asc', sort: 'name', pagination, search, page, filters },
    })
      .then((res) => {
        customSetCategories
          ? customSetCategories(res.data)
          : setCategories(res.data);
        selectAll && setValue('categories', res.data?.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const selectEntireData = async () => {
    if (!selectAll) {
      setPagination(9999);
      setPage(1);
    } else setValue('categories', []);
  };

  const setSelected = (product) => {
    let _selected = selected ? [...selected] : [];

    const alreadySelected = _selected.findIndex((s) => s?.id === product?.id);

    if (alreadySelected === -1) {
      if (pushSingle) {
        onRowSelect(product);
      } else {
        _selected.push(product);
      }
    } else {
      if (onRowRemove) {
        onRowRemove(product);
      } else {
        _selected.splice(alreadySelected, 1);
      }
    }
    if (!pushSingle) {
      onRowSelect(_selected);
    }
  };

  const isSelected = (id) => {
    return selected ? selected.some((s) => s?.id === id) : false;
  };

  return (
    <Grid templateColumns='1fr' w='100%' gap='10px' p='5px'>
      <Box p='10px'>
        <Flex mb='20px'>
          <Input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder='Search by name or SKU'
          />
          <Button
            onClick={() => getCategories()}
            variantColor='secondary'
            ml='20px'
            isLoading={loading}
            isDisabled={loading}
          >
            Search
          </Button>
        </Flex>
        {loading && (
          <Flex justify='center' align='center' py='10em'>
            <Spinner />
          </Flex>
        )}
        {(customCategories || Categories) && !loading && (
          <Table
            columns={
              columns ?? [
                {
                  Header: '',
                  accessor: 'id',
                  Cell: ({ row }) => (
                    <Button
                      onClick={() => {
                        setSelected(row.original);
                        selectAll && setSelectAll(false);
                      }}
                      size='sm'
                      color={
                        isSelected(row.original.id)
                          ? 'white'
                          : colorMode === 'light'
                          ? 'white'
                          : 'black'
                      }
                      rounded='md'
                      variantColor={
                        isSelected(row.original.id) ? 'success' : 'primary'
                      }
                    >
                      {isSelected(row.original.id) ? 'Selected' : 'Select'}
                    </Button>
                  ),
                },
                {
                  Header: 'Name',
                  accessor: 'name',
                },
              ]
            }
            data={customCategories ?? Categories}
            isExpandable={false}
            // onRowSelect={(e) => onRowSelect(e)}
            onChangePageSize={(e) => setPagination(e)}
            onChangePage={(e) => setPage(e)}
            canSelect={true}
            loading={loading}
          />
        )}
      </Box>
    </Grid>
  );
};

export default Modal;
