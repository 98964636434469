import React, { useEffect, useState, useCallback } from 'react';
import {
    InputGroup,
    Input,
    InputLeftElement,
    Icon,
} from '@builtbypixel/plasma';
import { useHttp } from '@builtbypixel/nucleus';

import { FaCheckCircle } from 'react-icons/fa';
import { MdRadioButtonUnchecked, MdErrorOutline } from 'react-icons/md';

const Slug = ({ value, onChange }) => {
    const Http = useHttp();

    const [loading, setLoading] = useState(false);

    const [status, setStatus] = useState(null);
    const [val, setVal] = useState();

    useEffect(() => {
        if (value) {
            setVal(value);
        } else {
            setVal('');
        }
    }, [value]);

    const searchSlug = async () => {
        onChange(val);
        setLoading(true);
        Http.post(`/products-check-slug/${val}`)
            .then((res) => {
                if (res.data.data.exists) {
                    setStatus(false);
                } else {
                    setStatus(true);
                }
                setLoading(false);
            })
            .catch(() => {
                setStatus(false);

                setLoading(false);
            });
    };

    const handleChangeValue = (v) => {
        setVal(v);
    };

    return (
        <InputGroup
            errorBorderColor='red'
            focusBorderColor='primary'
            isFullWidth
            placeholder='Enter slug...'
            size='md'
            variant='outline'
        >
            <InputLeftElement>
                {status === null && (
                    <Icon color='rgba(0,0,0,0.1)'>
                        <MdRadioButtonUnchecked fontSize='22px' />
                    </Icon>
                )}
                {status === false && (
                    <Icon color='red'>
                        <MdErrorOutline fontSize='22px' />
                    </Icon>
                )}
                {status === true && (
                    <Icon color='success'>
                        <FaCheckCircle fontSize='22px' />
                    </Icon>
                )}
            </InputLeftElement>
            <Input
                placeholder='Enter slug...'
                onChange={(e) => handleChangeValue(e.target.value)}
                onBlur={() => searchSlug()}
                value={val}
            />
        </InputGroup>
    );
};

export default Slug;
