import React, { useMemo } from 'react';
import { AutoFields, EditView, useGet } from '@builtbypixel/nucleus';
import useSWR from 'swr';
import setup from './setup';

const EditForm = () => {
    const { data: cat } = useSWR('/privacy-policy-categories', useGet);

    const fields = useMemo(
        () => [
            {
                group: 'Privacy Policy',
                fields: [
                    {
                        name: 'question',
                        component: 'text',
                        placeholder: 'Privacy Policy Question',
                        label: 'Privacy Policy Question',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'answer',
                        component: 'text',
                        placeholder: 'Privacy Policy Answer',
                        label: 'Privacy Policy Answer',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'category',
                        component: 'select',
                        options: cat?.data,
                        labelKey: 'name',
                        valueKey: 'id',
                        placeholder: 'Privacy Policy Category',
                        label: 'Privacy Policy Category',
                        isRequired: true,
                        rules: { required: true },
                    },
                ],
            },
        ],
        [cat]
    );
    return (
        <EditView setup={setup} isFullWidth>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
