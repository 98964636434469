import React, {useState, useEffect} from 'react'
import { ListWrapper, useHttp } from '@builtbypixel/nucleus'
import { Button } from '@builtbypixel/plasma';
import { Link } from 'react-router-dom';
import setup from './setup'

// Extra Menu appears next to the create button
const extraMenu = () => {
   return null
}

const List = () => {
   const [countries, setCountries] = useState([]);

   const Http = useHttp();

   // get only delivered countries
    useEffect(() => {
        Http.get('/countries', {
            params: {
                order: 'asc',
                sort: 'name',
                pagination: 500,
                filters: {
                    deliver: {
                        "value": true
                    },
                },
            },
        }).then((res) => {
            setCountries(res.data);
        });
    }, [Http]);


   const columns = [
      {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ row }) => (
                <Link
                    to={`${setup.model}/edit/${row.original[setup.accessor]}`}
                >
                    <Button variantColor='secondary' variant='link'>
                        {row.original.name}
                    </Button>
                </Link>
            ),
        },

      {
         Header: 'Country',
         accessor: 'country.name'
      },
   ];

   const filters = [
        {
            component: 'select',
            options: countries?.data,
            labelKey: 'name',
            valueKey: 'id',
            name: 'country',
            placeholder: 'Filter Countries',
        },
    ];


   return <ListWrapper
            setup={setup}
            columns={columns}
            extraMenu={extraMenu}
            filters={filters}
         />
}

export default List
