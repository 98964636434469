import React, { useState, useEffect, useMemo } from 'react';
import { AutoFields, EditView, useGet } from '@builtbypixel/nucleus';
import setup from './setup';
import { useHttp } from '@builtbypixel/nucleus';

const EditForm = () => {
  const [status, setStatus] = useState([]);
  const Http = useHttp();

  useEffect(() => {
    Http.get('/contact-forms-status').then((res) => {
      setStatus(res.data.data);
    });
  }, []);

  const fields = useMemo(
    () => [
      {
        group: 'Contact Form',
        fields: [
          {
            name: 'status',
            component: 'select',
            options: status,
            labelKey: 'name',
            valueKey: 'id',
            label: 'Status',
          },
          {
            name: 'full_name',
            component: 'textarea',
            isReadOnly: true,
            placeholder: 'Full Name',
            label: 'Full Name',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'organisation',
            component: 'text',
            isReadOnly: true,
            placeholder: 'Organisation',
            label: 'Organisation',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'email',
            component: 'text',
            type: 'email',
            isReadOnly: true,
            placeholder: 'Email',
            label: 'Email',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'phone_number',
            component: 'text',
            isReadOnly: true,
            placeholder: 'Telephone',
            label: 'Telephone',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'department.name',
            component: 'text',
            isReadOnly: true,
            placeholder: 'Department',
            label: 'Department',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'message',
            component: 'textarea',
            isReadOnly: true,
            placeholder: 'Message',
            label: 'Message',
            isRequired: true,
            rules: { required: true },
          },
        ],
      },
    ],
    [status]
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
