import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Page from '../components/Page';
import { hasAuthToken } from '../helpers';
import { checkPermissions } from '../helpers';
import { globalAtom } from '../state/global';
import { useRecoilValue } from 'recoil';

const PrivateRoute = ({
  component: Component,
  title,
  permissions,
  ...rest
}) => {
  const global = useRecoilValue(globalAtom);

  const hasPermission = (permissions) => {
    if (global.user) {
      if (global.user?.data?.roles?.[0]?.name) {
        return checkPermissions({
          has: global.user?.data?.roles?.[0]?.name,
          required: permissions,
        });
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  return (
    <Route
      {...rest}
      render={(props) =>
        hasAuthToken() && hasPermission(permissions) ? (
          <Page title={title} auth>
            <Component {...props} />
          </Page>
        ) : hasAuthToken() &&
          global.user?.data?.roles?.[0]?.name === undefined &&
          hasPermission(permissions) === false ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: rest.location },
            }}
          />
        ) : !hasAuthToken() &&
          global.user?.data?.roles?.[0]?.name === undefined ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: rest.location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: rest.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
