import React, { useEffect } from 'react'
import { Switch } from '@builtbypixel/plasma'

const SwitchField = (props) => {
   const { setActive, onChange, onBlur, value, defaultValue, ...rest } = props

   useEffect(() => {
      if (value === '') {
         onChange(false)
      }
   }, [value])

   return (
      <Switch
         onChange={(e) => onChange(e.target.checked)}
         onBlur={() => {
            onBlur && onBlur()
            setActive && setActive(false)
         }}
         onFocus={() => setActive && setActive(true)}
         value={value === true ? true : false}
         isChecked={value === true ? true : false}
         required={false}
         isRequired={false}
         defaultValue={defaultValue ? defaultValue : false}
         {...rest}
      />
   )
}

export default SwitchField
