const inventoryFields = [
  {
    group: 'Inventory',
    tab: 'Inventory',
    fields: [
      {
        name: 'stock_count',
        component: 'text',
        type: 'number',
        label: 'Stock Count',
        isRequired: true,
        rules: { required: true },
      },
      {
        name: 'inventory.ignore_ow_stock',
        component: 'switch',

        label: 'Ignore Stock Count, Prices and Product Name from Orderwise',
      },
      {
        name: 'inventory.min_stock_watch',
        component: 'text',
        type: 'number',
        label: 'Minimum Stock Balance',
        isRequired: true,
        rules: { required: true },
      },
      {
        name: 'inventory.min_qty',
        component: 'text',
        type: 'number',
        label: 'Minimum Qty Allowed in Shopping Cart',
        defaultValue: null,
      },
      {
        name: 'inventory.max_qty',
        component: 'text',
        type: 'number',
        label: 'Maximum Qty Allowed in Shopping Cart',
        defaultValue: null,
      },
      {
        name: 'inventory.qty_increments',
        component: 'switch',
        label: 'Enable Qty Increments',
      },
      {
        name: 'inventory.pack_size',
        placeholder: 'Enter pack size',
        label: 'Pack Size',
        component: 'text',
        type: 'number',
        step: 1,
        maxWidth: '200px',
        childProps: {
          label: 'Pack Size',
          step: 1,
          type: 'number',
          placeholder: 'Enter pack size',
        },
        condition: {
          watchField: 'inventory.qty_increments',
          matchValue: true,
          operator: '===',
          childComponent: 'text',
        },
      },
      {
        name: 'inventory.weight',
        component: 'text',
        type: 'number',
        label: 'Weight (g)',
        isRequired: true,
        rules: { required: true },
      },
    ],
  },
];

export default inventoryFields;
