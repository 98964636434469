import React, { useEffect, useState } from 'react';
import { ListWrapper, useGet } from '@builtbypixel/nucleus';
import { Button, Text, Icon } from '@builtbypixel/plasma';
import { Image, Transformation } from 'cloudinary-react';
import { Link } from 'react-router-dom';
import setup from './setup';
// import { tableAtom } from '../../Nucleus/state/global';
import { MdCheck } from 'react-icons/md';
import { useRecoilState } from 'recoil';

// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const List = () => {
  // const [tableState, setTableState] = useRecoilState(tableAtom);

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      width: '20px',
    },

    {
      Header: 'Product Name',
      accessor: 'name',
      Cell: ({ row }) => (
        <Link to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button
            variant='link'
            href={`${setup.model}/edit/${row.original[setup.accessor]}`}
            as='a'
            variantColor='secondary'
            rounded='md'
          >
            {row.original.name}
          </Button>
        </Link>
      ),
    },
    {
      Header: 'Image',
      accessor: 'images',
      Cell: ({ row }) =>
        row.original.images && row.original.images.length !== 0 ? (
          <Image publicId={row.original.images[0].public_id} secure='true'>
            <Transformation
              width='40'
              height='40'
              crop='thumb'
              fetchFormat='auto'
            />
          </Image>
        ) : (
          'No Image'
        ),
    },
    {
      Header: 'SKU',
      accessor: 'sku',
      Cell: ({ row }) => <pre>{row.original.sku}</pre>,
    },
    {
      Header: 'Product Type',
      accessor: 'product_type_id',
      Cell: ({ row }) => (
        <Text opacity={0.7}>{row.original.product_type_id.name}</Text>
      ),
    },
    {
      Header: 'Price',
      accessor: 'price',
      Cell: ({ row }) => <Text opacity={0.7}>{row.original.price}</Text>,
      // Cell: ({ row }) => <pre>£{row.original.price}</pre>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row }) =>
        row.original.status.name === 'Active' ? (
          <Icon fontSize='20px' color='success'>
            <MdCheck />
          </Icon>
        ) : (
          <Text opacity={0.7}>{row.original.status.name}</Text>
        ),
    },
    {
      Header: 'Weight',
      accessor: 'weight',
      Cell: ({ row }) => <Text>{`${row.original.weight} (g)`}</Text>,
    },
    {
      Header: 'Stock',
      accessor: 'stock_count',
    },
  ];

  const filters = [
    {
      component: 'select',
      options: [
        {
          id: 1,
          name: 'Active',
        },
        {
          id: 2,
          name: 'Inactive',
        },
        {
          id: 3,
          name: 'Not Visisble Individually',
        },
      ],
      labelKey: 'name',
      valueKey: 'id',
      name: 'status',
      placeholder: 'Status',
    },
    {
      component: 'select',
      options: [
        {
          id: 1,
          name: 'Simple Product',
        },
        {
          id: 2,
          name: 'Grouped Product',
        },
        {
          id: 3,
          name: 'Configurable Product',
        },
        {
          id: 4,
          name: 'Bundle Product',
        },
      ],
      labelKey: 'name',
      valueKey: 'id',
      name: 'type',
      placeholder: 'Type',
    },
  ];

  //   useEffect(() => {
  //       setTableState((old) => ({
  //         ...old,
  //         params: {
  //           ...old.params,
  //           orderwise_products: true,
  //         },
  //       }));
  //  }, [setTableState]);

  return (
    <ListWrapper
      setup={setup}
      columns={columns}
      extraMenu={extraMenu}
      filters={filters}
    />
  );
};

export default List;
