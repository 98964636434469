import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Table } from '@builtbypixel/plasma';
import PageContainer from '../Components/PageContainer';
import { customers } from '../Api';
import { useHttp } from '@builtbypixel/nucleus';
import Loader from '../Components/Loader';
import CustomTable from '../Components/CustomTable';
import CustomerOrder from '../../Customers/components/CustomerOrder';

const Customers = ({ setCurrentPage, currentPage }) => {
  const [customersRes, setCustomersRes] = useState([]);
  const [allCustomersRes, setAllCustomersRes] = useState(null);
  const [loading, setLoading] = useState(false);
  const Http = useHttp();

  const fetchData = (
    dateFrom = null,
    dateTo = null,
    pagination = 20,
    page = 1
  ) => {
    setLoading(true);
    Http.get(customers, {
      params: { from_date: dateFrom, to_date: dateTo, pagination, page },
    }).then((res) => {
      setCustomersRes(res.data);
      setLoading(false);
    });

    Http.get('/reports/dashboard/all-customer-count').then((res) =>
      setAllCustomersRes(res.data)
    );
  };

  const columns = [
    {
      Header: 'Customers',
      accessor: 'name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'No. of Orders',
      accessor: 'no_of_orders',
    },
    {
      Header: 'Average Order Amount',
      accessor: 'average_order_amount',
      Cell: ({ row }) => {
        return <Text>{`£${row.original.average_order_amount}`}</Text>;
      },
    },
    {
      Header: 'Total Order Amount',
      accessor: 'total_order_amount',
      Cell: ({ row }) => {
        return <Text>{`£${row.original.total_order_amount}`}</Text>;
      },
    },
    {
      Header: 'Orders',
      accessor: '-',
      Cell: ({ row }) => (
        <CustomerOrder
          customerEmail={row.original.email}
          totalOrderAmount={row.original.total_order_amount}
          totalOrders={row.original.no_of_orders}
        />
      ),
    },
  ];

  return (
    <PageContainer
      title='Customers'
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      endpoint={customers}
      fetchData={fetchData}
      data={customersRes?.data}
      excelFields={[
        'id',
        'name',
        'email',
        'no_of_orders',
        'average_order_amount',
        'total_order_amount',
      ]}
      addStats={
        <Flex w='100%' justify='space-evenly'>
          <Flex
            align='center'
            w='200px'
            direction='column'
            border='2px solid black'
            bg='#666666'
            color='white'
          >
            <Text textAlign='center' fontSize='1.4em'>
              New Customers
            </Text>
            <Text textAlign='center' fontSize='2em' fontWeight='bold'>
              {customersRes?.meta?.total}
            </Text>
          </Flex>
          <Flex
            w='200px'
            direction='column'
            border='2px solid black'
            // bg='#cbd500'
            bg='#666666'
            color='white'
            align='center'
          >
            <Text textAlign='center' fontSize='1.4em'>
              All Time Customers
            </Text>
            <Text textAlign='center' fontSize='2em' fontWeight='bold'>
              {allCustomersRes?.count}
            </Text>
          </Flex>
        </Flex>
      }
    >
      {loading && <Loader />}
      {customersRes?.data?.length > 0 && (
        <CustomTable data={customersRes} columns={columns} />
      )}
    </PageContainer>
  );
};

export default Customers;
