import React, { useMemo } from 'react';
import { AutoFields, EditView, useGet } from '@builtbypixel/nucleus';
import useSWR from 'swr';
import setup from './setup';

const EditForm = () => {
    const fields = useMemo(
        () => [
            {
                group: 'Category Information',
                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        placeholder: 'Category Name',
                        label: 'Category Name',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'description',
                        component: 'richtext',
                        placeholder: 'Category Description',
                        label: 'Category Description',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'seo_index.meta_title',
                        component: 'text',
                        placeholder: 'Meta Title',
                        label: 'Meta Title',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'seo_index.meta_description',
                        component: 'text',
                        placeholder: 'Meta Description',
                        label: 'Meta Description',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'seo_index.meta_keywords',
                        component: 'text',
                        placeholder: 'Meta Keywords',
                        label: 'Meta Keywords',
                        isRequired: true,
                        rules: { required: true },
                    },
                ],
            },
        ],
        []
    );
    return (
        <EditView setup={setup} isFullWidth>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
