import React from 'react';
import { ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@builtbypixel/plasma';
import { Link } from 'react-router-dom';
import setup from './setup';
import dayjs from 'dayjs';

// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const List = () => {
  const columns = [
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ row }) => (
        <Link to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variantColor='secondary' variant='link'>
            {row.original.title}
          </Button>
        </Link>
      ),
    },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ value }) => (value?.[0] ? value?.[0]?.name : ''),
    },
    {
      Header: 'Active',
      accessor: 'active',
      Cell: ({ value }) => (value ? 'Active' : 'Draft'),
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ value }) => dayjs(value).format('DD/MM/YYYY HH:mm'),
    },
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
