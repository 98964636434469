import React, { useContext, useEffect, useState } from 'react';
import { useGet, useHttp, Field } from '@builtbypixel/nucleus';
import { Box, Grid, Text, Input } from '@builtbypixel/plasma';
import { useFormContext, useWatch } from 'react-hook-form';
import ProductsTable from '../ProductsTable';
import SelectedProductsList from '../SelectedProductsList';

const ProductSelector = ({ value, onChange, productTypes, attributes }) => {
  const name = 'ow_products';
  const { setValue, register } = useFormContext();
  const selected = useWatch({ name: name });

  const onRowSelect = (rows) => {
    setValue(name, rows);
  };

  return (
    <Box>
      <Text mb='1em' fontWeight='bold'>
        Update OW Product Stock & Prices
      </Text>
      <Input
        as='input'
        autoFocus='autoFocus'
        id='name'
        placeholder='Number of products to import'
        size='md'
        type='number'
        variant='outline'
        value={value}
        mb='1em'
        width='50%'
        onChange={(e) => onChange(e.target.value)}
      />
      {/* <Text mb='25px'>
        Select some products - if no products are selected then all products
        will be updated
      </Text> */}
      {/* <ProductsTable
        selected={selected}
        productTypes={productTypes}
        attributes={attributes}
        onRowSelect={onRowSelect}
        {...register(name)}
      />
      <Grid gap='10px' mb='20px' mt='20px'>
        {selected &&
          selected?.map((item, i) => (
            <SelectedProductsList
              item={item}
              name={name}
              key={`item-${i}`}
              index={i}
            />
          ))}
      </Grid> */}
    </Box>
  );
};

export default ProductSelector;
