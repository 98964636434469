import React from 'react';
import { Flex, Card, Text } from '@builtbypixel/plasma';
import { EditView, Field, useGet } from '@builtbypixel/nucleus';

const CommunicationPreferences = () => {
  return (
    <Flex w='100%' maxWidth='700px' mx='auto'>
      <Card w='100%' p='10px'>
        <Text mb='1em'>Communication Preferences</Text>
        <Flex justify='space-evenly'>
          <Field
            label='SMS'
            name='communication_preferences.text_message'
            component='switch'
            isDisabled
          />
          <Field
            label='Email'
            name='communication_preferences.email'
            component='switch'
            isDisabled
          />
          <Field
            label='Phone'
            name='communication_preferences.telephone'
            component='switch'
            isDisabled
          />
          <Field
            label='Post'
            name='communication_preferences.post'
            component='switch'
            isDisabled
          />
        </Flex>
      </Card>
    </Flex>
  );
};

export default CommunicationPreferences;
