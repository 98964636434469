import React from 'react';
import { Flex, Box, Text, Card } from '@builtbypixel/plasma';
import ToolBar from '../Toolbar';
import Header from './Header';
import { routeSelectPages } from '../../Page';
import { useLocation } from 'react-router';

const PageContainer = ({
  children,
  setCurrentPage,
  currentPage,
  title,
  endpoint,
  fetchData,
  data,
  excelFields,
  excelSecondTable,
  excelSecondTableFields,
  toolbarFilters,
  addStats,
  customExport,
}) => {
  let { search } = useLocation();

  return (
    <Flex direction='column' justify='center' width='100%'>
      <Box p='2em' width='100%'>
        <Card p='1em'>
          <Header
            title={title}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
          {search && !routeSelectPages.includes(currentPage) && (
            <ToolBar
              fetchData={fetchData}
              endpoint={endpoint}
              currentPage={currentPage}
              data={data}
              excelFields={excelFields}
              excelSecondTable={excelSecondTable}
              excelSecondTableFields={excelSecondTableFields}
              toolbarFilters={toolbarFilters}
              addStats={addStats}
              customExport={customExport}
            />
          )}
          <Flex justify='center' flexWrap='wrap' width='100%' minHeight='600px'>
            {children}
          </Flex>
        </Card>
      </Box>
    </Flex>
  );
};

export default PageContainer;
