import {
  Box,
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalButton,
  ModalBody,
  ModalFooter,
  Text,
  Divider,
} from '@builtbypixel/plasma';
import React from 'react';
import CategoryTable from './Modal';
import { useFormContext, useWatch } from 'react-hook-form';

const TableModal = ({
  columns,
  attributes,
  onRowSelect,
  onRowRemove,
  pushSingle,
  selected,
  customSetCategories,
  customCategories,
}) => {
  return (
    <Modal size='xl'>
      <ModalButton>
        <Button variantColor='secondary' variant='outline' size='sm'>
          Select Categories
        </Button>
      </ModalButton>
      <ModalContent>
        <ModalHeader>
          Select Categories -{' '}
          <Text ml='10px' fontWeight='normal'>
            {selected?.length} categories selected
          </Text>
        </ModalHeader>
        <ModalBody>
          <CategoryTable
            selected={selected}
            columns={columns}
            customCategories={customCategories}
            attributes={attributes}
            onRowSelect={onRowSelect}
            customSetCategories={customSetCategories}
            pushSingle={pushSingle}
            onRowRemove={onRowRemove}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const CategoriesTable = ({
  columns,
  attributes,
  onRowSelect,
  onRowRemove,
  pushSingle,
  selected,
  customSetCategories,
  customCategories,
}) => {
  const selectAll = useWatch({ name: 'select_all_categories' });

  return (
    <Box maxWidth='100%' w='100%'>
      {selectAll ? (
        <Box>
          <Text fontWeight='bold'>Excluded Products</Text>
          <Divider mb='1em' />
          <TableModal
            selectAll={selectAll}
            columns={columns}
            attributes={attributes}
            onRowSelect={onRowSelect}
            onRowRemove={onRowRemove}
            pushSingle={pushSingle}
            selected={selected}
            customSetCategories={customSetCategories}
            customCategories={customCategories}
          />
        </Box>
      ) : (
        <TableModal
          columns={columns}
          attributes={attributes}
          onRowSelect={onRowSelect}
          onRowRemove={onRowRemove}
          pushSingle={pushSingle}
          selected={selected}
          customSetCategories={customSetCategories}
          customCategories={customCategories}
        />
      )}
    </Box>
  );
};

export default CategoriesTable;
