import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  Grid,
  Box,
  Table,
  useColorMode,
  Button,
  Input,
  Flex,
  Text,
  PopConfirm,
  ModalContext,
} from '@builtbypixel/plasma';
//import FilterModal from './FilterModal';
import debounce from 'lodash.debounce';

import { useHttp } from '@builtbypixel/nucleus';
import { Image, Transformation } from 'cloudinary-react';

const SearchInput = ({ setSearchValue, getProducts }) => {
  const [search, setSearch] = useState('');

  const _search = useCallback(
    debounce(() => {
      setSearchValue(search);
      return getProducts(search), 500;
    }),
    [search]
  );

  useEffect(() => {
    if (search && search.length > 3) {
      _search();
    }
    if (search === null) {
      _search();
    }

    // Cancel the debounce on useEffect cleanup.
    return _search.cancel;
  }, [search, _search]);

  return (
    <Flex pos='relative' w='100%' align='center'>
      <Input
        onChange={(e) => setSearch(e.target.value)}
        // onBlur={() => setSearchValue(search)}
        value={search}
        placeholder='Search by name or SKU'
      />
      <Button
        pos='absolute'
        right='10px'
        size='sm'
        variant='ghost'
        zIndex={5000}
        onClick={() => setSearch(null)}
      >
        Clear Search
      </Button>
    </Flex>
  );
};

const Modal = ({
  customProducts,
  columns,
  selected,
  onRowSelect,
  customSetProducts,
  pushSingle,
  onRowRemove,
}) => {
  const { colorMode } = useColorMode();
  const [pagination, setPagination] = useState(10);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const Http = useHttp();
  const { toggle } = useContext(ModalContext);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      getProducts(search);
    }, 1000);
  }, [filters, search, page, pagination]);

  // useEffect(() => {
  //   if (search) {
  //     getProducts();
  //   }
  // }, [search]);

  const getProducts = (search) => {
    setLoading(true);

    Http.get('/products', {
      params: { pagination, search, page, filters },
    })
      .then((res) => {
        customSetProducts ? customSetProducts(res.data) : setProducts(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const setSelected = (product) => {
    let _selected = selected ? [...selected] : [];

    const alreadySelected = _selected.findIndex((s) => s?.id === product?.id);

    if (alreadySelected === -1) {
      if (pushSingle) {
        onRowSelect(product);
      } else {
        _selected.push(product);
      }
    } else {
      if (onRowRemove) {
        onRowRemove(product);
      } else {
        _selected.splice(alreadySelected, 1);
      }
    }
    if (!pushSingle) {
      onRowSelect(_selected);
    }
  };

  const isSelected = (id) => {
    return selected ? selected.some((s) => s?.id === id) : false;
  };

  return (
    <Grid templateColumns='1fr 350px' w='100%' gap='10px' p='5px'>
      <Box p='10px'>
        <form onSubmit={() => getProducts(search)}>
          <Flex mb='20px'>
            <SearchInput setSearchValue={setSearch} getProducts={getProducts} />
            <Button
              onClick={() => getProducts(search)}
              type='submit'
              variantColor='secondary'
              ml='20px'
              isLoading={loading}
              isDisabled={loading}
            >
              Search
            </Button>
          </Flex>
        </form>

        <Table
          columns={
            columns ?? [
              {
                Header: '',
                accessor: 'id',
                Cell: ({ row }) => (
                  <Button
                    onClick={() => {
                      setSelected(row.original);
                    }}
                    size='sm'
                    color={
                      isSelected(row.original.id)
                        ? 'white'
                        : colorMode === 'light'
                        ? 'white'
                        : 'black'
                    }
                    rounded='md'
                    variantColor={
                      isSelected(row.original.id) ? 'success' : 'primary'
                    }
                  >
                    {isSelected(row.original.id) ? 'Selected' : 'Select'}
                  </Button>
                ),
              },
              {
                Header: 'Image',
                accessor: 'images',
                Cell: ({ row }) =>
                  row.original.images.length !== 0 ? (
                    <Image
                      publicId={row.original.images[0].public_id}
                      secure='true'
                    >
                      <Transformation
                        width='40'
                        height='40'
                        crop='thumb'
                        fetchFormat='auto'
                      />
                    </Image>
                  ) : (
                    <img
                      src='https://via.placeholder.com/300.png/e9e9e9/000?text="No Image"'
                      width={40}
                      alt='No pic'
                    />
                  ),
              },
              {
                Header: 'Name',
                accessor: 'name',
              },
              {
                Header: 'SKU',
                accessor: 'sku',
                Cell: ({ value }) => (
                  <Text fontSize='10px' opacity={0.8}>
                    <pre>{value}</pre>
                  </Text>
                ),
              },

              {
                Header: 'Stock Count',
                accessor: 'stock_count',
              },
            ]
          }
          data={
            customProducts ? customProducts : products ? products : { data: [] }
          }
          isExpandable={false}
          //onRowSelect={(e) => onRowSelect(e)}
          onChangePageSize={(e) => {
            setPagination(e);
            setPage(1);
          }}
          onChangePage={(e) => setPage(e)}
          canSelect={false}
          loading={loading}
          size='sm'
        />
      </Box>
      <Box
        bg={colorMode === 'light' ? 'rgba(0,0,0,0.07)' : 'rgba(0,0,0,0.5)'}
        h='100%'
        rounded='card'
        p='10px'
        w='100%'
        overflowY='scroll'
      >
        {/* <FilterModal
                    isLoading={loading}
                    updateFilters={(filter) => setFilters(filter)}
                /> */}

        <Button
          isFullWidth
          mb='15px'
          onClick={() => toggle()}
          variantColor='secondary'
        >
          Confirm Selections
        </Button>

        {selected &&
          selected.map((sel, i) => (
            <Flex
              key={`selected-${i}`}
              bg='global.elementBg'
              rounded='4px'
              py='6px'
              px='8px'
              mb='6px'
              align='flex-start'
            >
              {sel.images.length !== 0 ? (
                <Image publicId={sel.images[0].public_id} secure='true'>
                  <Transformation
                    width='40'
                    height='40'
                    crop='thumb'
                    fetchFormat='auto'
                  />
                </Image>
              ) : (
                <img
                  src='https://via.placeholder.com/300.png/e9e9e9/000?text="No Image"'
                  width={40}
                  alt='no pic'
                />
              )}
              <Flex direction='column' w='100%' ml='5px' align='flex-start'>
                <Text fontWeight='semibold' fontSize='0.9em'>
                  {sel.name}
                </Text>
                <Text fontSize='9px' opacity={0.6} whitespace='wrap'>
                  <pre>{sel.sku}</pre>
                </Text>
                <PopConfirm onConfirm={() => setSelected(sel)}>
                  <Button
                    variant='link'
                    fontSize='11px'
                    fontWeight='normal'
                    mt='5px'
                    ml='5px'
                  >
                    Remove Product
                  </Button>
                </PopConfirm>
              </Flex>
            </Flex>
          ))}
      </Box>
    </Grid>
  );
};

export default Modal;
