import React, { useCallback, useEffect, useState } from 'react'
import { useFormContext, useFormState } from 'react-hook-form'
import { TiWarningOutline } from 'react-icons/ti'
import {
   Flex,
   Alert,
   Text,
   Card,
   Box,
   Tag,
   Icon,
   Button
} from '@builtbypixel/plasma'

const InfoBar = () => {
   const { errors } = useFormState()
   const [open, setOpen] = useState(false)
   const [large, setLarge] = useState(true)

   return (
      <Card
         title={large ? 'Form Errors' : null}
         maxWidth={large ? '300px' : '100px'}
         position='fixed'
         bottom='10px'
         right='10px'
         border='1px'
         borderColor='global.borderColour'
         display={Object.entries(errors).length !== 0 ? 'block' : 'none'}
      >
         <Box p='10px'>
            {large && (
               <Alert variant='subtle' status='error' hasIcon={false}>
                  <Text pb='15px' fontSize='sm'>
                     There were errors in the following fields in your form.
                     Please correct before continuing.
                  </Text>

                  {Object.values(errors) &&
                     Object.entries(errors).map(([key, value], index) => (
                        <React.Fragment key={`error-${index}`}>
                           <Text
                              fontWeight='semibold'
                              key={`fieldError-${index}`}
                              mb='8px'
                           >
                              <Tag
                                 variantColor='error'
                                 variant='solid'
                                 size='xs'
                                 mr='10px'
                              >
                                 Required
                              </Tag>
                              {key}
                           </Text>
                           <Text>
                              {Array.isArray(value) &&
                                 value.map((key, i) =>
                                    Object.keys(key).map((k, idx) => (
                                       <Flex
                                          key={`sub-field-${i}-${idx}`}
                                          align='center'
                                          pl='20px'
                                       >
                                          <Icon mr='5px'>
                                             <TiWarningOutline />{' '}
                                          </Icon>
                                          <Text fontSize='12px'>
                                             Item {i + 1} - {k}
                                          </Text>
                                       </Flex>
                                    ))
                                 )}
                           </Text>
                        </React.Fragment>
                     ))}
               </Alert>
            )}
            <Button
               isFullWidth
               mt='10px'
               size='sm'
               variantColor={!large ? 'error' : 'gray'}
               onClick={() => setLarge(!large)}
            >
               {large ? 'Minimise' : 'View Errors'}
            </Button>
         </Box>
      </Card>
   )
}

export default InfoBar
