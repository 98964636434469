/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/core';
import { useContext, useEffect, useState, useRef } from 'react';
import FroalaEditor from 'froala-editor';

import { useTheme, useColorMode } from '@builtbypixel/plasma';
import { useHttp } from '../../hooks';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';

import { CloudinaryUploadContext } from '../../Core/Kernel';
import { useRecoilState } from 'recoil';
import { mediaAtom as _mediaAtom } from '../../state/media';

/* eslint-disable */

const RichTextEditor = ({
  onChange,
  value,
  placeholder,
  setActive,
  maxCharacters,
  name,
}) => {
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const Http = useHttp();
  const { show } = useContext(CloudinaryUploadContext);
  const [mediaAtom, setMediaAtom] = useRecoilState(_mediaAtom);
  const [editor, setEditor] = useState(null);
  const [ready, setReady] = useState(false);
  const [froalaEditor, setFroalaEditor] = useState(null);

  useEffect(() => {
    if (value) {
      setReady(true);
      setEditor(value);
    } else {
      setTimeout(() => {
        setReady(true);
        setEditor(null);
      }, 10);
    }
  }, [value]);

  useEffect(() => {
    FroalaEditor.DefineIcon('cloudinaryImage', {
      NAME: 'Insert Image',
      SVG_KEY: 'insertImage',
    });
    FroalaEditor.RegisterCommand('cloudinaryImage', {
      title: 'Upload Image',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback: function () {
        show(name);
      },
    });
    FroalaEditor.RegisterCommand('insertCloudinaryImage', {
      title: 'Upload Image',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback: function () {
        const field = mediaAtom.find((f) => f.name === name);

        if (field) {
          froalaEditor.image.insert(field.files[0].url, null, null, null);
        }
      },
    });
  }, [show]);

  const fEditor = useRef();

  useEffect(() => {
    if (ready) {
      var _editor = new FroalaEditor(
        fEditor.current,
        {
          toolbarButtons: [
            ['bold', 'italic', 'underline', 'align', 'fontSize'],
            [
              'insertLink',
              process.env.REACT_APP_UPLOAD_METHOD !== 'cloudinary'
                ? 'insertImage'
                : 'cloudinaryImage',

              'insertVideo',

              'insertTable',
              'emoticons',
              'specialCharacters',
            ],
            ['formatOL', 'formatUL'],
          ],
          key: 'te1C2sA5B5E6E5B4I4jC1QUd1Xd1OZJ1ABVJRDRNGGUE1ITrE1D4A3A10B1C6B5B1E4H3==',
          maxCharacters: maxCharacters ? maxCharacters : 999999,
          charCounterMax: maxCharacters ? maxCharacters : -1,
          placeholderText: placeholder,
          videoUpload: false,
          toolbarInline: false,
          toolbarSticky: true,
          listAdvancedTypes: true,
          pastePlain: true,
          events: {
            focus: () => {
              setActive(true);
            },
            blur: function () {
              setActive(false);
              onChange(this.html.get());
            },

            'image.beforeUpload': function (images) {
              // Before image is uploaded
              const data = new FormData();
              data.append('file', images[0]);

              Http.post('/file-upload', data, {
                headers: {
                  accept: 'application/json',
                  'Accept-Language': 'en-US,en;q=0.8',
                  'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                },
              })
                .then((res) => {
                  this.image.insert(
                    `${process.env.REACT_APP_API}/${res.data.url}`,
                    null,
                    null,
                    this.image.get()
                  );
                })
                .catch((err) => {
                  console.log(err);
                });
              return false;
            },
          },
        },
        function () {
          // Call the method inside the initialized event.
          _editor.html.set(editor);
        }
      );

      setFroalaEditor(_editor);
    }
    return () => {
      if (editor) {
        _editor.destroy();
        setFroalaEditor(null);
      }
    };
  }, [ready]);

  useEffect(() => {
    const field = mediaAtom.find((f) => f.name === name);

    if (field && field.files.length !== 0) {
      FroalaEditor.COMMANDS.insertCloudinaryImage.callback.call();
    }
  }, [mediaAtom]);

  return (
    <div
      css={{
        color: theme.colors.global.text,
        zIndex: 0,
        position: 'relative',
        '.fr-sticky-off': {
          position: 'sticky !important',
        },
        '.fr-sticky-dummy': {
          display: 'none !important',
        },
        '.fr-toolbar.fr-top': {
          position: 'sticky',
        },
        '.fr-toolbar': {
          backgroundColor: colorMode === 'dark' ? 'transparent' : 'white',
          borderRadius: theme.radii.lg,
          color: theme.colors.global.text,
          padding: '3px 0px',
          border: 0,
          zIndex: '5000 !important',

          '.fr-newline': {
            backgroundColor: theme.colors.global.elementBg,
          },
          '.fr-btn-grp': {
            marginLeft: '0px',
          },

          '.fr-command.fr-btn': {
            margin: 0,
            color: theme.colors.global.text,
            '&:hover': {
              backgroundColor: theme.colors.global.elementBg,
            },
            svg: {
              height: '20px',
            },
            '&.fr-active svg path': {
              fill: theme.colors.secondary,
            },
            '&:not(.fr-active) svg path': {
              fill: theme.colors.global.text,
            },

            '&.fr-btn-active-popup': {
              background: theme.colors.secondary,
              'svg path': {
                fill: 'white',
              },
            },
          },
        },
        '.fr-second-toolbar #fr-logo': {
          display: 'none',
        },
        '.fr-second-toolbar .fr-counter': {
          fontSize: '12px',
          paddingTop: '3px',
        },
        '.fr-popup': {
          backgroundColor: theme.colors.global.elementBgAlt,
          color: theme.colors.global.text,
          zIndex: 9999,
          '.fr-buttons': {
            backgroundColor: theme.colors.global.elementBgAlt,
          },
          '.fr-input-line': {
            padding: '8px 0px',
            label: {
              top: '23px',
              background: 'transparent',
              color: theme.colors.global.text,
            },
            input: {
              background: theme.colors.whiteAlpha[100],
              borderColor: theme.colors.inputs.borderColour,
              color: theme.colors.global.text,
              borderWidth: '1px',
              paddingBottom: '0px',
              paddingTop: '6px',
              '&:focus': {
                borderColor: theme.colors.primary,
                borderWidth: '1px',
              },
            },
          },
          '.fr-checkbox input:checked + span': {
            background: theme.colors.primary,
            borderColor: theme.colors.primary,
            'svg path': {
              fill: colorMode === 'light' ? 'white' : 'black',
            },
          },
        },
        '.fr-box': {
          '.fr-wrapper': {
            borderRadius: '5px',
            background: theme.colors.richText.editorBg,
            color: theme.colors.global.text,
            border: `1px solid ${theme.colors.inputs.borderColour}`,
            borderTop: `1px solid ${theme.colors.inputs.borderColour} !important`,
            cursor: 'text',
          },
          '.fr-element': {
            color: theme.colors.global.text,
            padding: '7px 10px',
            a: {
              textDecoration: 'underline',
              color: theme.colors.secondary,
            },
          },
          '.fr-placeholder': {
            color: theme.colors.gray[300],
            opacity: 0.9,
          },
        },
      }}
    >
      {/* <FroalaEditorComponent
            model={editor}
            onModelChange={handleChange}
            config={{
               key:
                  'te1C2sA5B5E6E5B4I4jC1QUd1Xd1OZJ1ABVJRDRNGGUE1ITrE1D4A3A10B1C6B5B1E4H3==',
               maxCharacters: maxCharacters ? maxCharacters : 999999,
               charCounterMax: maxCharacters ? maxCharacters : -1,
               placeholderText: placeholder,
               videoUpload: false,
               inlineMode: false,
               events: {
                  focus: () => {
                     setActive(true)
                  },
                  blur: () => {
                     setActive(false)
                  },

                  'image.insert': function (images) {
                     // Before image is uploaded
                     const data = new FormData()
                     data.append('file', images[0])

                     Http.post('/file-upload', data, {
                        headers: {
                           accept: 'application/json',
                           'Accept-Language': 'en-US,en;q=0.8',
                           'Content-Type': `multipart/form-data; boundary=${data._boundary}`
                        }
                     })
                        .then((res) => {
                           this.image.insert(
                              `${process.env.REACT_APP_API}/${res.data.url}`,
                              null,
                              null,
                              this.image.get()
                           )
                        })
                        .catch((err) => {
                           console.log(err)
                        })
                     return false
                  }
               },
               toolbarButtons: [
                  ['bold', 'italic', 'underline', 'align'],
                  [
                     'insertLink',
                     process.env.REACT_APP_UPLOAD_METHOD !== 'cloudinary'
                        ? 'insertImage'
                        : 'cloudinaryImage',

                     'insertVideo',
                     'insertTable',
                     'emoticons',
                     'cloudinaryImage',
                     'specialCharacters'
                  ]
               ]
               // ] ] {
               //    moreText: {
               //       buttons: ['bold', 'italic', 'underline', 'align']
               //    },
               //    moreRich: {
               //       buttons: [
               //          'insertLink',
               //          'insertImage',
               //          'insertVideo',
               //          'insertTable',
               //          'emoticons',
               //          'fontAwesome',
               //          'specialCharacters',

               //       ]
               //    }
               // },
               // linkInsertButtons: ['linkBack']
            }}
         /> */}
      <textarea ref={fEditor} onChange={(e) => setEditor(e.target.value)}>
        {editor}
      </textarea>
    </div>
  );
};

export default RichTextEditor;
