import React, { useContext, useEffect, useState } from 'react';
import { useGet, useHttp, Field } from '@builtbypixel/nucleus';
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Table,
  useToast,
  Card,
  Button,
  Text,
  Tag,
  PopConfirm,
  IconButton,
} from '@builtbypixel/plasma';
import moment from 'moment';
import Main from './Tab';
import { useFormContext } from 'react-hook-form';
import { FiTrash } from 'react-icons/fi';

const Content = () => {
  const Http = useHttp();
  const toast = useToast();
  const [numberOfProducts, setNumberOfProducts] = useState(0);
  const [loading, setLoading] = useState(null);
  const [checked, setChecked] = useState(true);

  const [start_date, setStartDate] = useState(moment().startOf('day'));
  const [end_date, setEndDate] = useState(moment().endOf('day'));
  const [xmlImports, setXmlImports] = useState({ data: [] });
  const [pagination, setPagination] = useState(20);
  const [page, setPage] = useState(1);
  const [start_date_sales, setSalesDateStart] = useState(
    moment().startOf('day')
  );
  const [end_date_sales, setSalesDateEnd] = useState(moment().endOf('day'));

  const { getValues } = useFormContext();

  // import products endpoint - tab 1
  const import_products = () => {
    setLoading(true);
    Http.post('/import_ow_products', {
      products: numberOfProducts,
      update: checked,
    })
      .then((res) => {
        setLoading(false);
        toast({
          title: 'Success',
          message: 'Successfully imported products from orderwise',
          status: 'success',
          position: 'top',
          variant: 'plain',
        });
      })
      .catch(() => {
        toast({
          title: 'Error',
          message: 'There was an error with your request',
          status: 'error',
          position: 'top',
          variant: 'plain',
        });
        setLoading(false);
      });
  };

  const handleGetXmlUploadStatus = () => {
    Http.get('/xml-uploads', {
      params: { pagination, page },
    })
      .then((res) => {
        setLoading(false);
        setXmlImports(res.data);
      })
      .catch(() => {
        toast({
          title: 'Error',
          message: 'There was an error fetching uploads',
          status: 'error',
          position: 'top',
          variant: 'plain',
        });
        setLoading(false);
      });
  };

  const handleDeleteXmlFile = (id) => {
    Http.delete('/xml-uploads', { data: { id: [id] } })
      .then((res) => {
        handleGetXmlUploadStatus();
        toast({
          title: 'Success',
          message: 'File removed',
          status: 'success',
          position: 'top',
          variant: 'plain',
        });
      })
      .catch((err) => {
        toast({
          title: 'Error',
          message: 'There was an error deleting the file',
          status: 'error',
          position: 'top',
          variant: 'plain',
        });
      });
  };

  // create/update customers in ow - tab 2
  const create_or_update_customers_in_ow = () => {
    setLoading(true);
    Http.post('/create_or_update_customers_in_ow', { start_date, end_date })
      .then((res) => {
        setLoading(false);
        toast({
          title: 'Success',
          message: 'Successfully created/updated customers in orderwise',
          status: 'success',
          position: 'top',
          variant: 'plain',
        });
      })
      .catch(() => {
        toast({
          title: 'Error',
          message: 'There was an error with your request',
          status: 'error',
          position: 'top',
          variant: 'plain',
        });
        setLoading(false);
      });
  };

  // create sales in ow - tab 3
  const create_sales_in_ow = () => {
    setLoading(true);
    Http.post('/create_sales_in_ow', { start_date_sales, end_date_sales })
      .then((res) => {
        setLoading(false);
        toast({
          title: 'Success',
          message: 'Successfully created sales import in orderwise',
          status: 'success',
          position: 'top',
          variant: 'plain',
        });
      })
      .catch(() => {
        toast({
          title: 'Error',
          message: 'There was an error with your request',
          status: 'error',
          position: 'top',
          variant: 'plain',
        });
        setLoading(false);
      });
  };

  // create reset product endpoint - tab 4
  const reset_product_import = () => {
    setLoading(true);
    Http.post('/reset_product_import')
      .then((res) => {
        setLoading(false);
        toast({
          title: 'Success',
          message: 'Successfully reset products import in orderwise',
          status: 'success',
          position: 'top',
          variant: 'plain',
        });
      })
      .catch(() => {
        toast({
          title: 'Error',
          message: 'There was an error with your request',
          status: 'error',
          position: 'top',
          variant: 'plain',
        });
        setLoading(false);
      });
  };

  // create/update customers in ow - tab 2
  const update_stock_in_ow = () => {
    setLoading(true);
    Http.post('/update_product_price', {
      products: numberOfProducts,
      // ow_products: getValues('ow_products').map((p) =>
      //   p.orderwise_product_id ? p.orderwise_product_id : p.sku
      // ),
    })
      .then((res) => {
        setLoading(false);
        toast({
          title: 'Success',
          message:
            'Successfully updated product stock and prices from orderwise',
          status: 'success',
          position: 'top',
          variant: 'plain',
        });
      })
      .catch(() => {
        toast({
          title: 'Error',
          message: 'There was an error with your request',
          status: 'error',
          position: 'top',
          variant: 'plain',
        });
        setLoading(false);
      });
  };

  const onChange = (e) => {
    setNumberOfProducts(e);
  };
  const checkboxHandler = (e) => {
    setChecked(e);
  };

  useEffect(() => {
    handleGetXmlUploadStatus();
  }, [pagination, page]);

  return (
    <form
      style={{
        marginRight: 'auto',
        padding: 20,
      }}
    >
      <Box bg='gray.100' p='30px'>
        <Text fontSize={22} mb={5} fontWeight='600'>
          Orderwise Integrations
        </Text>
        <Tabs mb={10} orientation='horizontal' size='lg'>
          <TabList>
            <Tab>Create/Update Customers in OW</Tab>
            <Tab>Create Sales in OW</Tab>
            <Tab>XML Import</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Card minWidth='700px'>
                <Box width='50%' padding={5}>
                  <Main
                    start_date={start_date}
                    end_date={end_date}
                    submit={create_or_update_customers_in_ow}
                    onDateChangeStart={(e) => setStartDate(e)}
                    onDateChangeEnd={(e) => setEndDate(e)}
                    disable={!start_date || !end_date}
                    loading={loading}
                    type={3}
                  />
                </Box>
              </Card>
            </TabPanel>
            <TabPanel>
              <Card minWidth='700px'>
                <Box width='50%' padding={5}>
                  <Main
                    start_date={start_date_sales}
                    end_date={end_date_sales}
                    onDateChangeStart={(e) => setSalesDateStart(e)}
                    onDateChangeEnd={(e) => setSalesDateEnd(e)}
                    submit={create_sales_in_ow}
                    disable={!start_date_sales || !end_date_sales}
                    loading={loading}
                    type={4}
                    additional='sales'
                  />
                </Box>
              </Card>
            </TabPanel>
            <TabPanel>
              <Card minWidth='700px'>
                <Box width='50%' padding={5}>
                  <Text mb='1em'>Upload XML File</Text>
                  <Field
                    name='xml_file'
                    component='file'
                    uploadType='standard'
                    acceptedFile='text/xml'
                    endpoint='xml-upload'
                  />
                </Box>
                <Box width='100%'>
                  <Table
                    columns={[
                      {
                        Header: 'Name',
                        accessor: 'filename',
                      },
                      {
                        Header: 'Status',
                        accessor: 'document_status.name',
                        Cell: ({ value }) => (
                          <Tag
                            variant='solid'
                            variantColor={
                              value === 'Failed'
                                ? 'red'
                                : value === 'Pending'
                                ? 'blue'
                                : 'green'
                            }
                          >
                            {value}
                          </Tag>
                        ),
                      },
                      {
                        Header: 'Upload Date',
                        accessor: 'created_at',
                        Cell: ({ value }) =>
                          moment(value).format('DD/MM/YYYY hh:mm a'),
                      },
                      {
                        Header: 'Completed on',
                        accessor: 'completed_on',
                        Cell: ({ value }) =>
                          value
                            ? moment(value).format('DD/MM/YYYY hh:mm a')
                            : '-',
                      },
                      {
                        Header: 'Action',
                        accessor: 'id',
                        Cell: ({ value }) => (
                          <Box>
                            <PopConfirm
                              style={{ zIndex: '99999' }}
                              onConfirm={() => handleDeleteXmlFile(value)}
                              title='Are you sure you want to remove this file from the server?'
                            >
                              {' '}
                              <IconButton
                                variant='solid'
                                ml='5px'
                                icon={<FiTrash />}
                              />
                            </PopConfirm>
                          </Box>
                        ),
                      },
                    ]}
                    onChangePageSize={(e) => setPagination(e)}
                    onChangePage={(e) => setPage(e)}
                    data={xmlImports}
                    canSelect={false}
                    isExpandable={false}
                    size='sm'
                  ></Table>
                </Box>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </form>
  );
};

export default Content;
