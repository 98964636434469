import { Box, Flex, Text } from '@builtbypixel/plasma';
import React from 'react';

const OverallStats = ({ data }) => {
  return (
    <Flex width='100%' align='center' justify='space-evenly' mb='2em'>
      {data?.map((item) => {
        return (
          <Flex
            w='200px'
            direction='column'
            border='2px solid black'
            // bg='#cbd500'
            bg='#666666'
            color='white'
          >
            <Text textAlign='center' fontSize='1.4em'>
              {item.title}
            </Text>
            <Text textAlign='center' fontSize='2em' fontWeight='bold'>
              {item.count}
            </Text>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default OverallStats;
