import React, { memo } from 'react';
import { DatePicker } from '@builtbypixel/plasma';

const Date = memo((props) => {
  const { setActive, onChange, onBlur, value, ...rest } = props;

  return (
    <DatePicker
      onChange={(e) => onChange(e)}
      onBlur={() => {
        onBlur && onBlur();
        setActive && setActive(false);
      }}
      onFocus={() => setActive && setActive(true)}
      {...rest}
      value={value}
    />
  );
});

export default Date;
