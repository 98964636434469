import React, { useMemo, useState, useEffect } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';
import { useHttp } from '@builtbypixel/nucleus';
import setup from './setup';
import ReassignAttributes from './fields/ReassignAttributes';

const EditForm = () => {
  const [attributes, setAttrib] = useState(null);
  const [input, setInput] = useState(null);
  const Http = useHttp();

  const getAttributes = () =>
    Http.get(`/attributes`, {
      params: { search: input },
    }).then((res) => {
      setAttrib(res.data);
    });

  useEffect(() => {
    getAttributes();
  }, [input]);

  const fields = useMemo(
    () => [
      {
        group: 'Attribute Set Name',
        fields: [
          {
            name: 'name',
            component: 'text',
            placeholder: 'Name',
            label: 'Name',
            isRequired: true,
            rules: { required: true },
          },
        ],
      },
      {
        group: 'Groups',
        fields: [
          {
            name: 'groups',
            keyName: 'attset',
            component: 'repeater',
            columns: 1,
            label: 'Groups',
            rules: { required: true },
            fieldArray: [
              {
                name: 'title',
                component: 'text',
                placeholder: 'Title',
                label: 'Title',
                rules: { required: true },
              },
              {
                name: 'attributes',
                component: 'select',
                isMulti: true,
                placeholder: 'Attributes Name',
                label: 'Attributes Name',
                labelKey: 'name',
                valueKey: 'id',
                // options: attributes?.data,
                options: attributes?.data,
                rules: { required: true },
                onInputChange: (val) => {
                  setInput(val);
                },
              },
            ],
          },
        ],
      },
      {
        group: 'Assigned Products',
        fields: [
          {
            name: 'products',
            keyName: 'products',
            component: ReassignAttributes,
          },
        ],
      },
    ],
    [attributes]
  );

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
