import React, { useState } from 'react';
import {
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalButton,
  Table,
  Badge,
  Spinner,
} from '@builtbypixel/plasma';
import { useHttp } from '@builtbypixel/nucleus';
import { useEffect } from 'react';

const CustomerOrder = ({ customerEmail, totalOrderAmount, totalOrders }) => {
  const Http = useHttp();
  const [orders, setOrders] = useState();
  const [loading, setLoading] = useState();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);
      Http.get(`/customers/${customerEmail}/orders`)
        .then((res) => {
          setOrders(res.data);
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    }
  }, [isOpen]);

  const getStatusColour = (id) => {
    if (id === 5) {
      return 'grey';
    }
    if (id === 4) {
      return 'success';
    }
    if (id === 3) {
      return 'error';
    }
    if (id === 2) {
      return 'error';
    }
    if (id === 1) {
      return 'warning';
    }
  };

  const columns = [
    {
      Header: 'Order Number',
      accessor: 'id',
      Cell: ({ value }) => <Text>{value}</Text>,
    },
    {
      Header: 'Order Reference',
      accessor: 'id933',
      Cell: ({ row }) => {
        return <Text>{row.original.reference}</Text>;
      },
    },
    {
      Header: 'PO Number',
      accessor: 'purchase_order',
      Cell: ({ row }) =>
        row.original.purchase_order ? (
          <Text>{row.original.purchase_order}</Text>
        ) : (
          'N/A'
        ),
    },
    {
      Header: 'Total',
      accessor: 'total',
      Cell: ({ value }) => {
        return <Text>£{Number(value).toFixed(2)}</Text>;
      },
    },
    {
      Header: 'Order Status',
      accessor: 'order_status',
      Cell: ({ value }) => (
        <Badge variantColor={getStatusColour(value.id)}>{value.name}</Badge>
      ),
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
    },
    {
      Header: 'Orders',
      accessor: '-',
      Cell: ({ row }) => (
        <Button
          variant='link'
          variantColor='secondary'
          rounded='md'
          onClick={() =>
            window.open(`/orders/view/${row.original.id}`, '_blank')
          }
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <>
      <Modal size='xl' onClose={() => setIsOpen(false)}>
        <ModalButton>
          <Button
            variant='link'
            variantColor='secondary'
            rounded='md'
            onClick={() => setIsOpen(true)}
          >
            View
          </Button>
        </ModalButton>
        <ModalContent>
          <ModalHeader>
            <Text ml='10px' fontWeight='normal'>
              Customer Orders
            </Text>
          </ModalHeader>
          <ModalBody>
            <Flex p='2em'>
              {loading ? (
                <Spinner />
              ) : orders?.data?.length > 0 ? (
                <Flex direction='column' w='100%'>
                  <Flex
                    width='100%'
                    align='center'
                    justify='space-evenly'
                    mb='2em'
                  >
                    <Flex
                      w='200px'
                      direction='column'
                      border='2px solid black'
                      // bg='#cbd500'
                      bg='#666666'
                      color='white'
                    >
                      <Text textAlign='center' fontSize='1.4em'>
                        Total Orders
                      </Text>
                      <Text textAlign='center' fontSize='2em' fontWeight='bold'>
                        {totalOrders}
                      </Text>
                    </Flex>
                    <Flex
                      w='200px'
                      direction='column'
                      border='2px solid black'
                      // bg='#cbd500'
                      bg='#666666'
                      color='white'
                    >
                      <Text textAlign='center' fontSize='1.4em'>
                        Total Order Amount
                      </Text>
                      <Text textAlign='center' fontSize='2em' fontWeight='bold'>
                        £{totalOrderAmount}
                      </Text>
                    </Flex>
                  </Flex>
                  <Table
                    columns={columns}
                    data={orders}
                    isExpandable={false}
                    hidePagination={true}
                    loading={loading}
                  />
                </Flex>
              ) : (
                <Text>No Orders Have Been Placed By The Customer</Text>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomerOrder;
