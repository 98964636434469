import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';

import setup from './setup';

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: 'Banner Contents',
        fields: [
          {
            name: 'content.title',
            label: 'Banner Title',
            component: 'text',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.body',
            component: 'richtext',
            label: 'Banner Text',
            // isRequired: true,
            // rules: { required: true },
          },
          {
            name: 'content.background',
            component: 'colour',
            label: 'Banner Background',
            // isRequired: true,
            // rules: { required: true },
          },
          {
            name: 'content.fontColour',
            component: 'colour',
            label: 'Banner Font Colour',
            // isRequired: true,
            // rules: { required: true },
          },
          {
            name: 'content.active',
            component: 'switch',
            label: 'Active',
          },
          {
            name: 'content.countdown_date',
            component: 'dateTime',
            placeholder: 'Set Countdown Timer',
            label: 'Countdown Timer',
          },
          {
            name: 'content.countdown_active',
            component: 'switch',
            label: 'Countdown Timer Active',
            defaultValue: false,
          },
        ],
      },
    ],
    []
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
