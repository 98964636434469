import React, { useState, useEffect, useContext } from 'react';
import { Flex, Box, Text, Table, Image } from '@builtbypixel/plasma';
import PageContainer from '../Components/PageContainer';
import { customerPreferences } from '../Api';
import { useHttp } from '@builtbypixel/nucleus';
import Loader from '../Components/Loader';
import { HiCheck, HiOutlineX } from 'react-icons/hi';
import { useWatch } from 'react-hook-form';
import OverallStats from '../Components/OverallStats';
import CustomTable from '../Components/CustomTable';

const CustomerPreferences = ({ setCurrentPage, currentPage }) => {
  const [customerRes, setCustomerRes] = useState([]);
  const [prefCount, setPrefCount] = useState([]);
  const [loading, setLoading] = useState(false);
  const Http = useHttp();

  const fetchData = (
    dateFrom = null,
    dateTo = null,
    pagination = 20,
    page = 1,
    preferenceFilter = null
  ) => {
    setLoading(true);
    if (preferenceFilter) {
      for (let key in preferenceFilter) {
        if (preferenceFilter[key] === false) delete preferenceFilter[key];
      }
    }
    Http.get(customerPreferences, {
      params: preferenceFilter
        ? {
            from_date: dateFrom,
            to_date: dateTo,
            pagination,
            page,
            ...preferenceFilter,
          }
        : {
            from_date: dateFrom,
            to_date: dateTo,
            pagination,
            page,
          },
    }).then((res) => {
      setCustomerRes(res.data);
      setLoading(false);
    });

    Http.get('reports/dashboard/preferences-count', {
      params: { from_date: dateFrom, to_date: dateTo },
    })
      .then((res) => {
        setPrefCount(res.data);
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      Header: 'Customer name',
      accessor: 'full_name',
    },
    {
      Header: 'Customer Email',
      accessor: 'User_email',
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }) => {
        return (
          <Flex width='100%' jusstify='center' ml='0.5em'>
            {value === true ? (
              <HiCheck color='green' fontSize='1.5em' />
            ) : (
              <HiOutlineX color='red' fontSize='1.5em' />
            )}
          </Flex>
        );
      },
    },
    {
      Header: 'Post',
      accessor: 'post',
      Cell: ({ value }) => {
        return (
          <Flex width='100%' jusstify='center' ml='0.5em'>
            {value === true ? (
              <HiCheck color='green' fontSize='1.5em' />
            ) : (
              <HiOutlineX color='red' fontSize='1.5em' />
            )}
          </Flex>
        );
      },
    },
    {
      Header: 'Phone',
      accessor: 'telephone',
      Cell: ({ value }) => {
        return (
          <Flex width='100%' jusstify='center' ml='0.5em'>
            {value === true ? (
              <HiCheck color='green' fontSize='1.5em' />
            ) : (
              <HiOutlineX color='red' fontSize='1.5em' />
            )}
          </Flex>
        );
      },
    },
    {
      Header: 'SMS',
      accessor: 'text_message',
      Cell: ({ value }) => {
        return (
          <Flex width='100%' jusstify='center' ml='0.5em'>
            {value === true ? (
              <HiCheck color='green' fontSize='1.5em' />
            ) : (
              <HiOutlineX color='red' fontSize='1.5em' />
            )}
          </Flex>
        );
      },
    },
  ];

  const customExport = () => {
    return Http.get('/export-customer-preferences');
  };
  return (
    <PageContainer
      title='Customer Preferences'
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      endpoint={customerPreferences}
      fetchData={fetchData}
      data={customerRes?.data}
      excelFields={[
        'full_name',
        'User_email',
        'email',
        'post',
        'telephone',
        'text_message',
      ]}
      excelSecondTable={prefCount}
      excelSecondTableFields={['title', 'count']}
      toolbarFilters={['preference']}
      customExport={customExport}
    >
      <OverallStats data={prefCount} />
      {loading ? (
        <Loader />
      ) : (
        customerRes?.data?.length > 0 && (
          <CustomTable
            data={customerRes}
            columns={columns}
            fetchData={fetchData}
          />
        )
      )}
    </PageContainer>
  );
};

export default CustomerPreferences;
