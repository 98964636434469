import styled from '@emotion/styled';

export const Styles = styled.div`
    table {
        border-spacing: 0;
        border: 1px solid #e2e8f0;
        width: 100%;
        font-size: 0.9em;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        tr:hover td {
            background: rgba(0, 0, 0, 0.04);

            transition: transform 0.2s;
        }

        th,
        td {
            margin: 0;
            padding: 0.3rem;
            border-bottom: 1px solid #e2e8f0;
            border-right: 1px solid #e2e8f0;

            :last-child {
                border-right: 0;
            }
        }
    }
`;
