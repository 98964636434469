import React, { memo } from 'react'
import { Stack, Text, PopConfirm, Button, Box } from '@builtbypixel/plasma'
import { useFormContext } from 'react-hook-form'
import { FiTrash } from 'react-icons/fi'
import { BiAddToQueue } from 'react-icons/bi'
import { useParams } from 'react-router-dom'
import LinkButton from '../LinkButton'

const MoreOptions = memo(({ setup }) => {
   const { reset } = useFormContext()
   const params = useParams()

   return (
      <Stack isInline spacing='4px' mr='10px'>
         <Box>
            <PopConfirm
               onConfirm={() => {
                  reset({})
               }}
               title='Are you sure you want to clear the form values?'
            >
               <Button variant='ghost' leftIcon={<FiTrash />}>
                  <Text
                     display={{ xs: 'none', sm: 'inline-block' }}
                     fontWeight='semibold'
                  >
                     Clear Form
                  </Text>
               </Button>
            </PopConfirm>
         </Box>

         <Box>
            {params.type !== 'create' && setup.canAddNew && (
               <LinkButton to={`/${setup.model}/create`}>
                  <Button variant='ghost' leftIcon={<BiAddToQueue />}>
                     <Text display={{ xs: 'none', sm: 'inline-block' }}>
                        Create New
                     </Text>
                  </Button>
               </LinkButton>
            )}
         </Box>
      </Stack>
   )
})

export default MoreOptions
