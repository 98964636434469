import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Table } from '@builtbypixel/plasma';
import PageContainer from '../Components/PageContainer';
import { discountCodes } from '../Api';
import { useHttp } from '@builtbypixel/nucleus';
import Loader from '../Components/Loader';
import CustomTable from '../Components/CustomTable';

const DiscountCodes = ({ setCurrentPage, currentPage }) => {
  const [discountCodesRes, setDiscountCodesRes] = useState([]);
  const [loading, setLoading] = useState(false);
  const Http = useHttp();

  const fetchData = (
    dateFrom = null,
    dateTo = null,
    pagination = 20,
    page = 1
  ) => {
    setLoading(true);
    Http.get(discountCodes, {
      params: { from_date: dateFrom, to_date: dateTo, pagination, page },
    }).then((res) => {
      setDiscountCodesRes(res.data);
      setLoading(false);
    });
  };

  const columns = [
    {
      Header: 'Discount Code  ',
      accessor: 'title',
    },
    {
      Header: 'Count',
      accessor: 'count',
    },
  ];

  return (
    <PageContainer
      title='Discount Codes'
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      endpoint={discountCodes}
      fetchData={fetchData}
      data={discountCodesRes?.data}
      excelFields={['title', 'count']}
    >
      {loading && <Loader />}
      {discountCodesRes?.data?.length > 0 && (
        <CustomTable data={discountCodesRes} columns={columns} />
      )}
    </PageContainer>
  );
};

export default DiscountCodes;
