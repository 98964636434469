import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Table } from '@builtbypixel/plasma';
import PageContainer from '../Components/PageContainer';
import { poa } from '../Api';
import { useHttp } from '@builtbypixel/nucleus';
import Loader from '../Components/Loader';
import CustomTable from '../Components/CustomTable';

const POA = ({ currentPage, setCurrentPage }) => {
  const [poaRes, setPoaRes] = useState([]);
  const [loading, setLoading] = useState(false);
  const Http = useHttp();

  const fetchData = (dateFrom = null, dateTo = null) => {
    setLoading(true);
    Http.get(poa, { params: { from_date: dateFrom, to_date: dateTo } }).then(
      (res) => {
        setPoaRes(res.data);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      Header: 'Product',
      accessor: 'name',
    },
    {
      Header: 'Requests',
      accessor: 'total',
    },
  ];

  return (
    <PageContainer
      title='POA'
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      endpoint={poa}
      fetchData={fetchData}
      data={poaRes?.data}
      excelFields={['name', 'total']}
    >
      {loading && <Loader />}
      {poaRes?.data?.length > 0 && (
        <CustomTable data={poaRes} columns={columns} />
      )}
    </PageContainer>
  );
};

export default POA;
