import React, { useContext, useEffect, useState } from 'react'
import {
   Button,
} from '@builtbypixel/plasma'


const SubmitButton = (props) => (
    <Button
        variantColor='primary'
        size='sm'
        variant='outline'
        mt={5}
        isLoading={props.loading}
        px='20px'
        onClick={props.submit}
        isDisabled={props.disable}
    >
       {props.title}
    </Button>
);

export default SubmitButton;