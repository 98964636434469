import React, { useState } from 'react';
import { Flex, Text, PseudoBox } from '@builtbypixel/plasma';
import CEPriceTable from './CEPriceTable';

const CEProductsView = ({ data, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <PseudoBox
        _hover={{
          bg: '#cbd500',
          borderColor: '#abb400',
          color: 'black',
          transform: 'translateY(-5px)',
          boxShadow: '0px 5px 10px rgba(0,0,0, 0.4)',
        }}
        _active={{
          bg: '#abb400',
        }}
        as='button'
        bg='#3C3C3C'
        borderColor='transparent'
        borderWidth='2px'
        color='white'
        px={4}
        py={2}
        width='100%'
        rounded='lg'
        transition='all 0.3s ease'
        fontWeight='bold'
        fontSize={{ xs: '0.7em', md: '1em', lg: '1em' }}
        onClick={() => setOpen(!open)}
      >
        {open ? 'Hide Engravings' : 'Show Engravings'}
      </PseudoBox>

      {open && (
        <Flex width='100%' height='300px' p='1em' direction='column'>
          <Flex>
            <Text
              textTransform='uppercase'
              letterSpacing='-1px'
              fontSize='0.9em'
            >
              First Line Text:
            </Text>
            <Text
              textTransform='uppercase'
              letterSpacing='-1px'
              fontSize='0.9em'
              ml='1em'
            >
              {data.value}
            </Text>
          </Flex>

          <Flex>
            <Text
              textTransform='uppercase'
              letterSpacing='-1px'
              fontSize='0.9em'
            >
              First Line Characters:
            </Text>
            <Text
              textTransform='uppercase'
              letterSpacing='-1px'
              fontSize='0.9em'
              ml='1em'
            >
              {data.value.replace(/\s+/g, '').length}
            </Text>
          </Flex>
          <Flex
            direction='column'
            overflow='scroll'
            overflowY='scroll'
            mt=' 2em'
          >
            <Text mr='2em' fontWeight='bold' fontSize='1.1em'>
              Engraved Products
            </Text>
            <CEPriceTable data={data}></CEPriceTable>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default CEProductsView;
