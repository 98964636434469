import React, { useState, useEffect } from 'react';
import { useHttp } from '@builtbypixel/nucleus';
import { useFormContext } from 'react-hook-form';
import { Flex, Icon, Checkbox, Text, Badge } from '@builtbypixel/plasma';

import { MdChevronRight } from 'react-icons/md';

const Cat = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const Http = useHttp();
  const { setValue, watch } = useFormContext();

  const value = watch('categories');
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (categoryList.length === 0) {
      Http.get('/categories?pagination=50')
        .then((res) => {
          setCategoryList(res.data.data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (value) {
      setSelectedCategories(value);
    }
  }, [value]);

  const toggleCategory = (cat) => {
    let _selected = [...value];
    const index = _selected.findIndex((e) => e.id === cat.id);
    if (index === -1) {
      _selected.push(cat);
    } else {
      _selected.splice(index, 1);
    }
    setSelectedCategories(_selected);
    setValue('categories', _selected);
  };

  const isSelected = (id) => {
    return selectedCategories.some((e) => e.id === id);
  };

  const hasChildrenSelected = (index) => {
    const parent = categoryList[index];
    let found = [];
    parent.subRows.forEach((row) => {
      const matched = selectedCategories.find((e) => e.id === row.id);
      if (matched) {
        found.push(matched);
      }
    });

    return found;
  };

  return (
    <>
      <Flex>
        <Flex
          direction='column'
          w='300px'
          borderRight='1px'
          borderColor='global.borderColour'
          pr='15px'
        >
          {categoryList &&
            categoryList.map((category, key) => (
              <Flex
                w='100%'
                py='10px'
                key={`category-${key}`}
                align='center'
                borderBottom='1px'
                borderColor='global.borderColour'
                onClick={() => setSelected(key)}
                fontWeight={selected === key ? 'bold' : 'normal'}
              >
                <Text> {category.name}</Text>
                <Icon fontSize='18px' ml='auto'>
                  <MdChevronRight />
                </Icon>
                <Badge
                  variant={
                    hasChildrenSelected(key).length !== 0 ? 'solid' : 'subtle'
                  }
                  variantColor={
                    hasChildrenSelected(key).length !== 0
                      ? 'success'
                      : 'primary'
                  }
                >
                  {hasChildrenSelected(key).length}
                </Badge>
              </Flex>
            ))}
        </Flex>
        <Flex w='100%' direction='column' pl='15px'>
          <Text fontWeight='semibold' fontSize='1.3em' mb='25px'>
            {categoryList[selected]?.name}
          </Text>
          {categoryList &&
            categoryList[selected] &&
            categoryList[selected].subRows.map((subcategory, key) => (
              <Flex key={`subcategory-${key}`} as='label' py='6px'>
                <Checkbox
                  checked={isSelected(subcategory.id)}
                  label={subcategory.name}
                  onChange={() => toggleCategory(subcategory)}
                />
              </Flex>
            ))}
        </Flex>
      </Flex>
    </>
  );
};

export default Cat;
