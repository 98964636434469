import { AutoFields } from '@builtbypixel/nucleus';
import React from 'react';

import { useWatch } from 'react-hook-form';

import AssociatedProducts from './AssociatedProducts';
import configProductsField from './configProductsField';

import Categories from './Categories';

import CustomOptionsFields from './CustomOptionsFields';
import BundleItems from './BundleItems';

const DynamicFields = ({ fields, productTypes, attributeTypes }) => {
    const productType = useWatch({ name: 'product_type' });

    const usedProductType = productType;

    return (
        <AutoFields
            isFullWidth
            auto={false}
            fields={[
                ...fields,
                {
                    group: 'Categories',
                    tab: 'Categories',
                    auto: false,
                    fields: [
                        {
                            name: 'categories',
                            component: Categories,
                            rules: {
                                validate: {
                                  minLength: (v) => v.length > 0,
                                },
                            }
                        },
                    ],
                },
                {
                    group: 'Associated Products',
                    tab: 'Associated Products',
                    component: <AssociatedProducts />,
                    fields: [],
                },
                ...configProductsField(
                    usedProductType,
                    productTypes,
                    attributeTypes
                ),
                {
                    group:
                        productType && productType.id === 2
                            ? 'Grouped Products'
                            : 'Bundled Items',
                    tab:
                        productType && productType.id === 2
                            ? 'Grouped Products'
                            : 'Bundled Items',
                    component: <BundleItems />,
                    fields: [],
                },
                {
                    group: 'Customisable Options',
                    tab: 'Customisable Options',
                    component: <CustomOptionsFields />,
                    fields: [],
                },
            ]}
        />
    );
};

export default DynamicFields;
