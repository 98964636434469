import React, { useMemo } from 'react';
import { EditView, useGet, Field } from '@builtbypixel/nucleus';
import useSWR from 'swr';
import { Flex, Box, Grid, Text, Table, Card } from '@builtbypixel/plasma';
import OrderLinesTable from './components/OrderLinesTables';
import setup from './setup';

const Header = ({ text }) => {
    return (
        <Text mb='25px' fontSize={22} fontWeight={600}>
            {text}
        </Text>
    );
};

const EditForm = () => {
    const { data: statusTypes } = useSWR('/order-statuses', useGet);

    return (
        <EditView setup={setup}>
            <Box p='10px' width='100%'>
                <Grid
                    templateColumns='repeat(auto-fit, minmax(40%, 1fr))'
                    justifyContent='center'
                    gap={8}
                >
                    {/* Order Details */}
                    <Card my='10px' p='10px'>
                        <Box mb='20px' rounded='lg' p='10px' width='100%'>
                            <Header text='Order Details' />
                            <Field
                                label='Reference'
                                name='order.reference'
                                component='text'
                            />
                            <Field
                                label='Status'
                                component='select'
                                labelKey='name'
                                valueKey='id'
                                name='order.order_status'
                                options={statusTypes?.data}
                            />
                        </Box>
                    </Card>

                    {/* Customer Details */}
                    <Card my='10px' p='10px'>
                        <Box mb='20px' rounded='lg' p='10px' width='100%'>
                            <Header text='Customer Details' />
                            <Field
                                label='Name'
                                name='customer.full_name'
                                component='text'
                                placeholder='Enter a group title, eg Ribbons...'
                            />
                            <Field
                                label='Email'
                                name='customer.email'
                                component='text'
                                placeholder='Enter a group title, eg Ribbons...'
                            />
                            <Field
                                label='Company'
                                name='customer.company'
                                component='text'
                                placeholder='Enter a group title, eg Ribbons...'
                            />
                            <Field
                                label='Phone'
                                name='customer.phone_number'
                                component='text'
                                placeholder='Enter a group title, eg Ribbons...'
                            />
                        </Box>
                    </Card>

                    {/* Delivery Details */}
                    <Card my='10px' p='10px'>
                        <Box mb='20px' rounded='lg' p='10px' width='100%'>
                            <Header text='Delivery Details' />
                            <Field
                                label='Address Line One'
                                name='delivery.delivery_address.address_line_one'
                                component='text'
                            />
                            <Field
                                label='Address Line Two'
                                name='delivery.delivery_address.address_line_two'
                                component='text'
                            />
                            <Field
                                label='City'
                                name='delivery.delivery_address.city'
                                component='text'
                            />
                            <Field
                                label='Postcode'
                                name='delivery.delivery_address.postcode'
                                component='text'
                            />
                        </Box>
                    </Card>

                    {/* Billing Details */}
                    <Card my='10px' p='10px'>
                        <Box mb='20px' rounded='lg' p='10px' width='100%'>
                            <Header text='Billing Details' />
                            <Field
                                label='Address Line One'
                                name='billing.billing_address.address_line_one'
                                component='text'
                            />
                            <Field
                                label='Address Line Two'
                                name='billing.billing_address.address_line_two'
                                component='text'
                            />
                            <Field
                                label='City'
                                name='billing.billing_address.city'
                                component='text'
                            />
                            <Field
                                label='Postcode'
                                name='billing.billing_address.postcode'
                                component='text'
                            />
                        </Box>
                    </Card>

                    {/* Payment Details */}
                    <Card my='10px' p='10px'>
                        <Box mb='20px' rounded='lg' p='10px' width='100%'>
                            <Header text='Payment Details' />
                            <Field
                                label='Payment Method'
                                name='payment.payment_method.name'
                                component='text'
                            />
                            <Field
                                label='Transaction ID'
                                name='payment.transaction.transaction_reference_id'
                                component='text'
                            />
                            <Field
                                label='Sub Total'
                                name='payment.price'
                                component='text'
                                type='number'
                            />
                            <Field
                                label='Delivery Amount'
                                name='payment.delivery_amount'
                                component='text'
                                type='number'
                            />
                            <Field
                                label='Delivery'
                                name='payment.delivery_method.name'
                                component='text'
                                type='number'
                            />
                            <Field
                                label='Total'
                                name='payment.total'
                                component='text'
                                type='number'
                            />
                        </Box>
                    </Card>
                </Grid>
            </Box>

            <OrderLinesTable Header={Header} />
        </EditView>
    );
};

export default EditForm;
