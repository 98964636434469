import React, { useCallback, useContext, useState } from 'react';
import { Table } from '@builtbypixel/plasma';
import ReportsContext from '../../context';

const CustomTable = (props) => {
  const { onChangePageSize, onChangePage } = useContext(ReportsContext);
  return (
    <>
      <Table
        accessor={'id'}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        {...props}
      />
    </>
  );
};

export default CustomTable;
