import React from 'react'
import { ThemeProvider, Flex } from '@builtbypixel/plasma'
import Kernel from './Kernel'
import { theme } from './theme'
import { SWRConfig } from 'swr'
import { RecoilRoot } from 'recoil'
import merge from 'lodash.merge'
import { CloudinaryContext } from 'cloudinary-react'

const NucleusCore = (props) => {
   const { routes, config, links, theme: customTheme } = props

   const mergedTheme = merge(theme, customTheme)

   return (
      <RecoilRoot>
         <ThemeProvider override={mergedTheme}>
            <SWRConfig
               value={{
                  refreshInterval: 0,
                  revalidateOnFocus: false,
                  shouldRetryOnError: false
               }}
            >
               <CloudinaryContext
                  cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
               >
                  <Kernel config={config} routes={routes} links={links} />
               </CloudinaryContext>
            </SWRConfig>
         </ThemeProvider>
      </RecoilRoot>
   )
}

export default NucleusCore
