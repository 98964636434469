import {
  Box,
  Card,
  Flex,
  Text,
  Spinner,
  Badge,
  Button,
  Divider,
  Icon,
  Select,
  Modal,
  ModalHeader,
  ModalContent,
  ModalButton,
  ModalBody,
  ModalFooter,
  useColorMode,
  Table,
} from '@builtbypixel/plasma';
import React, { useEffect, useState } from 'react';
import {
  LinkButton,
  useGet,
  EditView,
  Field,
  useHttp,
} from '@builtbypixel/nucleus';
import useSWR from 'swr';
import { useParams } from 'react-router';
import OrderLinesTable from './components/OrderLinesTables';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import dayjs from 'dayjs';
import { useForm, useWatch } from 'react-hook-form';
import moment from 'moment';
import { BsArrowReturnRight } from 'react-icons/bs';
import { GrDocumentPdf } from 'react-icons/gr';

const SectionBlock = ({ children, title }) => (
  <Flex
    direction='column'
    p='20px'
    borderBottom='1px'
    borderColor='global.borderColour'
  >
    {title && (
      <Text
        fontWeight='semibold'
        mb='15px'
        textTransform='uppercase'
        fontSize='0.85em'
      >
        {title}
      </Text>
    )}
    {children}
  </Flex>
);

const OrderView = ({ order }) => {
  const { id: orderId } = useParams();
  const [data, setData] = useState(null);
  const [downloadURL, setDownloadURL] = useState(null);
  const [status, setStatus] = useState(null);
  // const { data: order } = useSWR(`/orders/${id}`, useGet);
  const { data: statusTypes } = useSWR('/order-statuses', useGet);

  const { setValue } = useForm();
  const { colorMode } = useColorMode();
  const Http = useHttp();

  useEffect(() => {
    if (order) {
      setData(order.data);
    }
  }, [order]);

  const getStatusColour = (id) => {
    if (id === 5) {
      return 'grey';
    }
    if (id === 4) {
      return 'success';
    }
    if (id === 3) {
      return 'error';
    }
    if (id === 2) {
      return 'error';
    }
    if (id === 1) {
      return 'warning';
    }
  };

  const downloadOrder = () => {
    Http.get(`/download_order/${orderId}`).then((res) => {
      let url = res.data;
      setDownloadURL(url);
    });
  };

  useEffect(() => {
    setDownloadURL(null);
  }, [orderId]);

  return (
    <Box w='100%' p='20px'>
      <LinkButton to='/orders'>
        <Button mb='25px' variant='ghost' leftIcon={<MdKeyboardArrowLeft />}>
          All Orders
        </Button>
      </LinkButton>
      {!data ? (
        <Spinner />
      ) : (
        <Flex w='100%'>
          <Flex direction='column' w='100%' mr='20px'>
            <Card>
              <Flex
                align='center'
                justify='space-between'
                p='20px'
                borderBottom='1px'
                borderColor='global.borderColour'
              >
                <Flex direction='column'>
                  <Text fontSize='1.2em' fontWeight='semibold'>
                    Order Number: {data.order.id}
                  </Text>
                  <Text fontSize='1.2em' fontWeight='semibold'>
                    Order Reference: #{data.order.reference}
                  </Text>
                  {data.order.orderwise_id && (
                    <Text fontSize='1.2em' fontWeight='semibold'>
                      OrderWise ID: {data.order.orderwise_id}
                    </Text>
                  )}
                </Flex>
                <Flex>
                  {data.purchase_order ? (
                    <Flex align='center'>
                      <Text mx='1em'>Status</Text>
                      <Field
                        name='order.order_status'
                        component='select'
                        labelKey='name'
                        valueKey='id'
                        options={statusTypes?.data}
                      ></Field>
                    </Flex>
                  ) : (
                    <Badge
                      ml='auto'
                      fontSize='1.1em'
                      px='10px'
                      fontWeight='semibold'
                      variantColor={getStatusColour(data.order.order_status.id)}
                    >
                      {data.order.order_status.name}
                    </Badge>
                  )}
                </Flex>
              </Flex>
              <Flex p='20px' direction='column'>
                <Text fontWeight='semibold' fontSize='1.1em' mb='20px'>
                  Ordered Items ({data.order_lines.length})
                </Text>
                <OrderLinesTable data={data.order_lines} />
              </Flex>
            </Card>
            <Card title='Payment' mt='20px'>
              {data.payment.transaction ? (
                <>
                  {colorMode === 'dark' && <Divider />}
                  <Flex align='center' px='10px' py='10px'>
                    <Text fontWeight='semibold'>Date</Text>
                    <Text ml='auto'>
                      {dayjs(data.payment.transaction.created_at).format(
                        'DD/MM/YYYY - HH:mm'
                      )}
                    </Text>
                  </Flex>
                  <Flex align='center' px='10px' py='10px'>
                    <Text fontWeight='semibold'>Event Name</Text>
                    <Text ml='auto'>
                      {data.delivery.event ? data.delivery.event : 'N/A'}
                    </Text>
                  </Flex>
                  <Flex
                    align='center'
                    justify='space-between'
                    px='10px'
                    py='10px'
                  >
                    <Text fontWeight='semibold'>Method</Text>
                    {data.purchase_order ? (
                      <Box width='40%'>
                        <Field
                          name='payment.payment_method'
                          component='select'
                          labelKey='name'
                          valueKey='id'
                          options={[
                            { id: 1, name: 'Card Payment' },
                            { id: 2, name: 'Other' },
                          ]}
                        ></Field>
                      </Box>
                    ) : (
                      <Text ml='auto'>{data.payment.payment_method.name}</Text>
                    )}
                  </Flex>

                  {data.purchase_order ? (
                    <Flex
                      align='center'
                      justify='space-between'
                      px='10px'
                      py='10px'
                      w='100%'
                    >
                      <Text fontWeight='semibold'>Amount Paid</Text>
                      <Box width='40%'>
                        <Field
                          name='payment.amount_paid'
                          placeholder='£0.00'
                          component='text'
                        ></Field>
                      </Box>
                    </Flex>
                  ) : null}

                  {data.purchase_order ? (
                    <Flex
                      align='center'
                      justify='space-between'
                      px='10px'
                      py='10px'
                    >
                      <Text fontWeight='semibold'>Date Paid</Text>
                      <Box width='40%'>
                        <Field
                          name='payment.date_paid'
                          component='date'
                        ></Field>
                      </Box>
                    </Flex>
                  ) : null}

                  <Divider />

                  <Flex align='center' px='10px' py='10px'>
                    <Text fontWeight='semibold'>Delivery</Text>
                    <Text ml='auto'>
                      {data.courier_name} - £{data.delivery_option_price}
                    </Text>
                  </Flex>
                  <Flex align='center' px='10px' py='10px'>
                    <Text fontWeight='semibold'>Delivery Type</Text>

                    <Text ml='auto'>{data.delivery_option}</Text>
                  </Flex>

                  <Flex align='center' px='10px' py='10px'>
                    <Text fontWeight='semibold'>Required By</Text>
                    <Text ml='auto'>
                      {moment(data.delivery?.required_by).format('DD/MM/YYYY')}
                    </Text>
                  </Flex>

                  <Divider />
                  <Flex align='center' px='10px' py='10px'>
                    <Text fontWeight='semibold'>Minimum Order</Text>
                    <Text ml='auto'>
                      £{Number(data.payment.minimum_order).toFixed(2)}
                    </Text>
                  </Flex>
                  <Flex align='center' px='10px' py='10px'>
                    <Text fontWeight='semibold'>Minimum Order Fee</Text>

                    <Text ml='auto'>
                      £{Number(data.payment.minimum_order_gap).toFixed(2)}
                    </Text>
                  </Flex>

                  <Divider />

                  <Flex align='center' px='10px' py='10px'>
                    <Text fontWeight='semibold'>Price (ex VAT)</Text>
                    <Text ml='auto'>
                      £{Number(data.payment.price).toFixed(2)}
                    </Text>
                  </Flex>

                  <Flex align='center' px='10px' py='10px'>
                    <Text fontWeight='semibold'>Total VAT</Text>
                    <Text ml='auto'>
                      £{Number(data.payment.total_vat).toFixed(2)}
                    </Text>
                  </Flex>
                  <Flex align='center' px='10px' py='10px'>
                    <Icon ml='1em' fontSize='24px'>
                      <BsArrowReturnRight />
                    </Icon>
                    <Text fontWeight='semibold' ml='1em'>
                      Product VAT
                    </Text>
                    <Text ml='auto'>
                      £{Number(data.payment.product_vat).toFixed(2)}
                    </Text>
                  </Flex>
                  {data.delivery?.delivery_address?.delivery_option?.vat ? (
                    <Flex align='center' px='10px' py='10px'>
                      <Icon ml='1em' fontSize='24px'>
                        <BsArrowReturnRight />
                      </Icon>
                      <Text fontWeight='semibold' ml='1em'>
                        Delivery VAT
                      </Text>

                      <Text ml='auto'>
                        £
                        {Number(
                          data.delivery.delivery_address.delivery_option.vat
                        ).toFixed(2)}
                      </Text>
                    </Flex>
                  ) : null}
                  <Divider />
                  <Flex align='center' px='10px' py='10px'>
                    <Text fontWeight='semibold'>Surcharge Total</Text>
                    <Text ml='auto'>
                      £{Number(data.payment.total_surcharge).toFixed(2)}
                    </Text>
                  </Flex>
                  <Divider />
                  <Flex align='center' px='10px' py='10px'>
                    <Text fontWeight='semibold'>Setup Cost Total</Text>
                    <Text ml='auto'>
                      £{Number(data.payment.total_setup).toFixed(2)}
                    </Text>
                  </Flex>
                  <Divider />
                  <Flex align='center' px='10px' py='10px'>
                    <Text fontWeight='semibold'>Amount</Text>
                    <Text ml='auto'>
                      <Badge fontSize='1.2em' px='15px' fontWeight='semibold'>
                        £{data.payment.total.toFixed(2)}
                      </Badge>
                    </Text>
                  </Flex>
                </>
              ) : (
                <Flex p='15px'>No transactions available for this order</Flex>
              )}
            </Card>
          </Flex>
          <Flex
            direction='column'
            justify='flex-start'
            align='flex-start'
            w='400px'
            h='auto'
          >
            <Card title='Customer' h='auto'>
              <SectionBlock>
                {data.purchase_order && (
                  <Text>PO Number: {data.purchase_order}</Text>
                )}
              </SectionBlock>
              <SectionBlock>
                {data.customer.full_name}
                <br />
                {data.customer.company}
                {data.source && <Text>Referred by: {data.source.name}</Text>}
              </SectionBlock>
              <SectionBlock title='Contact Information'>
                <Button
                  variant='link'
                  align='left'
                  as='a'
                  href={`mailto: ${data.customer.email}`}
                  fontWeight='normal'
                  variantColor='secondary'
                >
                  {data.customer.email}
                </Button>
                <br />
                {data.customer.phone_number}
              </SectionBlock>
              <SectionBlock title='Delivery Address'>
                <Text fontSize='0.9em'>
                  {data.delivery.delivery_address.address_line_one}
                  <br />
                  {data.delivery.delivery_address.address_line_two}
                  <br />
                  {data.delivery.delivery_address.city}
                  <br />
                  {data.delivery.delivery_address.postcode}
                  <br />
                  {data?.delivery?.delivery_address?.country?.name}
                  <br />
                  {data?.delivery?.delivery_address?.region?.name}
                </Text>
              </SectionBlock>
              <SectionBlock title='Billing Address'>
                {data.billing.billing_address.same_as_delivery ? (
                  <Text>Same as delivery</Text>
                ) : (
                  <Text fontSize='0.9em'>
                    {data.billing.billing_address.address_line_one}
                    <br />
                    {data.billing.billing_address.address_line_two}
                    <br />
                    {data.billing.billing_address.city}
                    <br />
                    {data.billing.billing_address.postcode}
                    <br />
                    {data?.billing?.billing_address?.country?.name}
                    <br />
                    {data?.billing?.billing_address?.region?.name}
                  </Text>
                )}
              </SectionBlock>
              <SectionBlock>
                <Button
                  size='lg'
                  isFullWidth
                  variantColor='primary'
                  onClick={() => downloadOrder()}
                >
                  Generate Download Link
                </Button>
                {downloadURL && (
                  <a href={downloadURL} target='_blank'>
                    <Flex justify='center' align='center' mt='2em'>
                      <Icon>
                        <GrDocumentPdf />
                      </Icon>
                      <Text ml='0.5em'>Download Order</Text>
                    </Flex>
                  </a>
                )}
              </SectionBlock>

              {data?.orderwise_logs?.length > 0 && (
                <SectionBlock>
                  <Modal size='xl'>
                    <ModalButton>
                      <Button
                        // variantColor='secondary'
                        // variant='outline'
                        // size='sm'
                        size='lg'
                        isFullWidth
                        variantColor='secondary'
                      >
                        View Notes
                      </Button>
                    </ModalButton>
                    <ModalContent>
                      <ModalHeader>
                        <Flex width='100%'>
                          <Text
                            fontSize='1.2em'
                            fontWeight='semibold'
                            px='2rem'
                            whiteSpace='noWrap'
                          >
                            Order Notes
                          </Text>
                          <Flex
                            direction='column'
                            align='flex-end'
                            width='100%'
                            px='2rem'
                          >
                            <Text fontSize='0.75em' fontWeight='semibold'>
                              Order Number: {data.order.id}
                            </Text>
                            <Text fontSize='0.75em' fontWeight='semibold'>
                              Order Reference: #{data.order.reference}
                            </Text>
                            {data.order.orderwise_id && (
                              <Text fontSize='0.75em' fontWeight='semibold'>
                                OrderWise ID: {data.order.orderwise_id}
                              </Text>
                            )}
                          </Flex>
                        </Flex>
                      </ModalHeader>
                      <ModalBody>
                        <Table
                          columns={[
                            {
                              Header: 'Date',
                              accessor: 'created_at',
                              Cell: ({ value }) => (
                                <div>
                                  {moment(value).format('DD/MM/YY hh:mm')}
                                </div>
                              ),
                            },

                            {
                              Header: 'Note',
                              accessor: 'description',
                            },
                          ]}
                          data={
                            data?.orderwise_logs
                              ? { data: data?.orderwise_logs }
                              : { data: [] }
                          }
                          isExpandable={false}
                          //onRowSelect={(e) => onRowSelect(e)}
                          onChangePageSize={(e) => null}
                          onChangePage={(e) => null}
                          manualPagination={false}
                          canSelect={false}
                          // loading={loading}
                          size='lg'
                        />
                      </ModalBody>
                      <ModalFooter></ModalFooter>
                    </ModalContent>
                  </Modal>
                </SectionBlock>
              )}
            </Card>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default OrderView;
