import React, { useCallback, useEffect, useState } from 'react';
import { Select, useHttp } from '@builtbypixel/nucleus';
import { useWatch } from 'react-hook-form';

const RegionSelect = ({ onChange, ...rest }) => {
    const Http = useHttp();
    const country = useWatch({ name: 'country' });
    const [regions, setRegions] = useState([]);

    useEffect(() => {
        if (country?.id) {
            setRegions(country.regions);
        }
    }, [country]);

    return (
        <Select
            name='region'
            options={regions}
            labelKey='name'
            valueKey='id'
            onChange={onChange}
            {...rest}
        />
    );
};

export default RegionSelect;
