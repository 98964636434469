import React, { memo, useState, useEffect } from 'react'
import Field from './Field'
import { Text, useColorMode, Flex, Card, Button } from '@builtbypixel/plasma'
import { useParams } from 'react-router-dom'
import groupBy from 'underscore/modules/groupBy.js'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import ConditionalField from '../../Fields/ConditionalField'

const AutoForm = (props) => {
   const { colorMode } = useColorMode()
   const { type } = useParams()
   const { fields, isFullWidth, auto = true } = props
   const [_tabs, setTabs] = useState(null)
   const [menu, showMenu] = useState(false)

   const [display, setDisplay] = useState(0)

   useEffect(() => {
      if (fields) {
         setTabs(groupBy(fields, 'tab'))
      }
   }, [fields])

   const showField = (field) => {
      if (field.editOnly === undefined && field.createOnly === undefined) {
         return true
      }
      if (field.editOnly && type === 'edit') {
         return true
      }
      if (field.createOnly && type === 'create') {
         return true
      }
      return false
   }

   return (
      <React.Fragment>
         {_tabs && Object.keys(_tabs)[0] !== 'undefined' ? (
            <Flex
               w='inherit'
               overflowX='hidden'
               align='center'
               alignItems='flex-start'
            >
               {_tabs && (
                  <Flex
                     w='100%'
                     justify='flex-start'
                     direction={{ xs: 'column', md: 'row' }}
                  >
                     <Flex
                        p='10px'
                        w='100%'
                        display={{ xs: 'block', md: 'none' }}
                     >
                        <Button
                           isFullWidth
                           onClick={() => showMenu(!menu)}
                           rightIcon={
                              menu ? (
                                 <MdKeyboardArrowUp fontSize='20px' />
                              ) : (
                                 <MdKeyboardArrowDown fontSize='20px' />
                              )
                           }
                        >
                           Menu
                        </Button>
                     </Flex>
                     <Flex
                        direction='column'
                        w={{ xs: '100%', sm: '100%', md: '200px' }}
                        h={{ xs: menu ? 'auto' : 0, md: '100vh' }}
                        p={{ xs: menu ? 'auto' : 0, md: '10px' }}
                        px={{ xs: '10px' }}
                        overflow='hidden'
                        flexShrink={0}
                     >
                        {Object.keys(_tabs).map((t, i) => (
                           <Button
                              key={`form-tab-${i}`}
                              mb='5px'
                              align='left'
                              variant='ghost'
                              variantColor='secondary'
                              opacity={display === i ? 1 : 0.5}
                              onClick={() => setDisplay(i)}
                              bg={
                                 display === i
                                    ? colorMode === 'light'
                                       ? 'white'
                                       : 'whiteAlpha.200'
                                    : 'transparent'
                              }
                              color='global.text'
                              boxShadow={
                                 display === i
                                    ? '0px 0px 3px rgba(0,0,0,0.1)'
                                    : 'none'
                              }
                           >
                              {t}
                           </Button>
                        ))}
                     </Flex>

                     {Object.keys(_tabs).map((t, i) => (
                        <Flex
                           direction='column'
                           align='center'
                           px='10px'
                           display={display === i ? 'block' : 'none'}
                           key={`form-tabpanel-${i}`}
                           w={{ xs: '100%', md: 'calc(100% - 200px)' }}
                        >
                           {Object.values(_tabs)[i].map((fGroup, idx) => (
                              <Card
                                 key={`card=${i}-${idx}`}
                                 p={{ xs: 2, sm: 3, md: 5 }}
                                 mt='10px'
                                 w='100%'
                                 maxWidth={isFullWidth ? 'none' : '700px'}
                              >
                                 {fGroup.group && (
                                    <Text mb={5} fontSize={20} id={`test-${i}`}>
                                       {fGroup.group}
                                    </Text>
                                 )}

                                 {fGroup.component
                                    ? fGroup.component
                                    : fGroup.fields.map((field, ix) => {
                                         if (showField(field)) {
                                            return field.condition ? (
                                               <ConditionalField
                                                  key={`field-${ix}`}
                                                  {...field}
                                                  index={ix}
                                                  showField={showField(field)}
                                               />
                                            ) : (
                                               <Field
                                                  key={`field-${ix}`}
                                                  {...field}
                                                  auto={auto}
                                                  showField={showField(field)}
                                               />
                                            )
                                         } else {
                                            return null
                                         }
                                      })}
                              </Card>
                           ))}
                        </Flex>
                     ))}
                  </Flex>
               )}
            </Flex>
         ) : (
            fields &&
            fields.map((group, index) => (
               <Flex p='10px' w='100%' justify='center' key={`field-${index}`}>
                  <Card
                     key={`card=${index}`}
                     p={{ xs: '10px', sm: 5 }}
                     w='100%'
                     maxWidth={isFullWidth ? 'none' : '700px'}
                  >
                     {group.group && (
                        <Text mb={5} fontSize={20} id={`test-${index}`}>
                           {group.group}
                        </Text>
                     )}

                     {group.fields.map((field, i) => {
                        if (showField(field)) {
                           return field.condition ? (
                              <ConditionalField
                                 key={`field-${i}`}
                                 {...field}
                                 index={i}
                              />
                           ) : (
                              <Field
                                 key={`field-${i}`}
                                 {...field}
                                 index={i}
                                 auto
                              />
                           )
                        } else {
                           return null
                        }
                     })}
                  </Card>
               </Flex>
            ))
         )}
      </React.Fragment>
   )
}

export default AutoForm
