import React, { useMemo } from 'react';
import useSWR from 'swr';
import { EditView, AutoFields, useGet } from '@builtbypixel/nucleus';

import setup from '../setup';

const EditForm = () => {
  const { data: countries } = useSWR('/countries', useGet);

  const fields = useMemo(
    () => [
      {
        group: 'Contact Us',
        fields: [
          {
            name: 'content.company_name',
            component: 'text',
            placeholder: 'Company Name',
            label: 'Company Name',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.address_line_1',
            component: 'text',
            placeholder: 'Address Line 1',
            label: 'Address Line 1',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.address_line_2',
            component: 'text',
            placeholder: 'Address Line 2',
            label: 'Address Line 2',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.address_line_3',
            component: 'text',
            placeholder: 'Address Line 3',
            label: 'Address Line 3',
            isRequired: false,
            rules: { required: false },
          },
          {
            name: 'content.city',
            component: 'text',
            placeholder: 'City',
            label: 'City',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.postcode',
            component: 'text',
            placeholder: 'Postcode',
            label: 'Postcode',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.country',
            component: 'select',
            labelKey: 'name',
            valueKey: 'id',
            options: countries?.data,
            placeholder: 'Country',
            label: 'Country',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.uk_telephone',
            component: 'text',
            placeholder: 'UK Telephone',
            label: 'UK Telephone',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'content.international_telephone',
            component: 'text',
            placeholder: 'International Telephone',
            label: 'International Telephone',
            isRequired: false,
            rules: { required: false },
          },
          {
            name: 'content.email',
            component: 'text',
            placeholder: 'Email',
            label: 'Email',
            isRequired: false,
            rules: { required: false },
          },
          {
            name: 'content.opening_hours',
            keyName: 'id',
            component: 'repeater',
            label: 'Opening Hours',
            rules: { required: true },
            layout: 'vertical',
            fieldArray: [
              {
                name: 'weekday',
                component: 'text',
                placeholder: 'Weekday',
                label: 'Week Days',
                isRequired: true,
                rules: { required: true },
              },

              {
                name: 'open',
                component: 'switch',
                placeholder: 'Open',
                label: 'Open',
                type: 'switch',
              },
              {
                name: 'start_time',
                component: 'text',
                placeholder: 'Opening Time',
                label: 'Opening Time',
                isRequired: false,
                rules: { required: false },
              },
              {
                name: 'end_time',
                component: 'text',
                placeholder: 'Closing Time',
                label: 'Closing Time',
                isRequired: false,
                rules: { required: false },
              },
            ],
          },
        ],
      },
    ],
    [countries]
  );

  return (
    <EditView setup={setup} isFullWidth>
      <AutoFields fields={fields} isFullWidth />
    </EditView>
  );
};

export default EditForm;
