import React, { useMemo } from 'react';

import { EditView, AutoFields } from '@builtbypixel/nucleus';

import setup from '../setup';

const EditForm = () => {
    const fields = useMemo(
        () => [
            {
                group: 'Page Details',
                fields: [
                    {
                        name: 'title',
                        component: 'text',
                        placeholder: 'Page Title',
                        label: 'Page Title',
                        rules: { required: true },
                    },
                    {
                        name: 'url',
                        component: 'text',
                        placeholder: 'Page URL',
                        label: 'Page URL',
                        rules: { required: true },
                    },
                ],
            },
            {
                group: 'Intro Block One',
                fields: [
                    {
                        name: 'content.block_one.cta_1.title',
                        component: 'text',
                        placeholder: 'Heading',
                        label: 'Heading',
                        rules: { required: true },
                    },
                    {
                        name: 'content.block_one.cta_1.description',
                        component: 'richtext',
                        placeholder: 'Description',
                        label: 'Description',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'content.block_one.cta_1.slug',
                        component: 'text',
                        placeholder: 'Slug',
                        label: 'Slug',
                        rules: { required: true },
                    },
                    {
                        name: 'content.block_one.cta_1.image',
                        component: 'file',
                        max: 1,
                        label: 'Image',
                        isRequired: true,
                        rules: { required: true },
                    },
                ],
            },
            {
                group: 'Intro Block Two',
                fields: [
                    {
                        name: 'content.block_one.cta_2.title',
                        component: 'text',
                        placeholder: 'Heading',
                        label: 'Heading',
                        rules: { required: true },
                    },
                    {
                        name: 'content.block_one.cta_2.description',
                        component: 'richtext',
                        placeholder: 'Description',
                        label: 'Description',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'content.block_one.cta_2.slug',
                        component: 'text',
                        placeholder: 'Slug',
                        label: 'Slug',
                        rules: { required: true },
                    },
                    {
                        name: 'content.block_one.cta_2.image',
                        component: 'file',
                        max: 1,
                        label: 'Image',
                        isRequired: true,
                        rules: { required: true },
                    },
                ],
            },
            {
                group: 'Intro Block Three',
                fields: [
                    {
                        name: 'content.block_one.cta_3.title',
                        component: 'text',
                        placeholder: 'Heading',
                        label: 'Heading',
                        rules: { required: true },
                    },
                    {
                        name: 'content.block_one.cta_3.description',
                        component: 'richtext',
                        placeholder: 'Description',
                        label: 'Description',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'content.block_one.cta_3.slug',
                        component: 'text',
                        placeholder: 'Slug',
                        label: 'Slug',
                        rules: { required: true },
                    },
                    {
                        name: 'content.block_one.cta_3.image',
                        component: 'file',
                        max: 1,
                        label: 'Image',
                        isRequired: true,
                        rules: { required: true },
                    },
                ],
            },
            {
                group: 'Half Strip Block One',
                fields: [
                    {
                        name: 'content.half_strip.cta_1.title',
                        component: 'text',
                        placeholder: 'Heading',
                        label: 'Heading',
                        rules: { required: true },
                    },
                    {
                        name: 'content.half_strip.cta_1.description',
                        component: 'richtext',
                        placeholder: 'Description',
                        label: 'Description',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'content.half_strip.cta_1.slug',
                        component: 'text',
                        placeholder: 'Slug',
                        label: 'Slug',
                        rules: { required: true },
                    },
                    {
                        name: 'content.half_strip.cta_1.image',
                        component: 'file',
                        max: 1,
                        label: 'Image',
                        isRequired: true,
                        rules: { required: true },
                    },
                ],
            },
            {
                group: 'Half Strip Block Two',
                fields: [
                    {
                        name: 'content.half_strip.cta_2.title',
                        component: 'text',
                        placeholder: 'Heading',
                        label: 'Heading',
                        rules: { required: true },
                    },
                    {
                        name: 'content.half_strip.cta_2.description',
                        component: 'richtext',
                        placeholder: 'Description',
                        label: 'Description',
                        isRequired: true,
                        rules: { required: true },
                    },
                    {
                        name: 'content.half_strip.cta_2.slug',
                        component: 'text',
                        placeholder: 'Slug',
                        label: 'Slug',
                        rules: { required: true },
                    },
                    {
                        name: 'content.half_strip.cta_2.image',
                        component: 'file',
                        max: 1,
                        label: 'Image',
                        isRequired: true,
                        rules: { required: true },
                    },
                ],
            },
            {
                group: 'Block Strip One',
                fields: [
                    {
                        name: 'content.blocks_one_title',
                        label: 'Block Title',
                        component: 'text',
                        rules: { required: true },
                        isRequired: true,
                    },
                    {
                        name: 'content.blocks_one',
                        component: 'repeater',
                        max: 4,
                        fieldArray: [
                            {
                                name: 'title',
                                label: 'Title',
                                component: 'text',
                                rules: { required: true },
                                isRequired: true,
                            },
                            {
                                name: 'image',
                                label: 'Image',
                                component: 'file',
                                max: 1,
                                rules: { required: true },
                                isRequired: true,
                            },
                            {
                                name: 'link_label',
                                label: 'Link Text',
                                component: 'text',
                                rules: { required: true },
                                isRequired: true,
                            },
                            {
                                name: 'link_href',
                                label: 'Link URL',
                                help: "Do not include 'http' - eg '/brochures'",
                                component: 'text',
                                rules: { required: true },
                                isRequired: true,
                            },
                        ],

                        label: 'Block Row One',
                        // isRequired: true,
                        // rules: { required: true },
                    },
                ],
            },
            {
                group: 'Block Strip Two',
                fields: [
                    {
                        name: 'content.blocks_two_title',
                        label: 'Block Title',
                        component: 'text',
                        rules: { required: true },
                        isRequired: true,
                    },
                    {
                        name: 'content.blocks_two',
                        component: 'repeater',
                        max: 4,
                        fieldArray: [
                            {
                                name: 'title',
                                label: 'Title',
                                component: 'text',
                                rules: { required: true },
                                isRequired: true,
                            },
                            {
                                name: 'image',
                                label: 'Image',
                                component: 'file',
                                max: 1,
                                rules: { required: true },
                                isRequired: true,
                            },
                            {
                                name: 'link_label',
                                label: 'Link Text',
                                component: 'text',
                                rules: { required: true },
                                isRequired: true,
                            },
                            {
                                name: 'link_href',
                                label: 'Link URL',
                                help: "Do not include 'http' - eg '/brochures'",
                                component: 'text',
                                rules: { required: true },
                                isRequired: true,
                            },
                        ],

                        label: 'Block Row One',
                        // isRequired: true,
                        // rules: { required: true },
                    },
                ],
            },
            // {
            //     group: 'News and blogs Strip Block One',
            //     fields: [
            //         {
            //             name: 'content.news_and_blogs.cta_1.title',
            //             component: 'text',
            //             placeholder: 'Heading',
            //             label: 'Heading',
            //             rules: { required: true },
            //         },
            //         {
            //             name: 'content.news_and_blogs.cta_1.description',
            //             component: 'richtext',
            //             placeholder: 'Description',
            //             label: 'Description',
            //             isRequired: true,
            //             rules: { required: true },
            //         },
            //         {
            //             name: 'content.news_and_blogs.cta_1.slug',
            //             component: 'text',
            //             placeholder: 'Slug',
            //             label: 'Slug',
            //             rules: { required: true },
            //         },
            //         {
            //             name: 'content.news_and_blogs.cta_1.image',
            //             component: 'file',
            //             max: 1,
            //             label: 'Image',
            //             isRequired: true,
            //             rules: { required: true },
            //         },
            //     ],
            // },
            // {
            //     group: 'News and blogs Strip Block Two',
            //     fields: [
            //         {
            //             name: 'content.news_and_blogs.cta_2.title',
            //             component: 'text',
            //             placeholder: 'Heading',
            //             label: 'Heading',
            //             rules: { required: true },
            //         },
            //         {
            //             name: 'content.news_and_blogs.cta_2.description',
            //             component: 'richtext',
            //             placeholder: 'Description',
            //             label: 'Description',
            //             isRequired: true,
            //             rules: { required: true },
            //         },
            //         {
            //             name: 'content.news_and_blogs.cta_2.slug',
            //             component: 'text',
            //             placeholder: 'Slug',
            //             label: 'Slug',
            //             rules: { required: true },
            //         },
            //         {
            //             name: 'content.news_and_blogs.cta_2.image',
            //             component: 'file',
            //             max: 1,
            //             label: 'Image',
            //             isRequired: true,
            //             rules: { required: true },
            //         },
            //     ],
            // },

            {
                group: 'Bottom Text Block',
                fields: [
                    {
                        name: 'content.bottom_text',
                        component: 'richtext',
                        label: 'Bottom Text',
                        // isRequired: true,
                        // rules: { required: true },
                    },
                ],
            },
        ],
        []
    );
    return (
        <EditView setup={setup}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
