import React, { useEffect } from 'react'
import {
   PseudoBox,
   Button,
   useColorMode,
   Box,
   PopConfirm,
   FormLabel,
   IconButton,
   Grid,
   useTheme,
   Dropdown,
   Flex
} from '@builtbypixel/plasma'

import Field from '../../components/Form/Field'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import { FiSettings } from 'react-icons/fi'
import ConditionalField from '../ConditionalField'
import { BiArrowToBottom, BiArrowToTop, BiTrashAlt } from 'react-icons/bi'

export const List = (props) => {
   const theme = useTheme()
   const { fieldArray, name, singleName, keyName, label, max } = props
   const { control } = useFormContext()
   const { colorMode } = useColorMode()
   const val = useWatch({ name })

   const { fields, append, remove, move, insert } = useFieldArray({
      name: name,
      control,
      keyName: keyName ? keyName : 'id'
   })

   useEffect(() => {
      if (!val) {
         append({})
      }
   }, [val])

   return (
      <PseudoBox w='100%'>
         <FormLabel>
            {label} {max && `(maximum ${max} items)`}
         </FormLabel>
         <Box
            bg={theme.colors.secondaryLight}
            rounded='lg'
            mb='10px'
            position='relative'
            w='100%'
            border='1px'
            borderColor='global.borderColour'
            overflow='hidden'
         >
            {fields.map((field, index) => (
               <Grid
                  key={field[keyName ? keyName : 'id']}
                  w='100%'
                  templateColumns='1fr 30px'
               >
                  {fieldArray.map((_field, i) =>
                     _field.condition ? (
                        <Box
                           mb='0px'
                           key={`field-${index}-${i}`}
                           borderBottom='1px'
                           borderColor='global.borderColour'
                           overflow='hidden'
                        >
                           <ConditionalField
                              {..._field}
                              parentName={`${name}[${index}]`}
                              isRepeater
                              index={i}
                              name={`${name}[${index}].${_field.name}`}
                              component={_field.component}
                              size='sm'
                              defaultValue={
                                 _field.defaultValue
                                    ? _field.defaultValue
                                    : field[_field.name]
                              }
                              label={null}
                              noMargin
                              autoFocus
                           />
                        </Box>
                     ) : (
                        <Box
                           mb='0px'
                           key={`field-${index}-${i}`}
                           borderBottom='1px'
                           borderColor='global.borderColour'
                           overflow='hidden'
                        >
                           <Field
                              {..._field}
                              autoFocus
                              parentName={`${name}[${index}]`}
                              isRepeater
                              index={i}
                              name={`${name}[${index}].${_field.name}`}
                              component={_field.component}
                              size='sm'
                              rounded='none'
                              defaultValue={
                                 _field.defaultValue
                                    ? _field.defaultValue
                                    : field[_field.name]
                              }
                              label={null}
                              border='0px'
                              noMargin
                           />
                        </Box>
                     )
                  )}

                  <Box borderBottom='1px' borderColor='global.borderColour'>
                     <Dropdown
                        title='Open Dropdown'
                        triggerElement={
                           <IconButton
                              rounded='full'
                              size='sm'
                              variant='ghost'
                              icon={
                                 <FiSettings fontSize='12px' opacity={0.4} />
                              }
                           ></IconButton>
                        }
                        overlay={
                           <Flex direction='column'>
                              <Button
                                 isFullWidth
                                 align='left'
                                 size='sm'
                                 variant='ghost'
                                 rounded='none'
                                 fontWeight='normal'
                                 leftIcon={<AiOutlineAppstoreAdd />}
                                 onClick={() => insert(index, {})}
                              >
                                 Add Item Above
                              </Button>
                              <Button
                                 isFullWidth
                                 size='sm'
                                 align='left'
                                 variant='ghost'
                                 rounded='none'
                                 fontWeight='normal'
                                 leftIcon={<AiOutlineAppstoreAdd />}
                                 onClick={() => insert(index + 1, {})}
                              >
                                 Add Item Below
                              </Button>
                              <PopConfirm
                                 onConfirm={() => remove(index)}
                                 title='Are you sure you want to remove this item?'
                              >
                                 <Button
                                    align='left'
                                    size='sm'
                                    rounded='none'
                                    fontWeight='normal'
                                    isFullWidth
                                    variant='ghost'
                                    leftIcon={<BiTrashAlt fontSize='16px' />}
                                 >
                                    Delete Item
                                 </Button>
                              </PopConfirm>
                              <Button
                                 align='left'
                                 rounded='none'
                                 size='sm'
                                 isFullWidth
                                 variant='ghost'
                                 fontWeight='normal'
                                 isDisabled={index === 0}
                                 leftIcon={<BiArrowToTop fontSize='16px' />}
                                 onClick={() => move(index, index - 1)}
                              >
                                 Move Up
                              </Button>
                              <Button
                                 align='left'
                                 size='sm'
                                 isFullWidth
                                 rounded='none'
                                 fontWeight='normal'
                                 variant='ghost'
                                 isDisabled={index + 1 === fields.length}
                                 leftIcon={<BiArrowToBottom fontSize='16px' />}
                                 onClick={() => move(index, index + 1)}
                              >
                                 Move Down
                              </Button>
                           </Flex>
                        }
                     ></Dropdown>
                  </Box>
               </Grid>
            ))}
         </Box>
         <Button
            onClick={() => append({})}
            leftIcon={<AiOutlineAppstoreAdd />}
            variant='outline'
            variantColor='secondary'
            isDisabled={fields.length >= max}
            mb='10px'
            size='sm'
         >
            Add {singleName ? singleName : 'item'}
         </Button>
      </PseudoBox>
   )
}
