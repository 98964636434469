import React from 'react';
import { Spinner, Flex, Text, Box } from '@builtbypixel/plasma';
const Loader = () => {
  return (
    <Flex direction='column' justify='center'>
      <Box m='0 auto'>
        <Spinner size='xl' speed='0.75s' />
      </Box>
      <Text>...LOADING</Text>
    </Flex>
  );
};

export default Loader;
