import React, { useMemo } from 'react';
import { useGet, useHttp, Field } from '@builtbypixel/nucleus';
import {
  Alert,
  Box,
  Grid,
  FormLabel,
  DatePicker,
  Input,
  Text,
  Divider,
  Checkbox,
} from '@builtbypixel/plasma';
import SubmitButton from '../SubmitButton';
import ProductSelector from '../ProductSelector';

const Main = (props) => {
  const getTypes = () => {
    switch (props.type) {
      case 1:
        return <Tab1 {...props} />;
      case 2:
        return <Tab2 {...props} />;
      case 3:
        return <Tab3 {...props} />;
      case 4:
        return <Tab3 {...props} />;
      case 5:
        return <Tab4 {...props} />;
      default:
        break;
    }
  };

  const Tab1 = (props) => {
    return (
      <Box padding={5}>
        <Input
          as='input'
          autoFocus='autoFocus'
          id='name'
          placeholder='Number of products to import'
          size='md'
          type='number'
          variant='outline'
          value={props.value}
          width='50%'
          onChange={(e) => props.onChange(e.target.value)}
        />
        <Text my='25px'>
          Select products to import - if no products are selected then all
          products will be imported
        </Text>
        <Checkbox
          checked={props.checked}
          onChange={(e) => props.checkboxHandler(!props.checked)}
          color='primary'
          label='Update Products'
          size='md'
        />
        <SubmitButton
          title='Import Products'
          submit={props.submit}
          disable={props.disable}
          loading={props.loading}
        />
      </Box>
    );
  };
  const Tab2 = (props) => {
    return (
      <Box padding={5}>
        <ProductSelector value={props.value} onChange={props.onChange} />
        <SubmitButton
          title='Update Products'
          submit={props.submit}
          disable={props.disable}
          loading={props.loading}
        />
      </Box>
    );
  };

  const Tab3 = (props) => (
    <Box padding={5}>
      <Box mb={5}>
        <FormLabel>Start Date</FormLabel>
        <DatePicker
          onChange={(date) => props.onDateChangeStart(date)}
          placeholder='Start Date'
          value={props.start_date}
        />
      </Box>
      <Box mb={5}>
        <FormLabel>End Date</FormLabel>
        <DatePicker
          value={props.end_date}
          placeholder='End Date'
          onChange={(date) => props.onDateChangeEnd(date)}
        />
      </Box>
      <SubmitButton
        title={props.additional ? 'Create Sales' : 'Create/Update Customers'}
        submit={props.submit}
        disable={props.disable}
        loading={props.loading}
      />
    </Box>
  );

  const Tab4 = () => (
    <Box padding={5}>
      <SubmitButton
        title='Reset Products'
        submit={props.submit}
        loading={props.loading}
        disable={false}
      />
    </Box>
  );

  return <>{getTypes()}</>;
};

export default Main;
