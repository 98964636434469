import React from 'react';
import { Flex, Box, Text, Card } from '@builtbypixel/plasma';
import { FiShoppingCart, FiUser } from 'react-icons/fi';
import PageContainer from '../Components/PageContainer';
import { useHistory } from 'react-router-dom';
import NavBoxWrapper from '../Components/NavBoxWrapper';

const routes = [
  {
    text: 'Most Viewed Products',
    icon: <FiUser />,
    path: 'products/most-viewed',
    image: '/images/mostViewed.jpg',
  },
  {
    text: 'Best Selling Products',
    icon: <FiUser />,
    path: 'products/best-sellers',
    image: '/images/bestSelling.jpg',
  },
];

const ProductsRoutes = ({ setCurrentPage, currentPage }) => {
  return (
    <PageContainer title='Reports Products' currentPage={currentPage}>
      {routes.map((route) => {
        return route.path === 'home' ? null : (
          <NavBoxWrapper
            text={route.text}
            icon={route.icon}
            path={route.path}
            image={route.image}
            setCurrentPage={setCurrentPage}
          ></NavBoxWrapper>
        );
      })}
    </PageContainer>
  );
};

export default ProductsRoutes;
