const url = `${process.env.REACT_APP_API}/api/reports`;

export const poa = `${url}/dashboard/poa-requests`;
export const customers = `${url}/dashboard/all-customers`;
export const lifetime = `${url}/dashboard/lifetime-sales`;
export const productsMostViewed = `${url}/dashboard/most-viewed-products`;
export const orders = `${url}/dashboard/last-five-orders`;
export const average = `${url}/dashboard/average-sales`;
export const bestSellers = `${url}/dashboard/bestsellers`;
export const customerPreferences = `${url}/dashboard/customer-preferences`;
export const customerHearUs = `${url}/dashboard/customer-hearus`;
export const brochures = `${url}/brochure-report`;
export const discountCodes = `${url}/dashboard/discount-codes`;
export const averageBasketProducts = `${url}/dashboard/average-products`;
