import { createCrudRoutes } from '@builtbypixel/nucleus';
import List from './List';
import Entry from './';
import View from './View';
import setup from './setup';

const permissions = ['Super Admin', 'Admin', 'Sales', 'Warehouse'];

const crud = createCrudRoutes({
  setup,
  name: setup.title,
  view: View,
  list: List,
  edit: View,
  permissions,
});

const otherRoutes = [
  /*
        {
        path: '/login',
        title: 'Login',
        exact: true,
        auth: false,
        component: Login,
    },
    */
];

const routes = [...crud, ...otherRoutes];

export default routes;
