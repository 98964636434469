import React, { useMemo } from 'react';
import { AutoFields, EditView, useGet } from '@builtbypixel/nucleus';

import setup from './setup';
import useSWR from 'swr';
import CategoryProducts from '../Products/Fields/CategoryProducts';

const EditForm = () => {
  const { data: attributes } = useSWR(
    '/attributes?pagination=300&sort=name&order=asc',
    useGet
  );
  const { data: productTypes } = useSWR('/product-types', useGet);
  const { data: attributeTypes } = useSWR('/attribute-sets', useGet);

  const { data: default_filters } = useSWR('/default-filters', useGet);

  const fields = useMemo(
    () => [
      {
        group: 'Category Information',
        tab: 'Information',
        fields: [
          {
            name: 'active',
            component: 'switch',
            label: 'Active',
          },
          {
            name: 'name',
            component: 'text',
            placeholder: 'Category Name',
            label: 'Category Name',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'default_filter',
            component: 'select',
            options: default_filters?.data,
            labelKey: 'name',
            valueKey: 'id',
            label: 'Default Filters',
            help: 'Select the default filter to apply when this sub-category is loaded',
          },
          {
            name: 'featured',
            component: 'switch',
            label: 'Featured Subcategory',
          },
          {
            name: 'image',
            component: 'file',
            max: 1,
            label: 'Image',
          },
          {
            name: 'description',
            component: 'richtext',
            placeholder: 'Category Description',
            label: 'Category Description',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'filters',
            component: 'select',
            isMulti: true,
            options: attributes?.data,
            labelKey: 'name',
            valueKey: 'name',
            label: 'Filters',
          },
          {
            name: 'lead_time',
            component: 'text',
            placeholder: 'Lead Time in weeks',
            label: 'Lead time in weeks',
            type:"number"
          },
        ],
      },
      {
        group: 'Second Category',
        tab: 'Second Category',
        fields: [
          {
            name: 'content.addSecondCategory',
            component: 'switch',
            label: 'Add Secondary Category',
          },
          {
            name: 'content.second_name',
            component: 'text',
            placeholder: 'Second Category Name',
            label: 'Second Category Name',
            step: 1,
            childProps: {
              label: 'Second Category Name',
              step: 1,
              placeholder: 'Second Category Name',
            },
            condition: {
              watchField: 'content.addSecondCategory',
              matchValue: true,
              operator: '===',
              childComponent: 'text',
            },
          },
          {
            name: 'content.second_description',
            component: 'richtext',
            placeholder: 'Second Category Description',
            label: 'Second Category Description',
            step: 1,
            childProps: {
              placeholder: 'Second Category Description',
              label: 'Second Category Description',
              step: 1,
            },
            condition: {
              watchField: 'content.addSecondCategory',
              matchValue: true,
              operator: '===',
              childComponent: 'text',
            },
          },
        ],
      },
      {
        group: 'Seo',
        tab: 'Seo',
        fields: [
          {
            name: 'seo_index.meta_title',
            component: 'text',
            placeholder: 'Meta Title',
            label: 'Meta Title',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'seo_index.meta_description',
            component: 'textarea',
            placeholder: 'Meta Description',
            label: 'Meta Description',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'seo_index.meta_keywords',
            component: 'text',
            placeholder: 'Meta Keywords',
            label: 'Meta Keywords',
            isRequired: true,
            rules: { required: true },
          },
        ],
      },
      {
        group: 'Products',
        tab: 'Products',
        auto: false,
        fields: [
          {
            name: 'products',
            component: CategoryProducts,
            productTypes: productTypes?.data,
            attributes: attributeTypes?.data,
          },
        ],
      },
    ],
    [attributes, default_filters]
  );
  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
