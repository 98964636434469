import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@builtbypixel/plasma';

import setup from './setup';
import dayjs from 'dayjs';

// Extra Menu appears next to the create button
const extraMenu = () => {
    return null;
};

const List = () => {
    const columns = [
        {
            Header: 'Title',
            accessor: 'title',
            Cell: ({ row }) => (
                <LinkButton
                    to={`${setup.model}/edit/${row.original[setup.accessor]}`}
                >
                    <Button
                        variant='link'
                        variantColor='secondary'
                        rounded='md'
                    >
                        {row.original.title}
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'Last Modified',
            accessor: 'last_modified_date',
            Cell: ({ row }) => {
                return dayjs(row.original.last_modified_date).format(
                    'DD/MM/YYYY'
                );
            },
        },
        {
            Header: 'Status',
            accessor: 'status.name',
        },
    ];

    return (
        <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />
    );
};

export default List;
