import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';

import setup from './setup';

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: 'Main Details',
        fields: [
          {
            name: 'title',
            component: 'text',
            placeholder: 'Brochure Name',
            label: 'Brochure Name',
            isRequired: true,
            rules: { required: true },
          },

          {
            name: 'images',
            component: 'file',
            max: 1,
            label: 'Brochure Cover Image',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'iframe',
            component: 'html',
            placeholder: 'Paste iFrame here',
            label: 'Paste iFrame here',
            isRequired: true,
            rules: { required: true },
          },
          {
            name: 'supplier',
            component: 'switch',
            placeholder: 'Supplier?',
            label: 'Supplier?',
          },
          {
            name: 'request_copy',
            component: 'switch',
            placeholder: 'Can customer request copy?',
            label: 'Request Copy?',
            defaultValue: true,
          },
        ],
      },
    ],
    []
  );

  return (
    <EditView setup={setup}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
